/* eslint-disable no-undef */
import axios from 'axios';
import { Auth } from 'aws-amplify';
import Cookies from 'cookies-js';
import mixpanel from 'mixpanel-browser';
import * as moment from 'moment';
import Identicon from 'identicon.js';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Flex, Image, Paragraph, Textarea } from 'theme-ui';
import { actions as userActions } from '../store/reducers/users';
import { NotificationsIcon } from './notifications';
import NotificationsPanel from './notifications-panel';
import { ChatBot } from './chat/chatbot';
import theme from '../theme';
import Overlay from './overlay/overlay';
import Divider from './divider';
import SendMessage from './portal/send-message';

const CookieBanner = ({ toggleCookiesEnabled }) => (
    <Flex
        sx={{
            background: '#2B373B',
            height: [120, 100],
            fontSize: 18,
            position: 'fixed',
            bottom: 0,
            width: '100%',
            zIndex: 99999999999,
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 20,
        }}
    >
        <Paragraph sx={{ ml: 20, width: ['60%', '80%'], color: '#FFFFFF', fontSize: '14px' }}>
            This website uses cookies to enhance the user experience.
        </Paragraph>
        <Button
            data-testid="cookie-accept"
            sx={{ borderRadius: 12, color: 'primary' }}
            onClick={() => {
                Cookies.set('BLCookieConsent', 'yes', {
                    domain: window.location.hostname,
                    secure: true,
                    expires: new Date(2025, 0, 1),
                });
                toggleCookiesEnabled(true);
                mixpanel.init('58f66d6f268affd34aaab754636a18ae', {
                    debug: true,
                    track_pageview: true,
                    persistence: 'localStorage',
                });
            }}
            variant="secondary"
        >
            Accept
        </Button>
    </Flex>
);

function arrayToTable(array) {
    // Flattening the nested array
    const flattenedArray = array.flat(Infinity);

    // Creating a table
    let table = '<table border="0">';

    if (typeof flattenedArray[0] !== 'string') {
        table += '<thead><tr>';
        Object.keys(flattenedArray[0]).forEach((key) => {
            table += `<th>${key}</th>`;
        });
        table += '</tr></thead>';
    }

    table += '<tbody>';

    // Adding rows for each object
    flattenedArray.forEach((item) => {
        table += '<tr>';
        if (typeof item === 'string') {
            table += `<td style="text-align: center;">${item}</td>`;
        } else {
            Object.values(item).forEach((value) => {
                table += `<td style="text-align: center;">${value}</td>`;
            });
        }

        table += '</tr>';
    });

    table += '</tbody></table>';

    return table;
}

export const queryDocument = async (state, updateState, stateRef) => {
    const newState = {
        ...stateRef?.current,
        loading: true,
        chatBotAnswer: '',
        chatBotError: '',
        documentToQuery: {
            ...stateRef?.current.documentToQuery,
            history: [
                ...stateRef.current.documentToQuery.history,
                {
                    type: 'question',
                    text: stateRef?.current.documentQuery,
                    timestamp: moment.utc().format('YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]'),
                },
            ],
        },
    };

    updateState({ ...newState, documentQuery: '', error: null });

    try {
        let [
            {
                data: { answer, resultsSetType, recordType, record_ids },
            },
            { data: organisation },
        ] = await Promise.all([
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_AQRU_AI_API}/chats/talk`,
                data: {
                    chat_id: `clients_list_chat_${stateRef?.current.userId}`,
                    question: stateRef?.current.documentQuery,
                },
            }),
            axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/organisations`,
            }),
        ]);

        if (!answer?.length) {
            answer = "I've not found anything for that, trying rephrasing the question";
        }

        let answerContainsComponent = false;
        if (
            answer?.length === 1 &&
            answer[0]?.[0] === null &&
            resultsSetType === 'single' &&
            recordType === 'clients'
        ) {
            answer = (
                <Flex sx={{ flexDirection: 'column', mt: 10 }}>
                    <Paragraph sx={{ fontSize: 13 }}>There is no value stored for that currently</Paragraph>
                    <a target="_blank" rel="noreferrer" href={`/clients?id=${record_ids?.[0]}`}>
                        <Button sx={{ width: 150, mt: 10, height: 40, fontSize: 14 }}>Click here to Add</Button>
                    </a>
                </Flex>
            );
            answerContainsComponent = true;
        }

        updateState({
            ...newState,
            loading: false,
            chatBotError: '',
            documentQuery: '',
            chatBotAnswer: answer,
            documentToQuery: {
                ...newState.documentToQuery,
                history: [
                    ...newState.documentToQuery.history,
                    {
                        type: 'answer',
                        text: answer,
                        answerContainsComponent,
                        organisation,
                        resultsSetType,
                        recordType,
                        timestamp: moment.utc().format('YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]'),
                    },
                ],
            },
        });
    } catch (e) {
        const error =
            "I don't seem to have an answer for that right now, if this problem persists please contact support";
        updateState({
            ...newState,
            loading: false,
            chatBotError: '',
            documentQuery: '',
            chatBotAnswer: error,
            documentToQuery: {
                ...newState.documentToQuery,
                history: [
                    ...newState.documentToQuery.history,
                    {
                        type: 'answer',
                        text: error,
                        timestamp: moment.utc().format('YYYY[-]MM[-]DD[T]HH[:]mm[:]ss[Z]'),
                    },
                ],
            },
        });
    }
};

const AppWrapper = ({ children, resize, timestampOfLastDataLoad, organisation, user }) => {
    const [cookiesEnabled, toggleCookiesEnabled] = useState(Cookies.get && Cookies.get('BLCookieConsent') === 'yes');

    const lastLoad = localStorage.getItem('last_load');

    let showNotificationsByDefault = false;

    const twoHoursInMillis = 1000 * 60 * 60 * 2;

    if (!lastLoad || new Date().getTime() - parseInt(lastLoad, 10) > twoHoursInMillis) {
        showNotificationsByDefault = true;
        localStorage.setItem('last_load', new Date().getTime() + '');
    }

    const [showNotificationsPanel, toggleNotificationsPanel] = useState(showNotificationsByDefault);
    const [showChatMini, toggleChatMini] = useState(false);

    window.toggleChatMini = () => toggleChatMini(!showChatMini);

    const [state, updateState] = useState({
        organisation,
        isLoading: false,
        notifications: [],
        documentToQuery: { history: [] },
    });

    const stateRef = useRef();
    stateRef.current = state;

    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    const handleFocus = () => {
        if (window.location.href?.includes('magic-links')) return;

        const secondsSinceLastRefresh = timestampOfLastDataLoad
            ? moment.utc().diff(timestampOfLastDataLoad, 'seconds')
            : 0;

        const THIRTY_MINS_IN_SECONDS = 60 * 30;
        if (secondsSinceLastRefresh > THIRTY_MINS_IN_SECONDS) {
            // ping to cause 401 failure and redirect if token expired
            (async () => {
                try {
                    await axios({
                        url: `${process.env.REACT_APP_AQRU_AI_API}/organisations`,
                    });
                } catch (e) {
                    try {
                        await Auth.signOut();
                    } catch (ex) {
                        // swallow
                    }
                    window.location.assign('/');
                }
            })();
        }
    };

    useEffect(() => {
        window.addEventListener('focus', handleFocus);
        return () => {
            return () => {
                window.removeEventListener('focus', handleFocus);
            };
        };
    }, [timestampOfLastDataLoad]);

    useEffect(() => {
        if (cookiesEnabled) {
            mixpanel.init('58f66d6f268affd34aaab754636a18ae', {
                debug: true,
                track_pageview: true,
                persistence: 'localStorage',
            });
        }

        window.onerror = function (message, source, lineno, colno, error) {
            mixpanel.track('default_onerror', {
                message,
                source,
                lineno,
                colno,
                error,
            });

            return true;
        };

        window.onunhandledrejection = function (event) {
            mixpanel.track('onunhandledrejection', {
                reason: event.reason,
            });

            return true;
        };

        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`;
        script.async = true;
        document.body.appendChild(script);

        window.addEventListener('resize', resize);
        return () => window.addEventListener('resize', resize);
    }, []);

    let userLabel = '';
    if (organisation?.entity?.user?.first_name && organisation?.entity?.user?.last_name) {
        userLabel = `${organisation?.entity?.user?.first_name} ${organisation?.entity?.user?.last_name}`;
    }

    useEffect(() => {
        (async () => {
            if (organisation?.entity?.user && organisation.entity?.user_type === 'org_user') {
                const { data } = await axios({
                    url: `${process.env.REACT_APP_AQRU_AI_API}/notifications`,
                });
                updateState({ ...state, notifications: data.notifications });
            }
        })();
        (async () => {
            if (organisation?.entity?.user && organisation.entity?.user_type === 'client_user') {
                const { data } = await axios({
                    url: `${process.env.REACT_APP_AQRU_AI_API}/notifications/portal`,
                });
                updateState({ ...state, notifications: data.notifications });
            }
        })();
    }, [organisation?.entity, showNotificationsPanel]);

    return (
        <Flex sx={{ flexDirection: 'column' }}>
            {state.showSendMsgModal && (
                <Overlay
                    copy={{}}
                    maxWidth={500}
                    maxHeight={380}
                    embeddedComponent={<SendMessage state={state} updateState={updateState} />}
                    updateOverlay={() => updateState({ ...state, showSendMsgModal: false })}
                />
            )}

            {organisation?.entity?.user_type === 'client_user' && (
                <>
                    <Flex
                        sx={{
                            position: 'absolute',
                            right: 20,
                            top: 20,
                            zIndex: 100,
                            cursor: 'pointer',
                            borderRadius: 12,
                        }}
                    >
                        <NotificationsIcon
                            customWrapperSx={{ mt: 10 }}
                            countLeft={20}
                            onClick={(e) => {
                                toggleChatMini(false);
                                toggleNotificationsPanel(!showNotificationsPanel);
                            }}
                            count={state.notifications?.filter((x) => x.status === 'new')?.length || 0}
                        />
                        <i
                            className="fa fa-comments-alt"
                            style={{
                                color: theme.colors.primary,
                                fontSize: 30,
                                marginRight: '5px',
                                marginLeft: '5px',
                                marginTop: 10,
                            }}
                            onClick={() => updateState({ ...state, showSendMsgModal: true })}
                        />
                        <Button
                            sx={{
                                zIndex: 100,
                                borderRadius: 12,
                                ml: 10,
                            }}
                            onClick={async () => {
                                try {
                                    await Auth.signOut();
                                } catch (ex) {
                                    // swallow
                                }
                                window.location.assign('/');
                            }}
                        >
                            <Flex>
                                <i
                                    className="fa fa-sign-out"
                                    style={{
                                        color: 'white',
                                        fontSize: 20,
                                        marginRight: '5px',
                                        marginLeft: '5px',
                                    }}
                                />
                                <Paragraph>Sign out</Paragraph>
                            </Flex>
                        </Button>
                    </Flex>
                    {showNotificationsPanel && (
                        <Flex sx={{ position: 'fixed', bottom: 0, right: '10px', zIndex: 3000 }}>
                            <NotificationsPanel
                                userType={organisation?.entity?.user_type}
                                toggleNotificationsPanel={() => toggleNotificationsPanel(!toggleNotificationsPanel)}
                                state={state}
                                updateState={updateState}
                            />
                        </Flex>
                    )}
                </>
            )}
            {organisation?.entity?.user_type === 'org_user' ? (
                <>
                    <Flex
                        sx={{
                            position: 'absolute',
                            right: 20,
                            top: 30,
                            zIndex: 100,
                            cursor: 'pointer',
                        }}
                        onClick={() => window.location.assign('/settings')}
                    >
                        <Flex sx={{ mr: '10px', mt: '5px' }}>
                            <Flex
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    toggleNotificationsPanel(false);
                                    toggleChatMini(!showChatMini);
                                }}
                            >
                                <i
                                    className="fa fa-comments"
                                    style={{ color: theme.colors.primary, fontSize: 30, marginRight: 20 }}
                                />
                            </Flex>
                            <NotificationsIcon
                                onClick={() => {
                                    toggleChatMini(false);
                                    toggleNotificationsPanel(!showNotificationsPanel);
                                }}
                                count={state.notifications?.filter((x) => x.status === 'new')?.length || 0}
                            />
                        </Flex>

                        {(organisation?.entity?.avatarData || organisation?.entity?.user?.email_address) && (
                            <Image
                                sx={{
                                    width: 40,
                                    height: 40,
                                    mt: '0px',
                                }}
                                src={
                                    organisation?.entity?.avatarData ||
                                    `data:image/png;base64,${new Identicon(organisation?.entity?.user?.email_address)}`
                                }
                                title={userLabel}
                                alt="avatar"
                            />
                        )}
                    </Flex>
                    {showChatMini && (
                        <Flex
                            sx={{
                                height: '600px',
                                boxShadow: ' 0px 0px 12px rgba(0,0,0,0.2)',
                                position: 'fixed',
                                bottom: '0',
                                right: '10px',
                                backgroundColor: 'white',
                                border: `1px solid ${theme.colors.primary}`,
                                pb: '30px',
                                zIndex: 99999,
                            }}
                        >
                            <i
                                onClick={() => toggleChatMini(false)}
                                style={{
                                    zIndex: 99999,
                                    right: '15px',
                                    top: '15px',
                                    cursor: 'pointer',
                                    color: theme.colors.primary,
                                    position: 'absolute',
                                }}
                                className="fa fa-window-close"
                            />
                            <ChatBot
                                state={state}
                                updateState={updateState}
                                docIds={[]}
                                user={{
                                    ...user,
                                    email: organisation?.entity?.user?.email_address,
                                }}
                                stateRef={stateRef}
                                queryDocument={queryDocument}
                                type="clients"
                            />
                        </Flex>
                    )}

                    {showNotificationsPanel && (
                        <Flex sx={{ position: 'fixed', bottom: 0, right: '10px', zIndex: 3000 }}>
                            <NotificationsPanel
                                userType={organisation?.entity?.user_type}
                                toggleNotificationsPanel={() => toggleNotificationsPanel(!toggleNotificationsPanel)}
                                state={state}
                                updateState={updateState}
                            />
                        </Flex>
                    )}
                </>
            ) : null}
            {children}
            {!cookiesEnabled && <CookieBanner toggleCookiesEnabled={toggleCookiesEnabled} />}
        </Flex>
    );
};

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        organisation: account.organisation,
        user: account.user,
        timestampOfLastDataLoad: account.timestampOfLastDataLoad,
    };
};

const mapDispatchToProps = (dispatch) => ({
    resize: () => dispatch({ type: userActions.RESIZE }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper);
