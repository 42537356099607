/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-undef */
import { Button, Flex, Input, Label, Paragraph, Spinner } from 'theme-ui';
import ReactSelect from 'react-select';
import { components } from 'react-select';
import { useEffect, useState } from 'react';
import { frequencyOptions } from '../settings/manage-workflow';
import Divider from '../divider';
import axios from 'axios';
import moment from 'moment';

export const deleteSchedule = async (state, updateState, localState, updateLocalState) => {
    updateState({
        ...state,
        loading: true,
    });

    updateLocalState({ ...localState, error: null });

    try {
        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/tasks/${state.task.id}/clone-events`,
            method: 'PUT',
            data: {
                events: [],
            },
        });

        const { data } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/tasks/${state.task.id}`,
        });

        updateState({
            ...state,
            loading: false,
            fullTaskData: {
                ...state.fullTaskData,
                clone_events: data.clone_events,
            },
            showFrequencySetupModal: false,
        });
    } catch (e) {
        updateState({
            ...state,
            loading: false,
        });
        updateLocalState({ ...localState, error: 'We are currently unable to process this, please contact support' });
    }
};

export const saveSchedule = async (state, updateState, localState, updateLocalState) => {
    if (!localState.newFrequencySelection?.length) {
        return updateLocalState({ ...localState, error: 'Please set when the task should be cloned' });
    }
    if (!localState.whenToCreate?.value) {
        return updateLocalState({ ...localState, error: 'Please set the day the end date should be set to' });
    }
    if (!localState.starting_on) {
        return updateLocalState({ ...localState, error: 'Please set when the cloning should begin' });
    }

    updateState({
        ...state,
        loading: true,
    });

    updateLocalState({ ...localState, error: null });

    try {
        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/tasks/${state.task.id}/clone-events`,
            method: 'PUT',
            data: {
                events: localState.newFrequencySelection?.map((x) => ({
                    when_to_run: x.value,
                    when_to_set_service_end_date: localState.whenToCreate.value,
                    frequency: localState.every,
                    starting_on: moment(localState.starting_on).format('YYYY-MM-DD'),
                    additional_data: {
                        partners: localState.partner_for_component,
                        reviewers: localState.reviewer_for_component,
                        assigned_to: localState.assigned_to_for_component,
                    },
                })),
            },
        });

        const { data } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/tasks/${state.task.id}`,
        });

        updateState({
            ...state,
            loading: false,
            fullTaskData: {
                ...state.fullTaskData,
                clone_events: data.clone_events,
            },
            showFrequencySetupModal: false,
        });
    } catch (e) {
        updateState({
            ...state,
            loading: false,
        });
        updateLocalState({ ...localState, error: 'We are currently unable to process this, please contact support' });
    }
};

const TaskCloneModal = ({ state, updateState }) => {
    const [localState, updateLocalState] = useState({
        partner_for_component:
            state.fullTaskData?.clone_events?.[0]?.additional_data?.partners ||
            state.fullTaskData?.partner_for_component,
        reviewer_for_component:
            state.fullTaskData?.clone_events?.[0]?.additional_data?.reviewers ||
            state.fullTaskData?.reviewer_for_component,
        assigned_to_for_component:
            state.fullTaskData?.clone_events?.[0]?.additional_data?.assigned_to ||
            state.fullTaskData?.assigned_to_for_component,
        newFrequencySelection: state.fullTaskData?.clone_events?.map((x) => ({
            value: x.when_to_run,
            label: [
                ...frequencyOptions,
                { value: 'end_of_month', label: 'End of month' },
                { value: 'last_business_day_of_month', label: 'Last business day of month' },
            ]?.find((y) => y.value === x.when_to_run)?.label,
        })),
        every: state.fullTaskData?.clone_events?.[0]?.frequency || 1,
        starting_on: state.fullTaskData?.clone_events?.[0]?.starting_on
            ? moment(state.fullTaskData?.clone_events?.[0]?.starting_on, 'YYYY-MM-DD').format('YYYY-MM-DD')
            : null,
        whenToCreate: state.fullTaskData?.clone_events?.[0]
            ? {
                  value: state.fullTaskData?.clone_events?.[0]?.when_to_set_service_end_date,
                  label: [
                      { value: 'last_business_day', label: 'Last business day of month' },
                      { value: 'end_of_month', label: 'End of month' },
                      { value: 'end_of_quarter', label: 'End of quarter' },
                      { value: 'end_of_previous_month', label: 'End of previous month' },
                      ...frequencyOptions,
                  ]?.find((y) => y.value === state.fullTaskData?.clone_events?.[0]?.when_to_set_service_end_date)
                      ?.label,
              }
            : null,
    });

    return (
        <Flex sx={{ mt: 30, ml: 20, flexDirection: 'column', overflow: 'visible' }}>
            <Paragraph sx={{ fontWeight: 600, fontSize: 17, color: 'text' }}>Schedule task to be cloned</Paragraph>

            <Divider width="97%" />

            <Flex sx={{}}>
                <Flex sx={{ flexDirection: 'column' }}>
                    <Flex sx={{ flexDirection: 'column' }}>
                        <Label id="freq-label" sx={{ mt: 0 }}>
                            When
                        </Label>

                        <ReactSelect
                            aria-labelledby="freq-label"
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                    width: '400px',
                                    minHeight: '40px',
                                    marginTop: '10px',
                                    borderRadius: 10,
                                    marginBottom: '10px',
                                    border: '1px solid #a3a69b',
                                    fontSize: '14px',
                                }),
                            }}
                            isMulti
                            onChange={async (c) => {
                                updateLocalState({
                                    ...localState,
                                    newFrequencySelection: c,
                                });
                            }}
                            placeholder="Select when this task should be created"
                            value={localState.newFrequencySelection}
                            options={[
                                ...frequencyOptions.slice(3),
                                { value: 'end_of_month', label: 'End of month' },
                                { value: 'last_business_day_of_month', label: 'Last business day of month' },
                            ]}
                        />

                        <Label id="freq-label" sx={{ mt: 10, width: 400 }}>
                            New Relevant Period End Date
                        </Label>

                        <ReactSelect
                            aria-labelledby="freq-label"
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                    width: '400px',
                                    minHeight: '40px',
                                    marginTop: '10px',
                                    borderRadius: 10,
                                    marginBottom: '10px',
                                    border: '1px solid #a3a69b',
                                    fontSize: '14px',
                                }),
                            }}
                            onChange={async (c) => {
                                updateLocalState({
                                    ...localState,
                                    whenToCreate: c,
                                });
                            }}
                            placeholder="Select on what day the end date should be set"
                            value={localState.whenToCreate}
                            options={[
                                { value: 'last_business_day', label: 'Last business day of month' },
                                { value: 'end_of_month', label: 'End of month' },
                                { value: 'end_of_quarter', label: 'End of quarter' },
                                { value: 'end_of_previous_month', label: 'End of previous month' },
                                ...frequencyOptions.slice(3),
                            ]}
                        />

                        <Flex>
                            <Flex sx={{ flexDirection: 'column' }}>
                                <Label id="every-label" sx={{ mt: 10, mb: 10 }}>
                                    Every
                                </Label>

                                <Flex sx={{ alignItems: 'center' }}>
                                    <Input
                                        onChange={(e) =>
                                            updateLocalState({
                                                ...localState,
                                                every: e.target.value,
                                            })
                                        }
                                        defaultValue={localState.every}
                                        type="number"
                                        sx={{ borderRadius: 10, fontSize: 14, width: 50, height: 40, mr: 10 }}
                                    />
                                    <Paragraph sx={{ fontSize: 14 }}>month(s)</Paragraph>
                                </Flex>
                            </Flex>

                            <Flex sx={{ flexDirection: 'column', ml: 30 }}>
                                <Label id="starting-on-label" sx={{ mt: 10, mb: 10, width: 400 }}>
                                    Starting on
                                </Label>

                                <Flex sx={{ alignItems: 'center' }}>
                                    <Input
                                        onChange={(e) =>
                                            updateLocalState({
                                                ...localState,
                                                starting_on: e.target.value,
                                            })
                                        }
                                        defaultValue={localState.starting_on}
                                        type="date"
                                        sx={{ borderRadius: 10, fontSize: 14, width: 150, height: 40, mr: 10 }}
                                    />
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex sx={{ flexDirection: 'column', ml: -120 }}>
                    <Flex sx={{ mt: 0, flexDirection: 'column' }}>
                        <Label id="partners-label" sx={{ mr: 30, fontSize: 15 }}>
                            Partners assigned
                        </Label>
                        <ReactSelect
                            aria-labelledby="partners-label"
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                    width: '370px',
                                    minHeight: '40px',
                                    marginTop: '10px',
                                    borderRadius: 10,
                                    marginBottom: '10px',
                                    fontSize: '14px',
                                    border: `1px solid ${
                                        state?.organisation?.task_configuration?.mandatory_fields?.includes(
                                            'partners'
                                        ) && !state.fullTaskData?.partner_for_component?.length
                                            ? 'red'
                                            : '#a3a69b'
                                    }`,
                                }),
                            }}
                            isMulti
                            onChange={async (c) => {
                                updateLocalState({
                                    ...localState,
                                    partner_for_component: c,
                                });
                            }}
                            components={{
                                MultiValueLabel: ({ children, ...rest }) => {
                                    return (
                                        <components.MultiValueLabel {...rest}>
                                            <a
                                                target={'_blank'}
                                                href={`/tasks?user_id=${rest.data.value}&raw_id=1`}
                                                style={{ textDecoration: 'none' }}
                                                rel="noreferrer"
                                            >
                                                <Flex
                                                    sx={{
                                                        width: '370px',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {children}
                                                </Flex>
                                            </a>
                                        </components.MultiValueLabel>
                                    );
                                },
                            }}
                            placeholder="Select..."
                            value={localState?.partner_for_component}
                            options={[
                                ...state?.users?.map((x) => ({
                                    value: x.raw_id,
                                    label: x.first_name ? `${x.first_name} ${x.last_name}` : x.email_address,
                                })),
                            ]}
                        />
                    </Flex>

                    <Flex sx={{ mt: 10, flexDirection: 'column' }}>
                        <Label id="preparers-label" sx={{ mr: 30, fontSize: 15 }}>
                            Preparers
                        </Label>
                        <ReactSelect
                            aria-labelledby="preparers-label"
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                    width: '370px',
                                    minHeight: '40px',
                                    marginTop: '10px',
                                    borderRadius: 10,
                                    marginBottom: '10px',
                                    fontSize: '14px',
                                    border: `1px solid ${
                                        state?.organisation?.task_configuration?.mandatory_fields?.includes(
                                            'assigned_to'
                                        ) && !state.fullTaskData?.assigned_to_for_component?.length
                                            ? 'red'
                                            : '#a3a69b'
                                    }`,
                                }),
                            }}
                            components={{
                                MultiValueLabel: ({ children, ...rest }) => {
                                    return (
                                        <components.MultiValueLabel {...rest}>
                                            <a
                                                target={'_blank'}
                                                href={`/tasks?user_id=${rest.data.value}&raw_id=1`}
                                                style={{ textDecoration: 'none' }}
                                                rel="noreferrer"
                                            >
                                                <Flex
                                                    sx={{
                                                        width: '370px',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {children}
                                                </Flex>
                                            </a>
                                        </components.MultiValueLabel>
                                    );
                                },
                            }}
                            isMulti
                            onChange={async (c) => {
                                updateLocalState({
                                    ...localState,
                                    assigned_to_for_component: c,
                                });
                            }}
                            placeholder="Select..."
                            value={localState?.assigned_to_for_component}
                            options={[
                                ...state?.users?.map((x) => ({
                                    value: x.raw_id,
                                    label: x.first_name ? `${x.first_name} ${x.last_name}` : x.email_address,
                                })),
                            ]}
                        />
                    </Flex>

                    <Flex sx={{ mt: 10, flexDirection: 'column' }}>
                        <Label id="reviewers-label" sx={{ mr: 30, fontSize: 15 }}>
                            Reviewers
                        </Label>
                        <ReactSelect
                            aria-labelledby="reviewers-label"
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                    width: '370px',
                                    minHeight: '40px',
                                    marginTop: '10px',
                                    borderRadius: 10,
                                    marginBottom: '10px',
                                    border: `1px solid ${
                                        state?.organisation?.task_configuration?.mandatory_fields?.includes(
                                            'reviewers'
                                        ) && !state.fullTaskData?.reviewer_for_component?.length
                                            ? 'red'
                                            : '#a3a69b'
                                    }`,
                                    fontSize: '14px',
                                }),
                            }}
                            components={{
                                MultiValueLabel: ({ children, ...rest }) => {
                                    return (
                                        <components.MultiValueLabel {...rest}>
                                            <a
                                                target={'_blank'}
                                                href={`/tasks?user_id=${rest.data.value}&raw_id=1`}
                                                style={{ textDecoration: 'none' }}
                                                rel="noreferrer"
                                            >
                                                <Flex
                                                    sx={{
                                                        width: '370px',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {children}
                                                </Flex>
                                            </a>
                                        </components.MultiValueLabel>
                                    );
                                },
                            }}
                            onChange={async (c) => {
                                updateLocalState({
                                    ...localState,
                                    reviewer_for_component: c,
                                });
                            }}
                            isMulti
                            placeholder="Select..."
                            value={localState?.reviewer_for_component}
                            options={[
                                ...state?.users?.map((x) => ({
                                    value: x.raw_id,
                                    label: x.first_name ? `${x.first_name} ${x.last_name}` : x.email_address,
                                })),
                            ]}
                        />
                    </Flex>
                </Flex>

                <Flex sx={{ mt: 10, flexDirection: 'row', position: 'absolute', ml: 'calc(50% - 100px)', bottom: 15 }}>
                    <Button
                        onClick={() => saveSchedule(state, updateState, localState, updateLocalState)}
                        sx={{ width: 100, height: 40 }}
                    >
                        Save
                    </Button>
                    <Button
                        onClick={() => deleteSchedule(state, updateState, localState, updateLocalState)}
                        sx={{
                            width: 100,
                            height: 40,
                            ml: 10,
                            border: '1px solid lightGrey',
                            background: '#FFF',
                            color: 'text',
                        }}
                    >
                        Delete
                    </Button>
                </Flex>
            </Flex>
            {localState.error && <Paragraph sx={{ mt: 20, color: 'red' }}>{localState.error}</Paragraph>}
        </Flex>
    );
};

export default TaskCloneModal;
