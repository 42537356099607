import { Box, Flex, Image } from 'theme-ui';

const MainLogo = ({ organisation, sxContainer }) => (
    <Flex sx={{ alignItems: 'center', ...(sxContainer || {}) }}>
        <Image
            sx={{ display: ['none', 'none', 'block'], mt: 40, width: 300, mb: 0, ml: 0 }}
            src={'https://cdn.accru.finance/af/logo-dark.png'}
            alt=""
        />
        <Image
            sx={{ display: ['block', 'block', 'none'], mt: 35, width: 60, mb: 0, ml: 0 }}
            src={'https://cdn.accru.finance/af/icon-dark.png'}
            alt=""
        />

        {organisation?.default_visualisation?.logo && (
            <Box sx={{ display: ['none', 'block'] }}>
                <Flex sx={{ alignItems: 'center', mt: 45 }}>
                    <i
                        style={{
                            marginLeft: 10,
                            marginRight: 20,
                            fontSize: '23px',
                            cursor: 'pointer',
                        }}
                        className="fal fa-handshake"
                        aria-hidden="true"
                    />
                    <Image
                        sx={{ mt: '8px', height: 20, width: 300, mb: 10, ml: 0 }}
                        src={organisation?.default_visualisation?.logo}
                        alt=""
                    />
                </Flex>
            </Box>
        )}
    </Flex>
);

export default MainLogo;
