/* eslint-disable no-undef */
import { Button, Flex, Input, Paragraph } from 'theme-ui';
import { PgPaginationV2 } from '../documents/paging';
import axios from 'axios';
import { debounce } from 'debounce';
import Divider from '../divider';

const unlinkUser = (email_address, state, updateState) => async () => {
    try {
        updateState({ ...state, loading: true, error: null });

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations/unlink-user`,
            method: 'POST',
            data: {
                email_address,
            },
        });

        const {
            data: { users, total: usersTotal },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations/users`,
        });

        updateState({ ...state, loading: false, error: null, users, usersTotal });
    } catch (e) {
        console.log(e);
        updateState({
            ...state,
            loading: false,
            error: 'Uh oh, there has been an error unlinking this user from your organisation',
        });
    }
};

const UsersTable = ({ organisation, updateState, state }) => {
    const handleDescriptionChange = debounce(async (e) => {
        updateState({ ...state, userSearchClause: e.target.value });
    }, 500);

    return (
        <Flex sx={{ flexDirection: 'column' }}>
            <Paragraph sx={{ fontSize: 20, mt: 20, fontWeight: 600 }}>Users</Paragraph>
            <Divider width="1200px" />

            {organisation?.user?.role === 'admin' && (
                <Button
                    sx={{
                        border: '1px solid #ccc',
                        backgroundColor: 'white',
                        color: 'text',
                        fontSize: 14,
                        height: 40,
                        width: 150,
                        mt: 10,
                    }}
                    onClick={() =>
                        updateState({
                            ...state,
                            userView: 'invite',
                        })
                    }
                >
                    Invite new user
                </Button>
            )}

            <Flex>
                <Input
                    sx={{
                        mt: organisation?.user?.role === 'admin' ? '20px' : '10px',
                        mb: '0px',
                        width: '300px',
                        background: '#FFFFFF',
                        border: '1px solid #a3a69b',
                        borderRadius: '10px',
                        height: '42px',
                        fontSize: '14px',
                    }}
                    type="text"
                    id="userSearchName"
                    data-testid="userSearchName"
                    name="userSearchName"
                    label=""
                    placeholder={'Search for users by email address'}
                    onChange={handleDescriptionChange}
                    error={null}
                />
            </Flex>

            <Flex sx={{ flexDirection: 'column', mt: 20, fontSize: 14 }}>
                <Flex
                    sx={{
                        width: 800,
                        borderLeft: '1px solid #ccc',
                        borderRight: '1px solid #ccc',
                        borderTop: '1px solid #ccc',
                        borderBottom: '1px solid #ccc',
                        backgroundColor: 'primary',
                        color: 'white',
                    }}
                >
                    <Flex sx={{ padding: 20, wordWrap: 'break-word', width: 500, borderRight: '1px solid #ccc' }}>
                        <Paragraph sx={{ fontWeight: 600 }}>Email address</Paragraph>
                    </Flex>
                    <Flex sx={{ padding: 20, width: 200, borderRight: '1px solid #ccc' }}>
                        <Paragraph sx={{ fontWeight: 600 }}>Role</Paragraph>
                    </Flex>
                    <Flex sx={{ width: 100 }}></Flex>
                </Flex>
                {state?.users?.map((x, idx) => (
                    <Flex
                        key={x.title}
                        sx={{
                            boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.2)',
                            borderLeft: '1px solid #ccc',
                            borderRight: '1px solid #ccc',
                            borderBottom: idx === state.users.length - 1 ? '1px solid #ccc' : 'unset',
                            width: 800,
                        }}
                    >
                        <Flex sx={{ padding: 20, wordWrap: 'break-word', width: 500, borderRight: '1px solid #ccc' }}>
                            <Paragraph>{x.email_address}</Paragraph>
                        </Flex>
                        <Flex sx={{ padding: 20, wordWrap: 'break-word', width: 200, borderRight: '1px solid #ccc' }}>
                            <Paragraph>{x.role || 'user'}</Paragraph>
                        </Flex>
                        <Flex sx={{ padding: 20, width: 100, justifyContent: 'flex-end' }}>
                            {organisation?.user?.role === 'admin' && (
                                <>
                                    <i
                                        style={{
                                            marginLeft: '10px',
                                            fontSize: '12px',
                                            cursor: 'pointer',
                                        }}
                                        className="fas fa-pencil"
                                        aria-hidden="true"
                                        onClick={() =>
                                            updateState({
                                                ...state,
                                                userView: 'edit',
                                                user: x,
                                            })
                                        }
                                    />

                                    <i
                                        style={{
                                            marginLeft: '30px',
                                            fontSize: '12px',
                                            cursor: 'pointer',
                                            color: 'red',
                                        }}
                                        className="fas fa-trash"
                                        aria-hidden="true"
                                        onClick={() =>
                                            updateState({
                                                ...state,
                                                showUnlinkUserConfirmation: true,
                                                confirmCallback: unlinkUser(x.email_address, state, updateState),
                                            })
                                        }
                                    />
                                </>
                            )}
                        </Flex>
                    </Flex>
                ))}
                <Flex sx={{ width: 800 }}>
                    <PgPaginationV2
                        currentState={state}
                        updateState={updateState}
                        totalPages={state.totalUsers ? Math.ceil(state.totalUsers / state.usersLimit) : 1}
                        totalRecords={state.totalUsers}
                        offsetKey="usersOffset"
                        totalKey="totalUsers"
                        limitKey="usersLimit"
                    />
                </Flex>
            </Flex>
        </Flex>
    );
};

export default UsersTable;
