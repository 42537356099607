/* eslint-disable no-undef */
import { Button, Flex, Image, Paragraph } from 'theme-ui';
import Divider from '../divider';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';

const Integrations = ({ organisation }) => {
    return (
        <Flex sx={{ flexDirection: 'column', mt: 20 }}>
            <Paragraph sx={{ fontSize: 20, fontWeight: 600 }}>Integrations</Paragraph>
            <Divider width="1200px" />
            <Flex sx={{ alignItems: 'center', mt: 20 }}>
                <Image src="/quickbooks.png" width="250px" />
                {organisation?.quickbooks_status === 'requires_connection' ? (
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href={`${process.env.REACT_APP_AQRU_AI_API}/quickbooks/auth?org_uuid=${organisation.id}`}
                    >
                        <Button sx={{ ml: 20 }}>Connect</Button>
                    </a>
                ) : null}
                {organisation?.quickbooks_status === 'connected' ? (
                    <Flex sx={{ flexDirection: 'row', alignItems: 'center', mt: 10 }}>
                        <Paragraph sx={{ color: 'green', ml: 20 }}>Connected</Paragraph>
                        <Button
                            onClick={async () => {
                                try {
                                    await axios.post(`${process.env.REACT_APP_AQRU_AI_API}/quickbooks/disconnect`, {});
                                    window.location.assign(`/settings?tab=integrations`);
                                } catch (e) {
                                    toast(
                                        'We have been unable to disconnect your Quickbooks account, please try again later',
                                        {
                                            type: 'error',
                                        }
                                    );
                                }
                            }}
                            sx={{ ml: 20 }}
                        >
                            Disconnect
                        </Button>
                    </Flex>
                ) : null}
            </Flex>

            <Flex sx={{ alignItems: 'center', mt: 60, mb: 40 }}>
                <Image src="/iris.png" width="150px" />
                <Paragraph sx={{ ml: 120 }}>Coming soon</Paragraph>
            </Flex>
            <ToastContainer />
        </Flex>
    );
};

export default Integrations;
