/* eslint-disable no-useless-escape */
/** @jsxImportSource theme-ui */
import { useState } from 'react';
import { Heading, Box, Link, Flex, Image, Paragraph } from 'theme-ui';
import { Auth } from 'aws-amplify';
import Button from '../../components/button';
import Input from '../../components/form-input';
import Spinner from '../../components/spinner';
import PasswordValidation from '../../components/password-validation';
import validatePassword from '../../utils/validate-password';
import { useNavigate } from 'react-router-dom';
import Waves from '../waves';
import { toast, ToastContainer } from 'react-toastify';

const allowedSpecialChars = `!\"#$%&'()*+,-./:;<=>?@[\\]^_\`{|}~`;

async function setPassword(navigate: any, currentState: any, updateState: any) {
    try {
        if (validatePassword(currentState.password).filter((check) => !check).length > 0) {
            return updateState({ ...currentState, passwordValidated: false });
        }

        for (const char of currentState.password) {
            if (!char.match(/[A-Za-z0-9]/) && !allowedSpecialChars.includes(char)) {
                return toast(
                    `Password contains invalid character: ${char}. Allowed special characters are: ${allowedSpecialChars}`,
                    {
                        type: 'error',
                    }
                );
            }
        }

        updateState({ ...currentState, loading: true, passwordValidated: true });

        const { username, code } = Object.fromEntries(new URLSearchParams(window.location.search).entries());

        await Auth.forgotPasswordSubmit(username, code, currentState.password);
        await Auth.signIn(username, currentState.password);

        navigate('/');
    } catch (error) {
        updateState({
            ...currentState,
            loading: false,
            error: 'We are currently unable to process your request, please try again later.',
            passwordValidated: true,
        });
    }
}

const SetPassword = (props: any) => {
    const [currentState, updateState] = useState({
        loading: false,
        passwordValidated: true,
        error: null,
        type: 'password',
        password: '',
    });

    const navigate = useNavigate();

    return (
        <Flex
            sx={{
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                minHeight: '100vh',
                background: 'primary',
                backgroundSize: 'cover',
            }}
        >
            <Flex
                sx={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    background: 'white',
                    width: ['100%', '600px'],
                    height: '550px',
                    p: '50px',
                    borderRadius: '10px',
                }}
            >
                <Waves />
                {currentState.loading && <Spinner />}

                <Box sx={{ margin: '30px auto' }}>
                    <Image
                        src={`https://cdn.accru.finance/af/logo-dark.png`}
                        width="260px"
                        height="auto"
                        alt="refer a friend"
                        data-testid="login-logo"
                    />
                </Box>

                <Heading sx={{ textAlign: 'center', mt: 20, mb: 30 }}>Enter your new password</Heading>

                <Flex>
                    <Input
                        sx={{ mb: 2, width: [270, 320] }}
                        id="password"
                        name="password"
                        type={currentState.type}
                        data-testid="password"
                        autoComplete="new-password"
                        placeholder="Enter your new password"
                        onChange={(e: any) => updateState({ ...currentState, password: e.target.value })}
                        error={!currentState.passwordValidated}
                        onKeyDown={(e: any) => {
                            if (e.key === 'Enter') {
                                setPassword(navigate, currentState, updateState);
                            }
                        }}
                    />
                    <Box
                        onClick={() =>
                            updateState({
                                ...currentState,
                                type: currentState.type === 'password' ? 'text' : 'password',
                            })
                        }
                        sx={{
                            mt: 10,
                            ml: '5px',
                            alignSelf: 'flex-start',
                            '&:hover': {
                                cursor: 'pointer',
                            },
                            ...(props.style ? props.style : {}),
                        }}
                    >
                        <Image
                            sx={{ width: '25px' }}
                            src={`${process.env.REACT_APP_CDN_BASE_URL}/registration/elipsis.png`}
                            alt="Click to show password"
                        />
                    </Box>
                </Flex>

                <PasswordValidation password={currentState.password} />

                <Box sx={{ height: 20 }} />

                <Button
                    data-testid="submit"
                    variant="primary-wide"
                    onClick={() => setPassword(navigate, currentState, updateState)}
                >
                    Change password
                </Button>

                <Link
                    onClick={() => navigate('/')}
                    sx={{
                        mt: 20,
                        fontSize: '15px',
                        fontWeight: '400',
                        alignSelf: 'center',
                    }}
                >
                    Cancel
                </Link>
                <ToastContainer />
            </Flex>
        </Flex>
    );
};

export default SetPassword;
