import * as uuid from 'uuid';
import { useDrag, useDrop } from 'react-dnd';
import ReactSelect from 'react-select';
import { Button, Flex, Input, Label, Link, Paragraph, Switch, Textarea } from 'theme-ui';
import Divider from '../divider';
import { deepClone } from '../settings/manage-workflow';

const Question = ({ idx, state, updateState, organisation, attributeOptions, attributeTypes, question }) => {
    const questionIndex = question.originalIndex;

    return (
        <Flex
            sx={{
                flexDirection: 'column',
                padding: 20,
                backgroundColor:
                    state.hoveringOver &&
                    state.hoveringOver.text === state.questionnaire.definition?.questions?.[questionIndex]?.text
                        ? '#CCC'
                        : 'unset',
            }}
        >
            <Flex>
                <Flex sx={{ flexDirection: 'column' }}>
                    <Label sx={{ mt: 0 }}>{idx + 1}. Question text</Label>
                    <Textarea
                        rows={5}
                        onChange={(e) => {
                            const questions = state.questionnaire.definition?.questions || [];
                            questions[questionIndex].text = e.target.value;
                            updateState({
                                ...state,
                                questionnaire: {
                                    ...state.questionnaire,
                                    definition: {
                                        ...(state.questionnaire.definition || {}),
                                        questions,
                                    },
                                },
                            });
                        }}
                        value={state.questionnaire.definition?.questions?.[questionIndex]?.text}
                        sx={{ width: 400, mt: 10 }}
                    />

                    <Label sx={{ mt: 20 }}>Help text (optional)</Label>
                    <Input
                        onChange={(e) => {
                            const questions = state.questionnaire.definition?.questions || [];
                            questions[questionIndex].helpText = e.target.value;
                            updateState({
                                ...state,
                                questionnaire: {
                                    ...state.questionnaire,
                                    definition: {
                                        ...(state.questionnaire.definition || {}),
                                        questions,
                                    },
                                },
                            });
                        }}
                        value={state.questionnaire.definition?.questions?.[questionIndex]?.helpText}
                        sx={{ width: 400, height: 40, mt: 10 }}
                    />

                    <Label sx={{ mt: 20 }}>Mandatory</Label>

                    <Switch
                        defaultChecked={!!state.questionnaire?.definition?.questions[questionIndex].mandatory}
                        onClick={() => {
                            const questions = state.questionnaire.definition?.questions || [];

                            questions[questionIndex].mandatory = !questions[questionIndex].mandatory;

                            updateState({
                                ...state,
                                questionnaire: {
                                    ...state.questionnaire,
                                    definition: {
                                        ...(state.questionnaire.definition || {}),
                                        questions,
                                    },
                                },
                            });
                        }}
                        sx={{ mb: 20, mt: 10 }}
                    />

                    <Label sx={{ mt: 0 }}>Populate client attribute when submitted</Label>

                    <Switch
                        defaultChecked={
                            !!state.questionnaire?.definition?.questions[questionIndex].should_populate_client_attribute
                        }
                        onClick={() => {
                            const questions = state.questionnaire.definition?.questions || [];

                            questions[questionIndex].should_populate_client_attribute =
                                !questions[questionIndex].should_populate_client_attribute;

                            updateState({
                                ...state,
                                questionnaire: {
                                    ...state.questionnaire,
                                    definition: {
                                        ...(state.questionnaire.definition || {}),
                                        questions,
                                    },
                                },
                            });
                        }}
                        sx={{ mb: 20, mt: 10 }}
                    />

                    {state.questionnaire.definition?.questions?.[questionIndex]?.should_populate_client_attribute && (
                        <Flex sx={{ flexDirection: 'column' }}>
                            <Label sx={{ mt: 0 }}>Select attribute</Label>
                            <Flex sx={{ width: 400 }}>
                                <ReactSelect
                                    aria-labelledby="attr-type"
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            width: '400px',
                                            height: '40px',
                                            marginTop: 10,
                                            borderRadius: 10,
                                            marginBottom: '10px',
                                            border: '1px solid #a3a69b',
                                            fontSize: '15px',
                                        }),
                                    }}
                                    onChange={(e) => {
                                        const questions = state.questionnaire.definition?.questions || [];

                                        questions[questionIndex].client_attribute_key = e.value;

                                        if (organisation?.schema[e.value]?.dropdownOptions?.length) {
                                            questions[questionIndex].options = organisation?.schema[
                                                e.value
                                            ]?.dropdownOptions?.map((x) => ({
                                                value: x.id,
                                                label: x.label,
                                            }));
                                            questions[questionIndex].optionsDisabled = true;
                                        } else {
                                            questions[questionIndex].optionsDisabled = false;
                                        }

                                        questions[questionIndex].type = e.type;

                                        updateState({
                                            ...state,
                                            questionnaire: {
                                                ...state.questionnaire,
                                                definition: {
                                                    ...(state.questionnaire.definition || {}),
                                                    questions,
                                                },
                                            },
                                        });
                                    }}
                                    placeholder={'Set the client attribute to populate'}
                                    value={
                                        attributeOptions?.filter(
                                            (x) =>
                                                x.value ===
                                                state.questionnaire.definition?.questions?.[questionIndex]
                                                    ?.client_attribute_key
                                        )?.[0]
                                    }
                                    options={attributeOptions}
                                />
                            </Flex>
                        </Flex>
                    )}
                </Flex>
                <Flex sx={{ flexDirection: 'column', ml: '20px' }}>
                    <Label sx={{}}>Type</Label>
                    <Flex sx={{ width: 300, flexDirection: 'column' }}>
                        <ReactSelect
                            aria-labelledby="attr-type"
                            isDisabled={
                                !!state.questionnaire?.definition?.questions[questionIndex]
                                    .should_populate_client_attribute
                            }
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    width: '200px',
                                    height: '40px',
                                    marginTop: 10,
                                    borderRadius: 10,
                                    marginBottom: '10px',
                                    border: '1px solid #a3a69b',
                                    fontSize: '15px',
                                    boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.3)',
                                }),
                            }}
                            onChange={(e) => {
                                const questions = state.questionnaire.definition?.questions || [];

                                questions[questionIndex].type = e.value;
                                updateState({
                                    ...state,
                                    questionnaire: {
                                        ...state.questionnaire,
                                        definition: {
                                            ...(state.questionnaire.definition || {}),
                                            questions,
                                        },
                                    },
                                });
                            }}
                            placeholder={'Set the attribute type'}
                            value={
                                state.questionnaire.definition?.questions?.[questionIndex]?.type
                                    ? attributeTypes?.find(
                                          (x) =>
                                              x.value ===
                                              state.questionnaire.definition?.questions?.[questionIndex]?.type
                                      )
                                    : null
                            }
                            options={attributeTypes}
                        />
                        {state.questionnaire.definition?.questions?.[questionIndex]?.type === 'dropdown' && (
                            <Flex sx={{ flexDirection: 'column' }}>
                                <Flex
                                    sx={{
                                        mt: 10,
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                    }}
                                >
                                    <Label sx={{ width: 130 }}>Dropdown options</Label>
                                    {!question.optionsDisabled && (
                                        <Paragraph
                                            sx={{
                                                mt: 10,
                                                fontSize: 13,
                                                color: 'green',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                const questions = state.questionnaire.definition?.questions || [];

                                                questions[questionIndex].options = [
                                                    ...(questions[questionIndex]?.options || []),
                                                    {
                                                        value: uuid.v4(),
                                                        label: '',
                                                    },
                                                ];

                                                updateState({
                                                    ...state,
                                                    questionnaire: {
                                                        ...state.questionnaire,
                                                        definition: {
                                                            ...(state.questionnaire.definition || {}),
                                                            questions,
                                                        },
                                                    },
                                                });
                                            }}
                                        >
                                            Add option
                                        </Paragraph>
                                    )}
                                </Flex>
                                {state.questionnaire.definition?.questions?.[questionIndex]?.options?.map(
                                    (option, optionIdx) => (
                                        <Flex key={`option_${optionIdx}`}>
                                            <Input
                                                disabled={question.optionsDisabled}
                                                onChange={(e) => {
                                                    const questions = state.questionnaire.definition?.questions || [];

                                                    const options = questions[questionIndex]?.options || [];

                                                    options[optionIdx].label = e.target.value;

                                                    updateState({
                                                        ...state,
                                                        questionnaire: {
                                                            ...state.questionnaire,
                                                            definition: {
                                                                ...(state.questionnaire.definition || {}),
                                                                questions,
                                                            },
                                                        },
                                                    });
                                                }}
                                                value={
                                                    state.questionnaire.definition?.questions?.[questionIndex]
                                                        ?.options?.[optionIdx]?.label
                                                }
                                                sx={{
                                                    width: 400,
                                                    height: 40,
                                                    mt: 10,
                                                    backgroundColor: question.optionsDisabled ? '#EFEFEF' : 'auto',
                                                }}
                                            />
                                            {!question.optionsDisabled && (
                                                <i
                                                    style={{
                                                        fontSize: '17px',
                                                        cursor: 'pointer',
                                                        color: 'red',
                                                        marginLeft: 20,
                                                        marginTop: 20,
                                                    }}
                                                    className="fas fa-trash"
                                                    aria-hidden="true"
                                                    onClick={() => {
                                                        const questions =
                                                            state.questionnaire.definition?.questions || [];

                                                        const options = questions?.[questionIndex]?.options || [];
                                                        options.splice(optionIdx);

                                                        questions[questionIndex].options = options;

                                                        updateState({
                                                            ...state,
                                                            questionnaire: {
                                                                ...state.questionnaire,
                                                                definition: {
                                                                    ...(state.questionnaire.definition || {}),
                                                                    questions,
                                                                },
                                                            },
                                                        });
                                                    }}
                                                />
                                            )}
                                            {state.questionnaire?.definition?.sections?.length > 1 &&
                                                state.questionnaire?.definition?.questions[questionIndex]
                                                    .goToSection && (
                                                    <Flex sx={{ ml: 20 }}>
                                                        <ReactSelect
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    width: '250px',
                                                                    height: '40px',
                                                                    marginTop: 10,
                                                                    borderRadius: 10,
                                                                    marginBottom: '10px',
                                                                    border: '1px solid #a3a69b',
                                                                    fontSize: '15px',
                                                                }),
                                                            }}
                                                            onChange={(c) => {
                                                                const questions =
                                                                    state.questionnaire.definition?.questions || [];

                                                                const options = questions[questionIndex]?.options || [];

                                                                options[optionIdx].goToSectionIndex = c.value;

                                                                updateState({
                                                                    ...state,
                                                                    questionnaire: {
                                                                        ...state.questionnaire,
                                                                        definition: {
                                                                            ...(state.questionnaire.definition || {}),
                                                                            questions,
                                                                        },
                                                                    },
                                                                });
                                                            }}
                                                            value={
                                                                state.questionnaire?.definition?.questions[
                                                                    questionIndex
                                                                ]?.options?.[optionIdx]?.goToSectionIndex
                                                                    ? {
                                                                          value: state.questionnaire?.definition
                                                                              ?.questions[questionIndex]?.options?.[
                                                                              optionIdx
                                                                          ]?.goToSectionIndex,
                                                                          label: `Continue to section ${
                                                                              state.questionnaire?.definition
                                                                                  ?.questions[questionIndex]?.options?.[
                                                                                  optionIdx
                                                                              ]?.goToSectionIndex + 1
                                                                          }`,
                                                                      }
                                                                    : {
                                                                          value:
                                                                              state.questionnaire?.definition
                                                                                  ?.questions[questionIndex]
                                                                                  .sectionIndex + 1,
                                                                          label: `Continue to section ${
                                                                              (state.questionnaire?.definition
                                                                                  ?.questions[questionIndex]
                                                                                  .sectionIndex || 0) + 2
                                                                          }`,
                                                                      }
                                                            }
                                                            options={[
                                                                {
                                                                    value:
                                                                        state.questionnaire?.definition?.questions[
                                                                            questionIndex
                                                                        ].sectionIndex + 1,
                                                                    label: `Continue to section ${
                                                                        (state.questionnaire?.definition?.questions[
                                                                            questionIndex
                                                                        ].sectionIndex || 0) + 2
                                                                    }`,
                                                                },
                                                                ...(state.questionnaire?.definition?.sections
                                                                    ?.slice(
                                                                        state.questionnaire?.definition?.questions[
                                                                            questionIndex
                                                                        ].sectionIndex + 2
                                                                    )
                                                                    ?.map((item, itemIdx) => ({
                                                                        value: itemIdx + 2,
                                                                        label: `Continue to section ${itemIdx + 3}`,
                                                                    })) || []),
                                                            ]}
                                                        />
                                                    </Flex>
                                                )}
                                        </Flex>
                                    )
                                )}
                                {state.questionnaire?.definition?.sections?.length > 2 && (
                                    <>
                                        <Label sx={{ mt: 20 }}>Go to section based on answer</Label>
                                        <Switch
                                            defaultChecked={
                                                !!state.questionnaire?.definition?.questions[questionIndex].goToSection
                                            }
                                            onClick={() => {
                                                const questions = state.questionnaire.definition?.questions || [];

                                                questions[questionIndex].goToSection =
                                                    !questions[questionIndex].goToSection;

                                                updateState({
                                                    ...state,
                                                    questionnaire: {
                                                        ...state.questionnaire,
                                                        definition: {
                                                            ...(state.questionnaire.definition || {}),
                                                            questions,
                                                        },
                                                    },
                                                });
                                            }}
                                            sx={{ mb: 20, mt: 10 }}
                                        />
                                    </>
                                )}
                            </Flex>
                        )}
                    </Flex>
                </Flex>
                <Flex sx={{ flexDirection: 'column', ml: '0px', mt: 10 }}>
                    <Button
                        disabled={questionIndex === 0}
                        variant="light"
                        sx={{ backgroundColor: questionIndex === 0 ? '#EFEFEF' : '#fff' }}
                        onClick={() => {
                            const newQuestions = deepClone([...(state.questionnaire?.definition?.questions || [])]);

                            const itemToMove = deepClone(newQuestions[questionIndex]);
                            const existingAtLocation = deepClone(newQuestions[questionIndex - 1]);

                            newQuestions[questionIndex - 1] = itemToMove;
                            newQuestions[questionIndex] = existingAtLocation;

                            updateState({
                                ...state,
                                questionnaire: {
                                    ...state.questionnaire,
                                    definition: {
                                        ...(state.questionnaire?.definition || {}),
                                        questions: newQuestions,
                                    },
                                },
                            });
                        }}
                    >
                        <i
                            style={{
                                fontSize: '16px',
                                cursor: 'pointer',
                                color: '#000',
                            }}
                            className="fas fa-arrow-up"
                            aria-hidden="true"
                        />
                    </Button>
                    <Button
                        variant="light"
                        sx={{
                            mt: 10,
                            backgroundColor:
                                questionIndex === state.questionnaire?.definition?.questions.length - 1
                                    ? '#EFEFEF'
                                    : '#fff',
                        }}
                        onClick={() => {
                            const newQuestions = deepClone([...(state.questionnaire?.definition?.questions || [])]);

                            const itemToMove = deepClone(newQuestions[questionIndex]);
                            const existingAtLocation = deepClone(newQuestions[questionIndex + 1]);

                            newQuestions[questionIndex + 1] = itemToMove;
                            newQuestions[questionIndex] = existingAtLocation;

                            updateState({
                                ...state,
                                questionnaire: {
                                    ...state.questionnaire,
                                    definition: {
                                        ...(state.questionnaire?.definition || {}),
                                        questions: newQuestions,
                                    },
                                },
                            });
                        }}
                    >
                        <i
                            style={{
                                fontSize: '16px',
                                cursor: 'pointer',
                                color: '#000',
                            }}
                            className="fas fa-arrow-down"
                            aria-hidden="true"
                        />
                    </Button>
                </Flex>
            </Flex>

            <Link
                onClick={() => {
                    const questions = state.questionnaire.definition?.questions || [];
                    questions.splice(questionIndex, 1);
                    updateState({
                        ...state,
                        questionnaire: {
                            ...state.questionnaire,
                            definition: {
                                ...(state.questionnaire.definition || {}),
                                questions,
                            },
                        },
                    });
                }}
                sx={{ color: 'red', mt: 20, width: 200 }}
            >
                Remove question
            </Link>

            <Divider width="500px" />
        </Flex>
    );
};

export default Question;
