/* eslint-disable no-undef */
import axios from 'axios';
import { useEffect } from 'react';
import Avatar from 'react-avatar-edit';
import { Button, Flex, Image, Input, Label, Paragraph } from 'theme-ui';
import Divider from '../divider';

export const loadAvatar = (organisation) => {
    if (!organisation?.user?.additional_data?.avatar_key) {
        return null;
    }
    try {
        const baseUrl = `https://aflow-avatars-${
            process.env.REACT_APP_IS_LIVE === '1' ? 'prod' : 'uat'
        }.s3.eu-west-1.amazonaws.com`;

        return `${baseUrl}/${organisation?.user?.additional_data?.avatar_key}`;
    } catch (e) {
        return null;
    }
};

const save = async (state, updateState, refreshCoreData) => {
    try {
        if (!state.userFirstName) {
            return updateState({ ...state, error: 'Please enter a first name' });
        }
        if (!state.userLastName) {
            return updateState({ ...state, error: 'Please enter a last name' });
        }

        updateState({ ...state, loading: true, error: null });

        if (state.avatarPreview) {
            console.log(state.avatarPreview);
            const {
                data: { url },
            } = await axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/organisations/users/avatar`,
                method: 'POST',
                data: {
                    content_type: state.avatarContentType,
                },
            });

            await axios.put(url, state.avatarPreview, {
                headers: { 'Content-Type': state.avatarContentType },
            });
        }

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations/users`,
            method: 'PUT',
            data: {
                first_name: state.userFirstName,
                last_name: state.userLastName,
            },
        });

        await refreshCoreData();

        updateState({
            ...state,
            loading: false,
            confirmMessage: 'Successfully Saved',
            error: null,
        });
    } catch (e) {
        return updateState({
            ...state,
            confirmMessage: null,
            avatarFile: null,
            loading: false,
            error: 'There has been an error saving your data, please try again or contact support',
        });
    }
};

const MyProfile = ({ state, updateState, organisation, refreshCoreData }) => {
    useEffect(() => {
        updateState({
            ...state,
            userFirstName: state?.organisation?.user?.first_name,
            userLastName: state?.organisation?.user?.last_name,
            emailAddress: state?.organisation?.user?.email_address,
            avatarPreview: state?.organisation?.avatarData,
        });
    }, [state?.organisation]);

    const onCrop = (preview) => {
        updateState({ ...state, avatarPreview: preview, error: null });
    };

    const onClose = (e) => {
        updateState({ ...state, avatarPreview: null, avatarContentType: null });
    };

    const onBeforeFileLoad = (elem) => {
        if (elem.target.files[0].size > 3000000) {
            updateState({ ...state, error: 'Image is too large' });
            elem.target.value = '';
        } else {
            updateState({ ...state, avatarContentType: elem.target.files[0].type, avatarFile: elem.target.files[0] });
        }
    };

    return (
        <Flex sx={{ flexDirection: 'column', mt: 20 }}>
            <Paragraph sx={{ fontSize: 20, fontWeight: 600 }}>My Profile</Paragraph>
            <Divider width="1200px" />
            <Flex sx={{ flexDirection: 'row' }}>
                <Paragraph sx={{ fontWeight: 400, fontSize: 15, mt: 20 }}>Email Address: </Paragraph>
                <Paragraph sx={{ fontWeight: 400, fontSize: 15, mt: 20, ml: 20 }}>{state.emailAddress}</Paragraph>
            </Flex>

            <Flex sx={{ flexDirection: 'column', mt: 30 }}>
                <Paragraph sx={{ fontWeight: 400, fontSize: 15, mb: 10 }}>Set an avatar: </Paragraph>
                <Flex>
                    <Avatar
                        width={150}
                        height={150}
                        onCrop={onCrop}
                        onClose={onClose}
                        onBeforeFileLoad={onBeforeFileLoad}
                        src={state.avatarSrc}
                    />
                    {state.avatarPreview && (
                        <Image sx={{ width: 150, height: 150, ml: 30 }} src={state.avatarPreview} alt="Preview" />
                    )}
                </Flex>
            </Flex>

            <Flex sx={{ flexDirection: 'column', mt: 20 }}>
                <Label id="first-name-label" sx={{ fontWeight: 400, fontSize: 15, mt: 20 }}>
                    First Name
                </Label>
                <Input
                    aria-labelledby="first-name-label"
                    value={state.userFirstName}
                    onChange={(e) =>
                        updateState({
                            ...state,
                            userFirstName: e.target.value,
                        })
                    }
                    sx={{ width: 400, mt: 10, fontWeight: '400', border: '1px solid #ccc', fontSize: 15, mb: 20 }}
                />
            </Flex>
            <Flex sx={{ flexDirection: 'column' }}>
                <Label id="last-name-label" sx={{ fontWeight: 400, fontSize: 15, mt: 10 }}>
                    Last Name
                </Label>
                <Input
                    aria-labelledby="last-name-label"
                    value={state.userLastName}
                    onChange={(e) =>
                        updateState({
                            ...state,
                            userLastName: e.target.value,
                        })
                    }
                    sx={{ width: 400, mt: 10, fontWeight: '400', border: '1px solid #ccc', fontSize: 15, mb: 20 }}
                />
            </Flex>

            <Button onClick={() => save(state, updateState, refreshCoreData)} sx={{ mt: 20, width: 200 }}>
                Save
            </Button>

            {state.confirmMessage && <Paragraph sx={{ color: 'green', mt: 20 }}>{state.confirmMessage}</Paragraph>}
        </Flex>
    );
};

export default MyProfile;
