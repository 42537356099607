import { Button, Flex, Paragraph } from 'theme-ui';
import SignatureCanvas from '.';
import { useEffect } from 'react';

const SignatureSetupModal = ({ state, updateState }) => {
    const name = `${state.organisation?.user?.first_name} ${state.organisation?.user?.last_name}`;
    useEffect(() => {
        ['Great Vibes', 'Pacifico', 'Dancing Script'].forEach((x, idx) => {
            const canvas = document.getElementById(`canvas-signature-${idx + 1}`);
            const ctx = canvas.getContext('2d');
            ctx.font = `20px ${x}`;
            ctx.fillStyle = 'black';
            ctx.fillText(name, 30, 40);
        });
    }, []);

    return (
        <Flex sx={{ flexDirection: 'column', ml: 20, mt: 20 }}>
            <Paragraph sx={{ fontSize: 20, fontWeight: 600 }}>Provide a signature</Paragraph>

            <Paragraph sx={{ mt: 10 }}>You can either draw a signature:</Paragraph>

            <SignatureCanvas
                defaultValue={state.drawnSignature}
                id={`signature_canvas_123`}
                containerSx={{ mt: 10 }}
                width={250}
                height={60}
                drawingComplete={(res) => {
                    updateState({
                        ...state,
                        drawnSignature: res.base64,
                        signatureBase64: res.base64,
                        signatureChosen: null,
                    });
                }}
            />

            <Paragraph sx={{ mt: 20, mb: 0 }}>or you can select one of the following signatures:</Paragraph>

            <Flex
                sx={{
                    cursor: 'pointer',
                    mt: 20,
                    width: 300,
                    border: state.signatureChosen === 'pacifico' ? '1px solid #000' : '1px solid #EFEFEF',
                }}
                onClick={(e) => {
                    updateState({
                        ...state,
                        signatureChosen: 'pacifico',
                        drawnSignature: '',
                        signatureBase64: document.getElementById('canvas-signature-1').toDataURL('image/png'),
                    });
                }}
            >
                <canvas id="canvas-signature-1" width="300" height="60"></canvas>
            </Flex>
            <Flex
                sx={{
                    cursor: 'pointer',
                    mt: 20,
                    width: 300,
                    border: state.signatureChosen === 'dancing-script' ? '1px solid #000' : '1px solid #EFEFEF',
                }}
                onClick={(e) => {
                    updateState({
                        ...state,
                        signatureChosen: 'dancing-script',
                        drawnSignature: '',
                        signatureBase64: document.getElementById('canvas-signature-2').toDataURL('image/png'),
                    });
                }}
            >
                <canvas id="canvas-signature-2" width="300" height="60"></canvas>
            </Flex>
            <Flex
                sx={{
                    cursor: 'pointer',
                    mt: 20,
                    width: 300,
                    border: state.signatureChosen === 'great-vibes' ? '1px solid #000' : '1px solid #EFEFEF',
                }}
                onClick={(e) => {
                    updateState({
                        ...state,
                        signatureChosen: 'great-vibes',
                        drawnSignature: '',
                        signatureBase64: document.getElementById('canvas-signature-3').toDataURL('image/png'),
                    });
                }}
            >
                <canvas id="canvas-signature-3" width="300" height="60"></canvas>
            </Flex>

            <Button
                sx={{ mt: 20, width: 200 }}
                onClick={() => updateState({ ...state, showSignatureSetupModal: false })}
            >
                Submit
            </Button>

            <Flex sx={{ height: 20 }} />
        </Flex>
    );
};

export default SignatureSetupModal;
