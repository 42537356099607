/* eslint-disable no-undef */
import axios from 'axios';
import { useDrag, useDrop } from 'react-dnd';
import ReactQuill from 'react-quill';
import * as uuid from 'uuid';
import 'react-quill/dist/quill.snow.css';
import ReactSelect from 'react-select';
import { Button, Flex, Input, Label, Paragraph, Switch, Textarea } from 'theme-ui';
import Divider from '../divider';
import moment from 'moment';

export const clientTypeOptions = [
    { value: 'all', label: 'Companies and Individuals' },
    { value: 'company', label: 'Companies only' },
    { value: 'individual', label: 'Individuals only' },
];

export const frequencyOptions = [
    { value: 'on_ch_filing_date', label: 'On Companies House filing date' },
    { value: 'on_creation', label: 'On client creation' },
    { value: 'on_specific_date', label: 'On a specific date' },
    { value: '01', label: '1st of the month' },
    { value: '02', label: '2nd of the month' },
    { value: '03', label: '3rd of the month' },
    { value: '04', label: '4th of the month' },
    { value: '05', label: '5th of the month' },
    { value: '06', label: '6th of the month' },
    { value: '07', label: '7th of the month' },
    { value: '08', label: '8th of the month' },
    { value: '09', label: '9th of the month' },
    { value: '10', label: '10th of the month' },
    { value: '11', label: '11th of the month' },
    { value: '12', label: '12th of the month' },
    { value: '13', label: '13th of the month' },
    { value: '14', label: '14th of the month' },
    { value: '15', label: '15th of the month' },
    { value: '16', label: '16th of the month' },
    { value: '17', label: '17th of the month' },
    { value: '18', label: '18th of the month' },
    { value: '19', label: '19th of the month' },
    { value: '20', label: '20th of the month' },
    { value: '21', label: '21st of the month' },
    { value: '22', label: '22nd of the month' },
    { value: '23', label: '23rd of the month' },
    { value: '24', label: '24th of the month' },
    { value: '25', label: '25th of the month' },
    { value: '26', label: '26th of the month' },
    { value: '27', label: '27th of the month' },
    { value: '28', label: '28th of the month' },
    { value: '29', label: '29th of the month' },
    { value: '30', label: '30th of the month' },
    { value: '31', label: '31st of the month' },
];

const internalDateOptions = [
    {
        value: -1,
        label: '1 day before statutory deadline',
    },
    {
        value: -2,
        label: '2 days before statutory deadline',
    },
    {
        value: -3,
        label: '3 days before statutory deadline',
    },
    {
        value: -4,
        label: '4 days before statutory deadline',
    },
    {
        value: -5,
        label: '5 days before statutory deadline',
    },
    {
        value: -6,
        label: '6 days before statutory deadline',
    },
    {
        value: -7,
        label: '7 days before statutory deadline',
    },
    {
        value: -30,
        label: '1 month before statutory deadline',
    },
    {
        value: 7,
        label: '1 week after Relevant Period end',
    },
    {
        value: 30,
        label: '1 month after Relevant Period end',
    },
];

const saveWorkflowTask = async (state, updateState) => {
    try {
        updateState({ ...state, loading: true, editError: null, confirmMessage: null });

        const frequencyValues = state.workflowTask?.newFrequencySelection?.map((x) => x.value);

        const clientTypesValue = state.workflowTask?.clientTypeSelection?.value || 'all';

        const isCHWorkflowType = frequencyValues?.includes('on_ch_filing_date');

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations/workflow-tasks${
                state?.workflowTask?.id ? `/${state?.workflowTask?.id}` : ''
            }`,
            method: state?.workflowTask?.id ? 'PUT' : 'POST',
            data: {
                service: state.workflowTask?.newWorkflowTaskService?.value,
                definition: {
                    relevantPeriodEnd: state.workflowTask?.definition?.relevantPeriodEnd,
                    title: state.workflowTask?.title,
                    description: state.workflowTask?.description,
                    datesToSchedule: state.workflowTask?.datesToSchedule?.map((date) => {
                        if (date.day?.length === 1) {
                            date.day = `0${date.day}`;
                        }
                        if (date.month?.length === 1) {
                            date.month = `0${date.month}`;
                        }
                        return date;
                    }),
                    frequency: frequencyValues,
                    client_type: clientTypesValue,
                    subtasks: state.workflowTask?.subtasks || [],
                    priority: state.workflowTask?.priority,
                    sendNotification: state.workflowTask?.sendNotification,
                    ...(state.workflowTask?.sendNotification
                        ? { notificationTemplate: state.workflowTask.notificationTemplate }
                        : {}),
                    ...(isCHWorkflowType
                        ? {
                              whenToSetInternalDueDate: state.workflowTask?.whenToSetInternalDueDate?.value,
                          }
                        : {}),
                },
            },
        });

        const {
            data: { tasks, total: workflowTasksTotal },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations/workflow-tasks`,
        });

        updateState({
            ...state,
            loading: false,
            editError: null,
            workflowTask: null,
            confirmMessage: null,
            workflowTasksTotal,
            tasks,
            workflowView: 'list',
        });
    } catch (e) {
        console.log(e);
        updateState({
            ...state,
            loading: false,
            editError: 'Uh oh, there has been an error creating this task',
            confirmMessage: null,
        });
    }
};

export const deepClone = (obj) => (obj ? JSON.parse(JSON.stringify(obj)) : null);

const Subtask = ({ state, updateState, subtask, idx, organisation }) => {
    const [collected, drag, dragPreview] = useDrag(
        () => ({
            type: 'subtask',
            item: { subtask, idx },
            end: (item, monitor) => {
                if (state.idxMovingTo === state.idxOfItemMoving) return;

                const newSubtasks = deepClone([...(state.workflowTask?.subtasks || [])]);

                const itemToMove = deepClone(newSubtasks[state.idxOfItemMoving]);
                const existingAtLocation = deepClone(newSubtasks[state.idxMovingTo]);

                newSubtasks[state.idxMovingTo] = itemToMove;
                newSubtasks[state.idxOfItemMoving] = existingAtLocation;

                updateState({
                    ...state,
                    workflowTask: {
                        ...state.workflowTask,
                        subtasks: newSubtasks,
                    },
                    idxMovingTo: -1,
                    idxOfItemMoving: -1,
                });
            },
        }),
        [state.idxMovingTo, state.idxOfItemMoving, subtask]
    );

    const [, drop] = useDrop(
        () => ({
            accept: 'subtask',
            hover(item) {
                updateState({
                    ...state,
                    idxOfItemMoving: idx,
                    idxMovingTo: item.idx,
                });
            },
        }),
        [state.idxMovingTo, state.idxOfItemMoving]
    );

    return collected.isDragging ? (
        <div ref={dragPreview} />
    ) : (
        <div ref={(node) => drag(drop(node))} {...collected}>
            <Flex sx={{ cursor: 'pointer', mb: 10, padding: 20, border: '1px solid #EFEFEF', flexDirection: 'column' }}>
                <Flex sx={{ mb: 10, alignItems: 'center' }} key={`subtask_${idx}`}>
                    <Textarea
                        value={subtask.title}
                        onChange={(e) => {
                            const newSubtasks = [...(state.workflowTask?.subtasks || [])];
                            newSubtasks[idx].title = e.target.value;
                            updateState({
                                ...state,
                                workflowTask: {
                                    ...state.workflowTask,
                                    subtasks: newSubtasks,
                                },
                            });
                        }}
                        sx={{ width: 450, fontWeight: '400', border: '1px solid #a3a69b', fontSize: 15, mb: 0 }}
                    />
                    <Flex>
                        <i
                            style={{
                                marginTop: '0px',
                                fontSize: '18px',
                                marginLeft: '9px',
                                marginRight: '6px',
                                color: 'red',
                                cursor: 'pointer',
                            }}
                            className="fas fa-trash"
                            onClick={() => {
                                const newSubtasks = [...(state.workflowTask?.subtasks || [])];
                                newSubtasks.splice(idx, 1);
                                updateState({
                                    ...state,
                                    workflowTask: {
                                        ...state.workflowTask,
                                        subtasks: newSubtasks,
                                    },
                                });
                            }}
                        />
                    </Flex>
                </Flex>
                <Flex sx={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <Switch
                        label="Mark as resolved when third party api updates"
                        sx={{ cursor: 'pointer' }}
                        defaultChecked={subtask.autoResolveUsingWorkflow}
                        onClick={() => {
                            const newSubtasks = [...(state.workflowTask?.subtasks || [])];
                            newSubtasks[idx].autoResolveUsingWorkflow = subtask.autoResolveUsingWorkflow ? false : true;
                            updateState({
                                ...state,
                                workflowTask: {
                                    ...state.workflowTask,
                                    subtasks: newSubtasks,
                                },
                            });
                        }}
                    />
                </Flex>
                <Flex sx={{ mt: 15, justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <Switch
                        label="Require user to be an administrator to edit"
                        sx={{ cursor: 'pointer' }}
                        defaultChecked={subtask.requireAdminPermission}
                        onClick={() => {
                            const newSubtasks = [...(state.workflowTask?.subtasks || [])];
                            newSubtasks[idx].requireAdminPermission = subtask.requireAdminPermission ? false : true;
                            updateState({
                                ...state,
                                workflowTask: {
                                    ...state.workflowTask,
                                    subtasks: newSubtasks,
                                },
                            });
                        }}
                    />
                </Flex>
                <Flex sx={{ mt: 15, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <Switch
                        label="Change status when marked as complete"
                        sx={{ cursor: 'pointer' }}
                        defaultChecked={subtask.changeStatus}
                        onClick={() => {
                            const newSubtasks = [...(state.workflowTask?.subtasks || [])];
                            newSubtasks[idx].changeStatus = subtask.changeStatus ? false : true;
                            updateState({
                                ...state,
                                workflowTask: {
                                    ...state.workflowTask,
                                    subtasks: newSubtasks,
                                },
                            });
                        }}
                    />
                    {state.workflowTask?.subtasks?.[idx]?.changeStatus && (
                        <Flex sx={{ ml: 48, mt: 0, alignItems: 'center' }}>
                            <Label id="status-label" sx={{ fontSize: 14, mr: 10 }}>
                                Set task status to
                            </Label>
                            <ReactSelect
                                aria-labelledby="status-label"
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                        width: '250px',
                                        minHeight: '40px',
                                        marginTop: '10px',
                                        borderRadius: 10,
                                        marginBottom: '10px',
                                        border: '1px solid #a3a69b',
                                        fontSize: '14px',
                                    }),
                                }}
                                onChange={async (c) => {
                                    const newSubtasks = [...(state.workflowTask?.subtasks || [])];
                                    newSubtasks[idx].changeStatusTo = c.value;
                                    updateState({
                                        ...state,
                                        workflowTask: {
                                            ...state.workflowTask,
                                            subtasks: newSubtasks,
                                        },
                                    });
                                }}
                                placeholder="Select..."
                                value={
                                    subtask.changeStatusTo
                                        ? {
                                              value: subtask.changeStatusTo,
                                              label: organisation?.default_visualisation?.statuses?.find(
                                                  (x) => x.key === subtask?.changeStatusTo
                                              )?.label,
                                          }
                                        : null
                                }
                                options={[
                                    ...(organisation?.default_visualisation?.statuses?.map((x) => ({
                                        value: x.key,
                                        label: x.label,
                                    })) || []),
                                ]}
                            />
                        </Flex>
                    )}
                </Flex>
                <Flex sx={{ mt: 15, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <Switch
                        label="Suggest email template when complete"
                        sx={{ cursor: 'pointer' }}
                        defaultChecked={subtask.suggestEmailTemplate}
                        onClick={() => {
                            const newSubtasks = [...(state.workflowTask?.subtasks || [])];
                            newSubtasks[idx].suggestEmailTemplate = subtask.suggestEmailTemplate ? false : true;

                            if (subtask.suggestEmailTemplate) {
                                newSubtasks[idx].emailTemplateToSuggest = null;
                            }

                            updateState({
                                ...state,
                                workflowTask: {
                                    ...state.workflowTask,
                                    subtasks: newSubtasks,
                                },
                            });
                        }}
                    />
                    {state.workflowTask?.subtasks?.[idx]?.suggestEmailTemplate && (
                        <Flex sx={{ ml: 48, mt: 0, alignItems: 'center' }}>
                            <Label id="email-tem-label" sx={{ fontSize: 14, mr: 10, width: 115 }}>
                                Email template
                            </Label>
                            <ReactSelect
                                aria-labelledby="email-tem-label"
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                        width: '250px',
                                        minHeight: '40px',
                                        marginTop: '10px',
                                        borderRadius: 10,
                                        marginBottom: '10px',
                                        border: '1px solid #a3a69b',
                                        fontSize: '14px',
                                    }),
                                }}
                                onChange={async (c) => {
                                    const newSubtasks = [...(state.workflowTask?.subtasks || [])];
                                    newSubtasks[idx].emailTemplateToSuggest = c.value;
                                    updateState({
                                        ...state,
                                        workflowTask: {
                                            ...state.workflowTask,
                                            subtasks: newSubtasks,
                                        },
                                    });
                                }}
                                placeholder="Select..."
                                value={
                                    subtask.emailTemplateToSuggest
                                        ? {
                                              value: subtask.emailTemplateToSuggest,
                                              label: state.emailTemplates?.find(
                                                  (x) => x.uuid === subtask?.emailTemplateToSuggest
                                              )?.name,
                                          }
                                        : null
                                }
                                options={[
                                    ...(state.emailTemplates.map((x) => ({
                                        value: x.uuid,
                                        label: x.name,
                                    })) || []),
                                ]}
                            />
                        </Flex>
                    )}
                </Flex>
            </Flex>
        </div>
    );
};

const ManageWorkflow = ({ state, updateState, workflowTask, organisation }) => {
    const [, drop] = useDrop(() => ({
        accept: 'subtask',
    }));

    const services = Object.entries(organisation?.workflow_definition?.services || {}).map((x) => ({
        value: x[0],
        label: x[1]?.label,
    }));

    return (
        <Flex sx={{ flexDirection: 'column', mb: 150 }}>
            <Flex>
                <Button
                    sx={{
                        width: '115px',
                        height: '40px',
                        mt: 20,
                    }}
                    variant="light"
                    onClick={async () => {
                        updateState({
                            ...state,
                            userView: 'list',
                            workflowView: 'list',
                            user: null,
                            newRole: null,
                            confirmMessage: null,
                            editError: null,
                            error: null,
                        });
                    }}
                >
                    <i
                        style={{
                            marginTop: '0px',
                            fontSize: '12px',
                            marginLeft: '3px',
                            marginRight: '6px',
                        }}
                        className={`fas fa-chevron-left`}
                    />{' '}
                    Back
                </Button>
                <Button
                    sx={{
                        border: '1px solid #EFEFEF',
                        backgroundColor: 'white',
                        color: '#000',
                        fontSize: 14,
                        height: 40,
                        width: 100,
                        mt: 20,
                        ml: 10,
                    }}
                    onClick={async () => {
                        await saveWorkflowTask(state, updateState);
                    }}
                >
                    Save
                </Button>
            </Flex>

            <Flex sx={{ mt: 30, flexDirection: 'column' }}>
                <Paragraph sx={{ fontWeight: 600, fontSize: 20 }}>
                    {state.workflowTask?.id ? 'Update task' : 'Create task'}
                </Paragraph>
            </Flex>

            <Flex>
                <Flex sx={{ flexDirection: 'column' }}>
                    <Flex sx={{ mt: 40, flexDirection: 'column' }}>
                        <Label id="task-title" sx={{}}>
                            Task title
                        </Label>
                        <Input
                            aria-labelledby="task-title"
                            value={state.workflowTask?.title || ''}
                            onChange={(e) =>
                                updateState({
                                    ...state,
                                    workflowTask: {
                                        ...state.workflowTask,
                                        title: e.target.value,
                                    },
                                })
                            }
                            sx={{
                                width: 400,
                                mt: 10,
                                fontWeight: '400',
                                border: '1px solid #ccc',
                                fontSize: 15,
                                mb: 20,
                            }}
                        />
                    </Flex>

                    <Flex sx={{ mt: 20, flexDirection: 'column', width: 600, mb: 60 }}>
                        <Label id="task-desc" sx={{ mb: 10 }}>
                            Task description
                        </Label>
                        <ReactQuill
                            theme="snow"
                            value={state.workflowTask?.description}
                            style={{ minHeight: 200 }}
                            onChange={(val) =>
                                updateState({
                                    ...state,
                                    workflowTask: {
                                        ...state.workflowTask,
                                        description: val,
                                    },
                                })
                            }
                        />
                    </Flex>

                    <Flex sx={{ mt: 10, flexDirection: 'column', mb: 10 }}>
                        <Label id="priority-label" sx={{ mr: 30, fontSize: 15 }}>
                            Priority
                        </Label>
                        <ReactSelect
                            aria-labelledby="priority-label"
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                    width: '400px',
                                    minHeight: '40px',
                                    marginTop: '10px',
                                    borderRadius: 10,
                                    border: '1px solid #a3a69b',
                                    fontSize: '14px',
                                }),
                            }}
                            onChange={(c) => {
                                updateState({
                                    ...state,
                                    workflowTask: {
                                        ...state.workflowTask,
                                        priority: c.value,
                                    },
                                });
                            }}
                            placeholder="Select..."
                            value={organisation?.default_visualisation?.priorities?.find(
                                (x) => x.key === state.workflowTask?.priority
                            )}
                            options={[
                                ...(organisation?.default_visualisation?.priorities?.map((x, idx) => ({
                                    value: x.key,
                                    label: x.label,
                                })) || []),
                            ]}
                        />
                    </Flex>

                    <Flex sx={{ mt: 10, flexDirection: 'column', width: 300 }}>
                        <Label id="service-label" sx={{ mr: 30, fontSize: 15 }}>
                            Service
                        </Label>
                        <ReactSelect
                            aria-labelledby="service-label"
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    width: '400px',
                                    minHeight: '40px',
                                    marginTop: '10px',
                                    borderRadius: 10,
                                    marginBottom: '10px',
                                    border: '1px solid #a3a69b',
                                    fontSize: '14px',
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                }),
                            }}
                            onChange={async (c) => {
                                updateState({
                                    ...state,
                                    workflowTask: {
                                        ...state.workflowTask,
                                        newWorkflowTaskService: c,
                                    },
                                });
                            }}
                            placeholder="Select a service this task belongs to"
                            value={state.workflowTask?.newWorkflowTaskService}
                            options={services}
                        />
                    </Flex>

                    <Flex sx={{ mt: 20, flexDirection: 'column', width: 360 }}>
                        <Label id="freq-label" sx={{ mr: 30, fontSize: 15 }}>
                            Frequency of creation
                        </Label>
                        <ReactSelect
                            aria-labelledby="freq-label"
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    width: '400px',
                                    minHeight: '40px',
                                    marginTop: '10px',
                                    borderRadius: 10,
                                    marginBottom: '10px',
                                    border: '1px solid #a3a69b',
                                    fontSize: '14px',
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                }),
                            }}
                            isMulti
                            onChange={async (c) => {
                                updateState({
                                    ...state,
                                    workflowTask: {
                                        ...state.workflowTask,
                                        newFrequencySelection: c,
                                    },
                                });
                            }}
                            placeholder="Select how often this task should be created"
                            value={state.workflowTask?.newFrequencySelection}
                            options={frequencyOptions}
                        />
                        {state.workflowTask?.newFrequencySelection?.find((x) => x.value === 'on_specific_date') && (
                            <Flex sx={{ flexDirection: 'column', mt: 10 }}>
                                <Paragraph sx={{ mb: 10, fontSize: 15 }}>Dates to schedule</Paragraph>
                                <Paragraph
                                    onClick={() => {
                                        const datesToSchedule = state.workflowTask?.datesToSchedule || [];
                                        datesToSchedule.push({ day: '', month: '' });
                                        updateState({
                                            ...state,
                                            workflowTask: {
                                                ...state.workflowTask,
                                                datesToSchedule,
                                            },
                                        });
                                    }}
                                    sx={{ mb: 10, fontSize: 14, color: 'blue', cursor: 'pointer' }}
                                >
                                    Add new
                                </Paragraph>
                                {state.workflowTask?.datesToSchedule?.map((date, idx) => (
                                    <Flex sx={{ alignItems: 'center', mt: idx > 0 ? 10 : 0 }} key={`date_${idx}`}>
                                        <Input
                                            onChange={(e) => {
                                                const datesToSchedule = state.workflowTask?.datesToSchedule || [];
                                                datesToSchedule[idx].day = e.target.value;
                                                updateState({
                                                    ...state,
                                                    workflowTask: {
                                                        ...state.workflowTask,
                                                        datesToSchedule,
                                                    },
                                                });
                                            }}
                                            value={date.day}
                                            maxLength={2}
                                            sx={{ fontSize: 14, width: 45, mr: 10, textAlign: 'center' }}
                                            type="text"
                                            placeholder="DD"
                                        />
                                        <Input
                                            value={date.month}
                                            onChange={(e) => {
                                                const datesToSchedule = state.workflowTask?.datesToSchedule || [];
                                                datesToSchedule[idx].month = e.target.value;
                                                updateState({
                                                    ...state,
                                                    workflowTask: {
                                                        ...state.workflowTask,
                                                        datesToSchedule,
                                                    },
                                                });
                                            }}
                                            maxLength={2}
                                            sx={{ fontSize: 14, width: 45, textAlign: 'center' }}
                                            type="text"
                                            placeholder="MM"
                                        />
                                        <Button
                                            onClick={() => {
                                                const datesToSchedule = state.workflowTask?.datesToSchedule || [];
                                                datesToSchedule.splice(idx, 1);
                                                updateState({
                                                    ...state,
                                                    workflowTask: {
                                                        ...state.workflowTask,
                                                        datesToSchedule,
                                                    },
                                                });
                                            }}
                                            sx={{ ml: 10, backgroundColor: '#EFEFEF' }}
                                            variant="light"
                                        >
                                            Remove
                                        </Button>
                                        {date.day && date.month && (
                                            <Flex>
                                                <Paragraph sx={{ ml: 10 }}>
                                                    {moment(`${date.day}-12-2000`, 'DD-MM-YYYY').format('Do')}
                                                </Paragraph>
                                                <Paragraph sx={{ ml: '5px' }}>
                                                    {moment(`01-${date.month}-2000`, 'DD-MM-YYYY').format('MMMM')}
                                                </Paragraph>
                                            </Flex>
                                        )}
                                    </Flex>
                                ))}
                            </Flex>
                        )}
                    </Flex>

                    <Flex sx={{ mt: 20, flexDirection: 'column', width: 300 }}>
                        <Label id="client-type-label" sx={{ mr: 30, fontSize: 15 }}>
                            Client types
                        </Label>
                        <ReactSelect
                            aria-labelledby="client-type-label"
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    width: '400px',
                                    minHeight: '40px',
                                    marginTop: '10px',
                                    borderRadius: 10,
                                    marginBottom: '10px',
                                    border: '1px solid #a3a69b',
                                    fontSize: '14px',
                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                }),
                            }}
                            onChange={async (c) => {
                                updateState({
                                    ...state,
                                    workflowTask: {
                                        ...state.workflowTask,
                                        clientTypeSelection: c,
                                    },
                                });
                            }}
                            placeholder="Select which client types apply"
                            value={state.workflowTask?.clientTypeSelection || clientTypeOptions[0]}
                            options={clientTypeOptions}
                        />
                    </Flex>

                    <Label id="freq-label" sx={{ mt: 10, width: 400 }}>
                        Relevant Period End Date
                    </Label>

                    <ReactSelect
                        aria-labelledby="freq-label"
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                width: '400px',
                                minHeight: '40px',
                                marginTop: '10px',
                                borderRadius: 10,
                                marginBottom: '10px',
                                border: '1px solid #a3a69b',
                                fontSize: '14px',
                            }),
                        }}
                        onChange={async (c) => {
                            updateState({
                                ...state,
                                workflowTask: {
                                    ...state.workflowTask,
                                    definition: {
                                        ...(state.workflowTask?.definition || {}),
                                        relevantPeriodEnd: c,
                                    },
                                },
                            });
                        }}
                        placeholder="Select on what day the end date should be set"
                        value={state.workflowTask?.definition?.relevantPeriodEnd}
                        options={[
                            { value: 'last_business_day', label: 'Last business day of month' },
                            { value: 'end_of_month', label: 'End of month' },
                            { value: 'end_of_quarter', label: 'End of quarter' },
                            { value: 'end_of_previous_month', label: 'End of previous month' },
                            ...frequencyOptions.slice(3),
                        ]}
                    />

                    {state.workflowTask?.newFrequencySelection?.find((x) =>
                        ['on_ch_filing_date'].includes(x.value)
                    ) && (
                        <Flex sx={{ mt: 20, flexDirection: 'column', width: 300 }}>
                            <Label id="internal-label" sx={{ mr: 30, fontSize: 15 }}>
                                When to set internal due date
                            </Label>
                            <ReactSelect
                                aria-labelledby="internal-label"
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        width: '400px',
                                        minHeight: '40px',
                                        marginTop: '10px',
                                        borderRadius: 10,
                                        marginBottom: '10px',
                                        border: '1px solid #a3a69b',
                                        fontSize: '14px',
                                        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                    }),
                                }}
                                onChange={async (c) => {
                                    updateState({
                                        ...state,
                                        workflowTask: {
                                            ...state.workflowTask,
                                            whenToSetInternalDueDate: c,
                                        },
                                    });
                                }}
                                placeholder="When to set internal due date"
                                value={
                                    state.workflowTask?.whenToSetInternalDueDate
                                        ? internalDateOptions?.find(
                                              (x) => x.value === state.workflowTask?.whenToSetInternalDueDate
                                          )
                                        : null
                                }
                                options={internalDateOptions}
                            />
                        </Flex>
                    )}

                    <Flex sx={{ mb: 20, flexDirection: 'column', mt: 20 }}>
                        <Label id="send-not-label" sx={{ width: 500, mb: 10, fontSize: 15, mr: 20 }}>
                            Send notification
                        </Label>
                        <Switch
                            aria-labelledby="send-not-label"
                            sx={{ cursor: 'pointer' }}
                            defaultChecked={state?.workflowTask?.sendNotification}
                            onClick={() => {
                                updateState({
                                    ...state,
                                    workflowTask: {
                                        ...state.workflowTask,
                                        sendNotification: state?.workflowTask?.sendNotification ? false : true,
                                    },
                                });
                            }}
                        />
                    </Flex>

                    {state.workflowTask?.sendNotification && (
                        <Flex sx={{ mt: 10, flexDirection: 'column', fontSize: 15, width: 600, mb: 0 }}>
                            <Label sx={{ fontWeight: 600, mb: 10, fontSize: 15 }}>Notification template</Label>
                            <ReactQuill
                                theme="snow"
                                value={state.workflowTask?.notificationTemplate}
                                style={{ minHeight: 200 }}
                                onChange={(val) =>
                                    updateState({
                                        ...state,
                                        workflowTask: {
                                            ...state.workflowTask,
                                            notificationTemplate: val,
                                        },
                                    })
                                }
                            />
                        </Flex>
                    )}

                    {state.editError && <Paragraph sx={{ mt: 20, color: 'red' }}>{state.editError}</Paragraph>}
                    {state.confirmMessage && (
                        <Paragraph sx={{ mt: 20, color: 'green' }}>{state.confirmMessage}</Paragraph>
                    )}
                </Flex>
                <Flex sx={{ ml: 50, flexDirection: 'column', mt: 20 }}>
                    <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <Button
                            onClick={() =>
                                updateState({
                                    ...state,
                                    workflowTask: {
                                        ...state.workflowTask,
                                        subtasks: [
                                            ...(state.workflowTask?.subtasks || []),
                                            {
                                                title: '',
                                                autoResolveUsingWorkflow: false,
                                                uiKey: uuid.v4(),
                                            },
                                        ],
                                    },
                                })
                            }
                        >
                            Add
                        </Button>
                    </Flex>
                    <Divider width="500px" />
                    <Flex sx={{ flexDirection: 'column' }} ref={drop}>
                        {state.workflowTask?.subtasks?.map((x, idx) => (
                            <Subtask
                                state={state}
                                updateState={updateState}
                                key={`subss_${idx}_${x.uiKey}}`}
                                idx={idx}
                                subtask={x}
                                organisation={organisation}
                            />
                        ))}
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default ManageWorkflow;
