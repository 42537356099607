/* eslint-disable no-dupe-keys */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-undef */
import axios from 'axios';
import ReactSelect from 'react-select';
import { Box, Button, Flex, Input, Paragraph, Select, Switch, Textarea } from 'theme-ui';
import Divider from '../divider';
import Tooltip from '../tooltip';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';

const saveAnswers = async (
    clientId,
    state,
    updateState,
    setShowValidationWarning,
    finish = false,
    fireAndForget = false
) => {
    if (state.view === 'viewAnswers') return;

    try {
        const errors = fireAndForget ? state.errors : {};

        if (finish) {
            const sections = state.questionnaire?.definition?.sections || [{}];

            for (let index = 0; index < sections.length; index++) {
                const section = sections[index];

                const questions =
                    state.questionnaire?.definition?.questions?.filter(
                        (question) => question.sectionIndex === undefined || question.sectionIndex === index
                    ) || [];

                let _continue = false;
                for (let idx = 0; idx < questions?.length; idx++) {
                    const question = questions[idx];

                    if (question.type === 'dropdown' && question.goToSection && question.answer) {
                        const optionSelected = question.options?.find((x) => x.value === question.answer);
                        if (optionSelected) {
                            const goToSectionIndex =
                                typeof optionSelected?.goToSectionIndex === 'number'
                                    ? optionSelected?.goToSectionIndex
                                    : index + 1;

                            index = goToSectionIndex - 1;
                            _continue = true;
                            break;
                        }
                    }

                    if (
                        ['file', 'multiple_files'].includes(question.type) &&
                        !question.answer?.length &&
                        !question.notApplicable &&
                        !question.comment
                    ) {
                        errors[`${index}_${idx}`] =
                            'Please mark as not applicable if you are unable to answer this question';
                    }
                    if (
                        !['file', 'multiple_files', 'dropdown'].includes(question.type) &&
                        !question.answer &&
                        !question.notApplicable &&
                        !question.comment
                    ) {
                        errors[`${index}_${idx}`] =
                            'Please mark as not applicable if you are unable to answer this question';
                    }

                    if (
                        ['dropdown'].includes(question.type) &&
                        !question.answer &&
                        !question.notApplicable &&
                        !question.comment
                    ) {
                        errors[`${index}_${idx}`] = 'Please select a value';
                    }
                }

                if (_continue) continue;

                if (section.endState) break;
            }
        }

        if (!fireAndForget && finish && Object.keys(errors).length) {
            setShowValidationWarning(true);
            setTimeout(() => {
                setShowValidationWarning(false);
            }, 7000);

            const targetDiv = document.getElementById(Object.keys(errors)[0]);
            if (targetDiv) {
                setTimeout(() => {
                    targetDiv?.scrollIntoView({ behavior: 'smooth' });
                }, 500);
            }

            return updateState({ ...state, errors });
        }

        if (!fireAndForget) {
            updateState({ ...state, loading: true, errors });
        }

        const last_saved = moment.utc().format('YYYY-MM-DDTHH:mm:ssZ');

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${clientId}/users/foo/qs/${state.questionnaire.id}`,
            method: 'PUT',
            data: {
                ...(finish ? { status: 'completed' } : {}),
                definition: {
                    ...state.questionnaire.definition,
                    ...(finish ? { status: 'completed' } : {}),
                    last_saved,
                },
            },
        });

        if (finish) {
            return window.location.assign('/home');
        }

        updateState({
            ...state,
            loading: false,
            errors,
            ...{
                questionnaire: {
                    ...state.questionnaire,
                    definition: {
                        ...state.questionnaire.definition,
                        last_saved,
                    },
                },
            },
        });
    } catch (e) {
        console.log(e);
        updateState({ ...state, loading: false, error: 'We are currently unable to save your data' });
    }
};

const handleFileUpload = async (e, clientId, state, updateState, questionIndex) => {
    try {
        updateState({ ...state, loading: true });

        const filesToUpload = [...e.target.files];

        const document_uuids = state.questionnaire?.definition?.questions?.[questionIndex]?.answer || [];
        const document_names = state.questionnaire?.definition?.questions?.[questionIndex]?.document_names || [];

        for (let index = 0; index < filesToUpload.length; index++) {
            const newFile = filesToUpload[index];

            const {
                data: { document_uuid, presigned_url },
            } = await axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/documents/portal`,
                method: 'POST',
                data: {
                    content_type: newFile.name?.includes('.msg') ? 'application/vnd.ms-outlook' : newFile.type,
                    file_name: newFile.name,
                    definition: state.questionnaire.definition,
                },
            });

            await axios.put(presigned_url, newFile, {
                headers: { 'Content-Type': newFile.type },
            });

            document_uuids.push(document_uuid);
            document_names.push(newFile.name);
        }

        const newQuestions = [...state.questionnaire?.definition?.questions];

        newQuestions[questionIndex].answer = document_uuids;
        newQuestions[questionIndex].document_names = document_names;

        const newDefinition = {
            ...state.questionnaire.definition,
            questions: newQuestions,
            status: 'todo',
        };

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${clientId}/users/foo/qs/${state.questionnaire.id}`,
            method: 'PUT',
            data: {
                definition: newDefinition,
            },
        });

        updateState({
            ...state,
            loading: false,
            questionnaire: {
                ...state.questionnaire,
                definition: newDefinition,
            },
        });
    } catch (e) {
        console.log(e);
        updateState({ ...state, loading: false, error: 'We are currently unable to upload your file' });
    }
};

const back = async (clientId, state, updateState) => {
    try {
        updateState({ ...state, loading: true });

        const clients = [];
        let res = await Promise.all([
            ...(state.organisation?.clients?.map((client) => {
                if (clients?.find((x) => x.raw_id === client?.raw_id)) {
                    return Promise.resolve({ data: [] });
                }
                clients.push(client);
                return axios({
                    url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${client?.raw_id}/users/foo/qs`,
                });
            }) || []),
        ]);

        let questionnaires = [];
        for (let index = 0; index < res.length; index++) {
            const element = res[index];
            questionnaires = [
                ...questionnaires,
                ...(element.data.questionnaires || [])?.map((x) => ({
                    ...x,
                    client_name: clients[index - 1]?.name,
                })),
            ];
        }

        updateState({
            ...state,
            loading: false,
            questionnaire: null,
            view: 'list',
            questionnaires: questionnaires.sort((x, y) => new Date(y.created_at) - new Date(x.created_at)),
        });
    } catch (e) {
        console.log(e);
        updateState({ ...state, loading: false, error: 'We are currently unable to save your data' });
    }
};

const ActionButtons = ({ state, updateState, organisation, setShowValidationWarning }) => (
    <Flex sx={{ alignItems: 'center' }}>
        <Button
            onClick={async () => {
                await back(organisation.client?.raw_id, state, updateState);
            }}
            variant="light"
            sx={{ height: 40, fontSize: 14 }}
        >
            Back
        </Button>
        {state.view === 'editQuestionnaire' && (
            <>
                <Button
                    onClick={async () => {
                        await saveAnswers(organisation.client?.raw_id, state, updateState, setShowValidationWarning);
                    }}
                    sx={{ ml: 10, height: 40, fontSize: 14 }}
                    variant="light"
                >
                    Save
                </Button>
                <Button
                    onClick={async () => {
                        await saveAnswers(
                            organisation.client?.raw_id,
                            state,
                            updateState,
                            setShowValidationWarning,
                            true
                        );
                    }}
                    sx={{ ml: 10, height: 40, fontSize: 14 }}
                >
                    I'm finished
                </Button>
                {state.questionnaire?.definition?.last_saved && (
                    <Paragraph sx={{ ml: 20 }}>
                        Last Saved: {moment(state.questionnaire?.definition?.last_saved).format('HH:mm DD-MM-YYYY')}
                    </Paragraph>
                )}
            </>
        )}
    </Flex>
);

const PortalQuestions = ({ state, updateState, organisation }) => {
    const sectionsToRender = [];

    const sections = state.questionnaire?.definition?.sections || [{}];

    for (let index = 0; index < sections.length; index++) {
        const section = sections[index];

        sectionsToRender.push(index);

        const questions =
            state.questionnaire?.definition?.questions?.filter(
                (question) => question.sectionIndex === undefined || question.sectionIndex === index
            ) || [];

        let exit = false;
        let _continue = false;
        for (let questionsIndex = 0; questionsIndex < questions.length; questionsIndex++) {
            const question = questions[questionsIndex];
            if (question.type === 'dropdown' && question.goToSection && question.answer) {
                const optionSelected = question.options?.find((x) => x.value === question.answer);
                if (optionSelected) {
                    const goToSectionIndex =
                        typeof optionSelected?.goToSectionIndex === 'number'
                            ? optionSelected?.goToSectionIndex
                            : index + 1;

                    index = goToSectionIndex - 1;
                    _continue = true;
                    break;
                }
            }
            if (question.type === 'dropdown' && question.goToSection && !question.answer) {
                exit = true;
            }
        }

        if (_continue) {
            continue;
        }
        if (exit) {
            break;
        }
        if (section.endState) {
            break;
        }
    }

    const stateRef = useRef();
    stateRef.current = state;

    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    const [showValidationWarning, setShowValidationWarning] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(async () => {
            await saveAnswers(
                organisation.client?.raw_id,
                stateRef.current,
                updateState,
                setShowValidationWarning,
                false,
                true
            );
        }, 25000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <Flex
            sx={{
                flexDirection: 'column',
                mb: 120,
                backgroundColor: 'white',
                padding: 30,
                borderRadius: 10,
                maxWidth: 1400,
                width: '90%',
                minHeight: '700px',
            }}
        >
            {showValidationWarning && (
                <Flex
                    sx={{
                        backgroundColor: 'red',
                        minHeight: 50,
                        position: 'fixed',
                        left: 0,
                        right: 0,
                        top: 0,
                        width: '100%',
                        zIndex: 100000,
                        padding: 20,
                    }}
                >
                    <Paragraph sx={{ fontSize: 16, textAlign: 'center', color: 'white' }}>
                        There are incomplete questions. Please check all questions have either been answered or marked
                        as n/a before finishing
                    </Paragraph>
                </Flex>
            )}

            <Paragraph sx={{ fontSize: 22, mb: 30, fontWeight: 600 }}>{state.questionnaire?.name}</Paragraph>

            {!state.questionnaire?.definition.description && (
                <Paragraph sx={{ mb: 20, width: 700, fontWeight: 400, mt: -10, fontSize: 18 }}>
                    {state.questionnaire?.definition?.description}
                </Paragraph>
            )}
            <Paragraph sx={{ mb: 10 }}>Please complete the questions below</Paragraph>
            <Paragraph sx={{ mb: 20, fontSize: 13 }}>The questions marked with an * are mandatory</Paragraph>

            {state.questionnaire?.definition?.questions?.length > 2 && (
                <Flex sx={{ flexDirection: 'column' }}>
                    <ActionButtons
                        setShowValidationWarning={setShowValidationWarning}
                        state={state}
                        updateState={updateState}
                        organisation={organisation}
                    />
                    <Flex sx={{ height: 40 }} />
                </Flex>
            )}

            {sections?.map((section, sectionIndex) => {
                if (!sectionsToRender.includes(sectionIndex)) return null;

                const questionsToRender = true // state.sectionsOpen?.includes(sectionIndex)
                    ? state.questionnaire?.definition?.questions?.filter(
                          (question) => question.sectionIndex === undefined || question.sectionIndex === sectionIndex
                      )
                    : [];

                return (
                    <Flex
                        sx={{
                            flexDirection: 'column',
                            padding: 20,
                            mb: 20,
                            border: '1px solid #CCC',
                            borderRadius: 15,
                        }}
                        key={`section_${sectionIndex}`}
                    >
                        <Flex
                            sx={{
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                cursor: 'pointer',
                                mb: 30,
                                // mb: state.sectionsOpen?.includes(sectionIndex) ? 30 : 0,
                            }}
                            // onClick={() => {
                            //     let sectionsOpen = state.sectionsOpen || [];
                            //     if (sectionsOpen?.includes(sectionIndex)) {
                            //         sectionsOpen = sectionsOpen.filter((x) => x !== sectionIndex);
                            //     } else {
                            //         sectionsOpen.push(sectionIndex);
                            //     }
                            //     updateState({ ...state, sectionsOpen });
                            // }}
                        >
                            <Paragraph sx={{ fontSize: 20, fontWeight: 600 }}>
                                {section.title || `Section ${sectionIndex + 1}`}
                            </Paragraph>
                            {/* <i
                                style={{ marginRight: '10px' }}
                                className={`fas fa-chevron-${
                                    state.sectionsOpen?.includes(sectionIndex) ? 'down' : 'right'
                                }`}
                            /> */}
                        </Flex>

                        {questionsToRender?.map((question, idx) => (
                            <Flex key={`q_${idx}`} sx={{ flexDirection: 'column', mb: 40 }}>
                                <Box sx={{ alignItems: 'center', position: 'relative' }}>
                                    <Flex>
                                        <Paragraph id={`question_${idx}`}>
                                            {idx + 1}. {question.text} {question.mandatory ? ' *' : ''}{' '}
                                        </Paragraph>
                                        {question.helpText && (
                                            <Tooltip
                                                positionSx={{
                                                    marginTop: '-5px',
                                                }}
                                                text={question.helpText}
                                            />
                                        )}
                                    </Flex>
                                </Box>

                                {question.client_attribute_key === 'contact.title' && (
                                    <Select
                                        sx={{
                                            mt: 10,
                                            width: [235, 500],
                                            height: 40,
                                            backgroundColor: 'white',
                                            borderRadius: 10,
                                            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.3)',
                                        }}
                                        arrow={
                                            <Box
                                                as="svg" // @ts-ignore
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="currentcolor"
                                                sx={{
                                                    ml: -28,
                                                    mt: 15,
                                                    alignSelf: 'center',
                                                    pointerEvents: 'none',
                                                }}
                                            >
                                                <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
                                            </Box>
                                        }
                                        value={question.answer || ''}
                                        disabled={state.view === 'viewAnswers'}
                                        onChange={(e) => {
                                            updateState({
                                                ...state,
                                                questionnaire: {
                                                    ...state.questionnaire,
                                                    definition: {
                                                        ...state.questionnaire.definition,
                                                        questions: state.questionnaire?.definition?.questions?.map(
                                                            (x) => {
                                                                if (x.uuid === question.uuid) {
                                                                    x.answer = e.target.value;
                                                                }
                                                                return x;
                                                            }
                                                        ),
                                                    },
                                                },
                                            });
                                        }}
                                    >
                                        <option value="">Select a title</option>
                                        <option value="Mr">Mr</option>
                                        <option value="Miss">Miss</option>
                                        <option value="Mrs">Mrs</option>
                                        <option value="Ms">Ms</option>
                                        <option value="Dr">Dr</option>
                                        <option value="Mx">Mx</option>
                                    </Select>
                                )}

                                {['string', 'number', 'date'].includes(question.type) &&
                                    question.client_attribute_key !== 'contact.title' && (
                                        <Input
                                            disabled={state.view === 'viewAnswers'}
                                            value={question.answer || ''}
                                            onChange={(e) => {
                                                updateState({
                                                    ...state,
                                                    questionnaire: {
                                                        ...state.questionnaire,
                                                        definition: {
                                                            ...state.questionnaire.definition,
                                                            questions: state.questionnaire?.definition?.questions?.map(
                                                                (x) => {
                                                                    if (x.uuid === question.uuid) {
                                                                        x.answer = e.target.value;
                                                                    }
                                                                    return x;
                                                                }
                                                            ),
                                                        },
                                                    },
                                                });
                                            }}
                                            type={question.type === 'string' ? 'text' : question.type}
                                            sx={{
                                                mt: 10,
                                                borderRadius: 10,
                                                height: 40,
                                                width: [235, 500],
                                                backgroundColor: 'white',
                                                border: state.errors?.[`${sectionIndex}_${idx}`]
                                                    ? '1px solid red'
                                                    : 'auto',
                                            }}
                                        />
                                    )}

                                {['address'].includes(question.type) && (
                                    <Flex sx={{ flexDirection: 'column', mt: 10 }}>
                                        <Input
                                            disabled={state.view === 'viewAnswers'}
                                            sx={{ width: [235, 400] }}
                                            aria-labelledby="addr-label"
                                            placeholder="First line of the address"
                                            value={question.answer?.line1 || ''}
                                            onChange={(e) => {
                                                updateState({
                                                    ...state,
                                                    questionnaire: {
                                                        ...state.questionnaire,
                                                        definition: {
                                                            ...state.questionnaire.definition,
                                                            questions: state.questionnaire?.definition?.questions?.map(
                                                                (x) => {
                                                                    if (x.uuid === question.uuid) {
                                                                        if (!x.answer) {
                                                                            x.answer = {};
                                                                        }

                                                                        x.answer.line1 = e.target.value;
                                                                    }
                                                                    return x;
                                                                }
                                                            ),
                                                        },
                                                    },
                                                });
                                            }}
                                        />
                                        <Input
                                            sx={{ width: [235, 400], mt: 10 }}
                                            aria-labelledby="addr-label"
                                            placeholder="City"
                                            value={question.answer?.city || ''}
                                            disabled={state.view === 'viewAnswers'}
                                            onChange={(e) => {
                                                updateState({
                                                    ...state,
                                                    questionnaire: {
                                                        ...state.questionnaire,
                                                        definition: {
                                                            ...state.questionnaire.definition,
                                                            questions: state.questionnaire?.definition?.questions?.map(
                                                                (x) => {
                                                                    if (x.uuid === question.uuid) {
                                                                        if (!x.answer) {
                                                                            x.answer = {};
                                                                        }

                                                                        x.answer.city = e.target.value;
                                                                    }
                                                                    return x;
                                                                }
                                                            ),
                                                        },
                                                    },
                                                });
                                            }}
                                        />
                                        <Input
                                            sx={{ width: [235, 400], mt: 10 }}
                                            aria-labelledby="addr-label"
                                            placeholder="Postal code"
                                            value={question.answer?.postcode || ''}
                                            disabled={state.view === 'viewAnswers'}
                                            onChange={(e) => {
                                                updateState({
                                                    ...state,
                                                    questionnaire: {
                                                        ...state.questionnaire,
                                                        definition: {
                                                            ...state.questionnaire.definition,
                                                            questions: state.questionnaire?.definition?.questions?.map(
                                                                (x) => {
                                                                    if (x.uuid === question.uuid) {
                                                                        if (!x.answer) {
                                                                            x.answer = {};
                                                                        }

                                                                        x.answer.postcode = e.target.value;
                                                                    }
                                                                    return x;
                                                                }
                                                            ),
                                                        },
                                                    },
                                                });
                                            }}
                                        />
                                    </Flex>
                                )}
                                {['dropdown'].includes(question.type) && (
                                    <ReactSelect
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                width: [235, '400px'],
                                                minHeight: '40px',
                                                marginTop: '10px',
                                                borderRadius: 10,
                                                marginBottom: '10px',
                                                border: state.errors?.[`${sectionIndex}_${idx}`]
                                                    ? '1px solid red'
                                                    : '1px solid #a3a69b',
                                                fontSize: '14px',
                                            }),
                                        }}
                                        isDisabled={state.view === 'viewAnswers'}
                                        onChange={(c) => {
                                            updateState({
                                                ...state,
                                                questionnaire: {
                                                    ...state.questionnaire,
                                                    definition: {
                                                        ...state.questionnaire.definition,
                                                        questions: state.questionnaire?.definition?.questions?.map(
                                                            (x) => {
                                                                if (x.uuid === question.uuid) {
                                                                    x.answer = c.value;
                                                                }
                                                                return x;
                                                            }
                                                        ),
                                                    },
                                                },
                                            });
                                        }}
                                        placeholder="Select"
                                        value={
                                            question.answer
                                                ? question.options?.find((x) => x.value === question.answer)
                                                : null
                                        }
                                        options={question.options}
                                    />
                                )}

                                {['file', 'multiple_files'].includes(question.type) && (
                                    <Flex sx={{ flexDirection: 'column' }}>
                                        <Input
                                            id="file_input"
                                            sx={{
                                                fontSize: '15px',
                                                width: [235, 500],
                                                mt: 10,
                                                borderRadius: 10,
                                                backgroundColor: 'white',
                                                border: state.errors?.[`${sectionIndex}_${idx}`]
                                                    ? '1px solid red'
                                                    : 'auto',
                                            }}
                                            disabled={state.view === 'viewAnswers'}
                                            type="file"
                                            multiple={question.type === 'multiple_files'}
                                            onChange={async (e) => {
                                                await handleFileUpload(
                                                    e,
                                                    organisation.client?.raw_id,
                                                    state,
                                                    updateState,
                                                    state.questionnaire?.definition?.questions?.findIndex(
                                                        (x) => x.uuid === question.uuid
                                                    )
                                                );
                                            }}
                                        />

                                        {question.answer?.length ? <Flex sx={{ height: 30 }} /> : null}

                                        {question.answer?.map((item, docIdx) => (
                                            <Flex
                                                key={`uploaded_doc_${docIdx}`}
                                                sx={{ ml: 10, mt: 10, flexDirection: 'column' }}
                                            >
                                                <Flex sx={{ alignItems: 'center' }}>
                                                    <Paragraph sx={{ width: 400 }}>
                                                        {question.document_names?.[docIdx]}
                                                    </Paragraph>
                                                    <Flex>
                                                        <Button
                                                            onClick={async () => {
                                                                updateState({ ...state, loading: true });

                                                                const {
                                                                    data: { url },
                                                                } = await axios.get(
                                                                    `${process.env.REACT_APP_AQRU_AI_API}/documents/${item}`,
                                                                    {}
                                                                );

                                                                updateState({ ...state, loading: false });

                                                                const a = window.document.createElement('a');

                                                                a.href = url;
                                                                a.target = '_blank';
                                                                a.download = 'Uploaded doc';

                                                                return a.click();
                                                            }}
                                                            variant="light"
                                                            sx={{ height: 40, ml: 10, mr: 0 }}
                                                        >
                                                            Download
                                                        </Button>
                                                        <Button
                                                            onClick={() => {
                                                                const newQuestions = [
                                                                    ...state.questionnaire?.definition?.questions,
                                                                ];

                                                                const qIdx =
                                                                    state.questionnaire?.definition?.questions?.findIndex(
                                                                        (x) => x.uuid === question.uuid
                                                                    );

                                                                newQuestions[qIdx].answer.splice(docIdx, 1);
                                                                newQuestions[qIdx].document_names.splice(docIdx, 1);

                                                                updateState({
                                                                    ...state,
                                                                    questionnaire: {
                                                                        ...state.questionnaire,
                                                                        definition: {
                                                                            ...state.questionnaire.definition,
                                                                            questions: newQuestions,
                                                                        },
                                                                    },
                                                                });
                                                            }}
                                                            sx={{ height: 40, ml: 10 }}
                                                            disabled={state.view === 'viewAnswers'}
                                                        >
                                                            Delete
                                                        </Button>
                                                    </Flex>
                                                </Flex>
                                                <Divider width={600} />
                                            </Flex>
                                        ))}
                                        {Array.isArray(question.answer)?.length && (
                                            <Paragraph sx={{ mt: 10, color: 'green' }}>Successfully uploaded</Paragraph>
                                        )}
                                    </Flex>
                                )}

                                {['textarea'].includes(question.type) && (
                                    <Textarea
                                        disabled={state.view === 'viewAnswers'}
                                        value={question.answer || ''}
                                        onChange={(e) => {
                                            updateState({
                                                ...state,
                                                questionnaire: {
                                                    ...state.questionnaire,
                                                    definition: {
                                                        ...state.questionnaire.definition,
                                                        questions: state.questionnaire?.definition?.questions?.map(
                                                            (x) => {
                                                                if (x.uuid === question.uuid) {
                                                                    x.answer = e.target.value;
                                                                }
                                                                return x;
                                                            }
                                                        ),
                                                    },
                                                },
                                            });
                                        }}
                                        rows={6}
                                        type={question.type === 'string' ? 'text' : question.type}
                                        sx={{
                                            mt: 10,
                                            borderRadius: 10,
                                            width: [235, 500],
                                            backgroundColor: 'white',
                                            border: state.errors?.[`${sectionIndex}_${idx}`] ? '1px solid red' : 'auto',
                                        }}
                                    />
                                )}

                                {!['string', 'textarea', 'dropdown'].includes(question.type) && (
                                    <Flex sx={{ flexDirection: 'column', mt: 10 }}>
                                        <Paragraph sx={{ mb: '5px' }}>Add a comment for this question</Paragraph>
                                        <Input
                                            disabled={state.view === 'viewAnswers'}
                                            value={question.comment || ''}
                                            onChange={(e) => {
                                                updateState({
                                                    ...state,
                                                    questionnaire: {
                                                        ...state.questionnaire,
                                                        definition: {
                                                            ...state.questionnaire.definition,
                                                            questions: state.questionnaire?.definition?.questions?.map(
                                                                (x) => {
                                                                    if (x.uuid === question.uuid) {
                                                                        x.comment = e.target.value;
                                                                    }
                                                                    return x;
                                                                }
                                                            ),
                                                        },
                                                    },
                                                });
                                            }}
                                            sx={{ width: [235, 600] }}
                                            type="text"
                                        />
                                    </Flex>
                                )}

                                {!['dropdown'].includes(question.type) && (
                                    <Flex sx={{ flexDirection: 'column', mt: 20 }}>
                                        <Paragraph sx={{ mb: '5px' }}>Not Applicable</Paragraph>
                                        <Switch
                                            checked={question?.notApplicable}
                                            disabled={state.view === 'viewAnswers'}
                                            onClick={() => {
                                                updateState({
                                                    ...state,
                                                    questionnaire: {
                                                        ...state.questionnaire,
                                                        definition: {
                                                            ...state.questionnaire.definition,
                                                            questions: state.questionnaire?.definition?.questions?.map(
                                                                (x) => {
                                                                    console.log('debug', x.uuid, question.uuid);
                                                                    if (x.uuid === question.uuid) {
                                                                        x.notApplicable = !x.notApplicable;
                                                                    }
                                                                    return x;
                                                                }
                                                            ),
                                                        },
                                                    },
                                                });
                                            }}
                                            sx={{ mb: 10 }}
                                        />
                                    </Flex>
                                )}

                                {state.errors?.[`${sectionIndex}_${idx}`] && (
                                    <Paragraph id={`${sectionIndex}_${idx}`} sx={{ mt: 10, color: 'red' }}>
                                        {state.errors?.[`${sectionIndex}_${idx}`]}
                                    </Paragraph>
                                )}
                            </Flex>
                        ))}
                    </Flex>
                );
            })}
            <Flex
                sx={{
                    background: 'white',
                    height: 100,
                    width: '100%',
                    position: 'fixed',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    borderTop: '1px solid #EFEFEF',
                }}
            >
                <Flex sx={{ ml: 20, alignItems: 'center' }}>
                    <ActionButtons
                        setShowValidationWarning={setShowValidationWarning}
                        state={state}
                        updateState={updateState}
                        organisation={organisation}
                    />
                </Flex>
            </Flex>
        </Flex>
    );
};

export default PortalQuestions;
