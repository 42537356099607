/* eslint-disable no-undef */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Button, Flex, Input, Paragraph } from 'theme-ui';
import Divider from '../divider';
import Spinner from '../spinner';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import theme from '../../theme';

const ucase = (text) => (text ? `${text.substring(0, 1).toUpperCase()}${text?.toLowerCase().substring(1)}` : '');

const downloadTemplate = (id, localState, updateLocalState) => async (e) => {
    try {
        updateLocalState({ ...localState, loading: true, error: null });

        const {
            data: { url },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations/templates/${id}`,
        });

        const a = document.createElement('a');

        a.href = url;

        a.download = `${ucase(id.replace(/_/g, ' '))}.docx`;

        a.click();

        updateLocalState({
            ...localState,
            loading: false,
            error: null,
        });
    } catch (e) {
        console.log(e);
        updateLocalState({
            ...localState,
            loading: false,
            error: 'Uh oh, there has been an error downloading this file',
        });
    }
};

const uploadTemplate = (id, localState, updateLocalState) => async (e) => {
    try {
        updateLocalState({ ...localState, loading: true, error: null });

        const file = event.target.files?.[0];

        const {
            data: { url },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations/templates/${id}`,
            method: 'put',
        });

        await axios({
            url,
            method: 'put',
            data: file,
        });

        updateLocalState({
            ...localState,
            loading: false,
            error: null,
        });
    } catch (e) {
        console.log(e);
        updateLocalState({
            ...localState,
            loading: false,
            error: 'Uh oh, there has been an error uploading this file',
        });
    }
};

const deleteTemplate = async (localState, updateLocalState, idx) => {
    try {
        updateLocalState({ ...localState, loading: true, error: null });

        await axios({
            method: 'DELETE',
            url: `${process.env.REACT_APP_AQRU_AI_API}/email-templates/${idx}`,
        });

        const {
            data: { templates, total },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/email-templates`,
            params: { limit: 500 },
        });

        updateLocalState({ ...localState, templates, total, loading: false, error: null });
    } catch (e) {
        console.log(e);
        updateLocalState({
            ...localState,
            loading: false,
            error: 'Uh oh, there has been an error deleting this ',
        });
    }
};

const loadTemplates = async (localState, updateLocalState) => {
    try {
        updateLocalState({ ...localState, loading: true, error: null });

        const {
            data: { templates, total },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/email-templates`,
            params: { limit: 500 },
        });

        updateLocalState({ ...localState, templates, total, loading: false, error: null });
    } catch (e) {
        console.log(e);
        updateLocalState({
            ...localState,
            loading: false,
            error: 'Uh oh, there has been an error fetching these ',
        });
    }
};

const saveTemplate = async (localState, updateLocalState) => {
    try {
        if (!localState.content || !localState.name) {
            return updateLocalState({ ...localState, error: 'Please provide a name and content for the template' });
        }

        updateLocalState({ ...localState, loading: true, error: null });

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/email-templates${localState.uuid ? `/${localState.uuid}` : ''}`,
            method: localState.uuid ? 'PUT' : 'POST',
            data: {
                name: localState.name,
                content: localState.content,
            },
        });

        const {
            data: { templates, total },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/email-templates`,
            params: { limit: 500 },
        });

        updateLocalState({ ...localState, templates, total, loading: false, error: null, view: 'list' });
    } catch (e) {
        console.log(e);
        updateLocalState({
            ...localState,
            loading: false,
            error: 'Uh oh, there has been an error saving this template, please ensure the name is unique',
        });
    }
};

const DocTemplates = ({ organisation }) => {
    const [localState, updateLocalState] = useState({ templates: [], view: 'list' });

    useEffect(() => {
        loadTemplates(localState, updateLocalState);
    }, []);

    return (
        <Flex sx={{ flexDirection: 'column', mt: 10 }}>
            {localState.loading && <Spinner />}
            <Flex sx={{ justifyContent: 'space-between', mb: -10, width: 600 }}>
                <Paragraph
                    sx={{
                        width: 200,
                        fontSize: 20,
                        fontWeight: '600',
                        mt: 10,
                        color: 'text',
                    }}
                >
                    {localState.view === 'list' ? 'Email Templates' : 'Manage template'}
                </Paragraph>

                {organisation?.user?.role === 'admin' && localState.view === 'list' && (
                    <Button
                        sx={{
                            border: '1px solid #ccc',
                            backgroundColor: 'white',
                            fontSize: 14,
                            height: 40,
                            color: 'text',
                        }}
                        onClick={() =>
                            updateLocalState({
                                ...localState,
                                view: 'manage',
                                content: '',
                                name: '',
                                error: null,
                                uuid: null,
                            })
                        }
                    >
                        Add new template
                    </Button>
                )}
            </Flex>

            <Divider />

            {localState.view === 'manage' && (
                <Flex>
                    <Flex sx={{ flexDirection: 'column' }}>
                        {localState.error && <Paragraph sx={{ mb: 20, color: 'red' }}>{localState.error}</Paragraph>}

                        <Paragraph>Name</Paragraph>
                        <Input
                            sx={{ mt: 10 }}
                            value={localState.name}
                            onChange={(e) =>
                                updateLocalState({
                                    ...localState,
                                    name: e.target.value,
                                })
                            }
                        />

                        <Flex sx={{ flexDirection: 'column', mb: 50 }}>
                            <Paragraph sx={{ mb: 10, mt: 20 }}>Template</Paragraph>

                            <ReactQuill
                                style={{ minHeight: 400 }}
                                theme="snow"
                                value={localState.content}
                                onChange={(val) =>
                                    updateLocalState({
                                        ...localState,
                                        content: val,
                                    })
                                }
                            />
                        </Flex>

                        <Flex>
                            <Button
                                onClick={() =>
                                    updateLocalState({
                                        ...localState,
                                        view: 'list',
                                        content: '',
                                        name: '',
                                        error: null,
                                        uuid: null,
                                    })
                                }
                                sx={{ width: 100, mt: 20, height: 40, color: '#000', backgroundColor: '#fff' }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={async () => {
                                    await saveTemplate(localState, updateLocalState);
                                }}
                                sx={{ width: 100, mt: 20, height: 40, ml: 10 }}
                            >
                                Save
                            </Button>
                        </Flex>
                    </Flex>
                    <Flex sx={{ flexDirection: 'column', ml: 50 }}>
                        <Paragraph>Available tags for templates (copy them in to reference dynamic data)</Paragraph>
                        <ul style={{ lineHeight: '25px' }}>
                            <li>{'{todaysDate}'}</li>
                            <li>{'{client.name}'}</li>
                            <li>{'{client.company_number}'}</li>
                            <li>{'{client.primary_contact.first_name}'}</li>
                            <li>{'{client.primary_contact.last_name}'}</li>
                            <li>{'{task.relevant_period_end}'}</li>
                        </ul>
                    </Flex>
                </Flex>
            )}

            {localState.view === 'list' && (
                <>
                    <table
                        style={{
                            marginTop: 10,
                            width: 600,
                            border: '1px solid lightGrey',
                            borderCollapse: 'collapse',
                        }}
                    >
                        <thead
                            style={{
                                backgroundColor: theme.colors.primary,
                                color: 'white',
                                height: 50,
                            }}
                        >
                            <th style={{ width: 400, textAlign: 'left', paddingLeft: 20 }}>Name</th>
                            <th
                                style={{
                                    width: 120,
                                    textAlign: 'left',
                                    paddingLeft: 20,
                                    borderLeft: '1px solid lightGrey',
                                }}
                            ></th>
                        </thead>

                        {localState.templates.map((x) => (
                            <tr
                                style={{ boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.2)', height: 50 }}
                                key={`email_templates_${x.uuid}`}
                            >
                                <td style={{ paddingLeft: '20px' }}>{x.name}</td>
                                <td>
                                    <i
                                        style={{
                                            marginTop: '0px',
                                            fontSize: '18px',
                                            marginLeft: '35px',
                                            cursor: 'pointer',
                                            marginRight: '6px',
                                        }}
                                        className="fas fa-pencil"
                                        onClick={() => {
                                            updateLocalState({
                                                ...localState,
                                                name: x.name,
                                                content: x.content,
                                                uuid: x.uuid,
                                                view: 'manage',
                                            });
                                        }}
                                    />
                                    <i
                                        style={{
                                            marginTop: '0px',
                                            fontSize: '18px',
                                            marginLeft: '16px',
                                            marginRight: '6px',
                                            cursor: 'pointer',
                                            color: 'red',
                                        }}
                                        className="fas fa-trash"
                                        onClick={async () => {
                                            await deleteTemplate(localState, updateLocalState, x.uuid);
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                    </table>

                    <Paragraph sx={{ mb: 0, mt: 40, fontWeight: 600, fontSize: 20 }}>Onboarding templates</Paragraph>
                    <Divider />
                    <Flex sx={{ alignItems: 'center' }}>
                        <Paragraph sx={{ width: 200 }}>Companies</Paragraph>
                        <input
                            onChange={uploadTemplate('company_onboarding', localState, updateLocalState)}
                            type="file"
                        />
                        <Button
                            onClick={downloadTemplate('company_onboarding', localState, updateLocalState)}
                            sx={{ height: 40 }}
                        >
                            Download
                        </Button>
                    </Flex>
                    <Flex sx={{ mt: 30, alignItems: 'center' }}>
                        <Paragraph sx={{ width: 200 }}>Individuals</Paragraph>
                        <input
                            onChange={uploadTemplate('individual_onboarding', localState, updateLocalState)}
                            type="file"
                        />
                        <Button
                            onClick={downloadTemplate('individual_onboarding', localState, updateLocalState)}
                            sx={{ height: 40 }}
                        >
                            Download
                        </Button>
                    </Flex>

                    <a
                        href="https://cdn.accru.finance/af/doc-template.docx"
                        target="_blank"
                        rel="noreferrer"
                        style={{ marginTop: 40 }}
                    >
                        Example report template
                    </a>
                    <Paragraph sx={{ fontSize: 14, mt: 20 }}>
                        This illustrates how to pull in dynamic data using tags
                    </Paragraph>
                </>
            )}
        </Flex>
    );
};

export default DocTemplates;
