import { useState } from 'react';
import { Button, Flex, Input, Label, Paragraph } from 'theme-ui';
import { updateClientData } from './api';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import Divider from '../divider';

const EditClientData = ({ state, updateState }) => {
    const [localState, updateLocalState] = useState({ ...state.clientInView });

    let customRows = Object.entries(state.clientInView.organisation?.schema || {})?.filter(
        (entry) =>
            !entry[1]?.client_types ||
            entry[1]?.client_types?.includes('all') ||
            entry[1]?.client_types?.includes(localState.type)
    );

    const sortingOrder = state.clientInView.organisation?.default_visualisation?.attribute_sorting_order || [];

    customRows.forEach((x) => {
        if (!sortingOrder.includes(x[0])) {
            sortingOrder.push(x[0]);
        }
    });

    customRows = customRows.sort((a, b) => {
        const indexA = sortingOrder.indexOf(a[0]);
        const indexB = sortingOrder.indexOf(b[0]);

        return indexA - indexB;
    });

    return (
        <Flex sx={{ flexDirection: 'column', mt: 25, justifyContent: 'flex-start' }}>
            <Paragraph sx={{ ml: 20, fontWeight: 600, fontSize: 20 }}>Client Data</Paragraph>

            <Divider mt="20px" mb="40px" width="900px" />

            <Flex sx={{ ml: 20, alignItems: 'center', justifyContent: 'flex-start' }}>
                <Label
                    sx={{
                        fontWeight: 400,
                        fontSize: 14,
                        width: 280,
                        mr: 20,
                    }}
                >
                    Name
                </Label>
                <Input
                    value={localState.name}
                    onChange={(e) => updateLocalState({ ...localState, name: e.target.value })}
                    sx={{ width: 500 }}
                />
            </Flex>

            <Divider mt="50px" width="900px" />

            {localState.type === 'company' && (
                <Flex sx={{ ml: 20, alignItems: 'center', mt: 20, justifyContent: 'flex-start' }}>
                    <Label
                        sx={{
                            fontWeight: 400,
                            fontSize: 14,
                            width: 280,
                            mr: 20,
                        }}
                    >
                        Company Number
                    </Label>
                    <Input
                        value={localState.company_number}
                        onChange={(e) => updateLocalState({ ...localState, company_number: e.target.value })}
                        sx={{ width: 500 }}
                    />
                </Flex>
            )}

            {localState.type === 'company' && <Divider mt="40px" width="900px" />}

            <Flex sx={{ ml: 20, alignItems: 'center', mt: 20, justifyContent: 'flex-start' }}>
                <Label
                    sx={{
                        fontWeight: 400,
                        fontSize: 14,
                        width: 280,
                        mr: 20,
                    }}
                >
                    Client Reference Number
                </Label>
                <Input
                    value={localState.internal_reference_code}
                    onChange={(e) => updateLocalState({ ...localState, internal_reference_code: e.target.value })}
                    sx={{ width: 500 }}
                />
            </Flex>

            <Divider mt="40px" width="900px" />

            <Flex sx={{ ml: 20, alignItems: 'center' }}>
                <Label
                    sx={{
                        fontWeight: 400,
                        fontSize: 14,
                        width: 280,
                        mr: 20,
                    }}
                    id="label-services"
                >
                    Services
                </Label>
                <Flex sx={{ mr: 20 }}>
                    <ReactSelect
                        aria-labelledby="label-services"
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                width: '500px',
                                minHeight: '40px',
                                marginTop: '30px',
                                borderRadius: 10,
                                border: '1px solid #a3a69b',
                                fontSize: '14px',
                            }),
                        }}
                        onChange={async (value) => {
                            updateState({
                                ...state,
                                servicesOffered: value,
                            });
                        }}
                        isMulti
                        placeholder={'Select the services offered'}
                        value={state.servicesOffered}
                        options={Object.entries(
                            state.clientInView?.organisation?.workflow_definition?.services || {}
                        ).map(([key, value]) => ({
                            value: key,
                            label: value.label,
                        }))}
                    />
                </Flex>
            </Flex>

            <Divider mt="40px" width="900px" />

            {customRows?.map((entry) => (
                <Flex key={`custom_row_${entry[0]}`} sx={{ flexDirection: 'column' }}>
                    <Flex sx={{ ml: 20, alignItems: 'center', mt: 40, justifyContent: 'flex-start' }}>
                        <Label
                            sx={{
                                fontWeight: 400,
                                fontSize: 14,
                                width: 280,
                                mr: 20,
                            }}
                        >
                            {entry[1].label}
                        </Label>
                        {!['dropdown', 'address'].includes(entry[1].type) && (
                            <Input
                                type={entry[1].type}
                                value={localState.client_data?.[entry[0]]}
                                onChange={(e) =>
                                    updateLocalState({
                                        ...localState,
                                        client_data: {
                                            ...(localState.client_data || {}),
                                            [entry[0]]: e.target.value,
                                        },
                                    })
                                }
                                sx={{ width: 500 }}
                            />
                        )}
                        {['dropdown'].includes(entry[1].type) && (
                            <ReactSelect
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                        width: '300px',
                                        minHeight: '40px',
                                        borderRadius: 10,
                                        border: '1px solid #a3a69b',
                                        fontSize: '14px',
                                    }),
                                }}
                                onChange={async (value) => {
                                    updateLocalState({
                                        ...localState,
                                        client_data: {
                                            ...(localState.client_data || {}),
                                            [entry[0]]: value.value,
                                        },
                                    });
                                }}
                                placeholder={'Select the services offered'}
                                value={
                                    localState.client_data?.[entry[0]]
                                        ? {
                                              value: localState.client_data?.[entry[0]],
                                              label: entry[1].dropdownOptions?.find(
                                                  (option) => option.id === localState.client_data?.[entry[0]]
                                              )?.label,
                                          }
                                        : null
                                }
                                options={entry[1].dropdownOptions?.map((option) => ({
                                    value: option.id,
                                    label: option.label,
                                }))}
                            />
                        )}
                    </Flex>
                    <Divider mt="40px" width="900px" />
                </Flex>
            ))}

            <Flex
                sx={{
                    position: 'fixed',
                    height: 80,
                    background: 'white',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    alignItems: 'center',
                    borderTop: '2px solid #EFEFEF',
                    width: '100%',
                    zIndex: 998,
                }}
            >
                <Flex sx={{ ml: 150, alignItems: 'center' }}>
                    <Button
                        sx={{ ml: 10, width: 100, height: 40 }}
                        onClick={async () => {
                            try {
                                updateState({
                                    ...state,
                                    loading: true,
                                });

                                await updateClientData(state.clientInView?.id, {
                                    ...localState,
                                    client_data: {
                                        ...(localState.client_data || {}),
                                        new_client_services_offered: state.servicesOffered?.map((x) => x.value),
                                    },
                                });

                                updateState({
                                    ...state,
                                    loading: false,
                                    clientInView: {
                                        ...state.clientInView,
                                        ...localState,
                                    },
                                });

                                toast('Client data saved', {
                                    type: 'success',
                                    autoClose: 5000,
                                    position: 'top-right',
                                });
                            } catch (e) {
                                updateState({
                                    ...state,
                                    loading: false,
                                });
                                toast(
                                    e?.response?.data?.message || 'Something has gone wrong, please contact support',
                                    {
                                        type: 'error',
                                        autoClose: 5000,
                                        position: 'top-right',
                                    }
                                );
                            }
                        }}
                    >
                        <i
                            style={{
                                marginRight: '7px',
                            }}
                            className={`fas fa-save`}
                        />
                        Save
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default EditClientData;
