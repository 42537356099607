/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Flex, Heading, Paragraph } from 'theme-ui';
import Divider from '../components/divider';
import MainLogo from '../components/main-logo';
import Sidebar from '../components/sidebar';
import Spinner from '../components/spinner';
import { loadTaskData } from '../components/tasks/api';
import Boards from '../components/tasks/boards';
import ButtonToolbar from '../components/tasks/button-toolbar';
import ConfirmDialog from '../components/tasks/confirm-dialog';
import Filters, { defaultColumnOptions, sortOptions, sortTasks } from '../components/tasks/filters';
import Swimlane from '../components/tasks/swimlane';
import Task from '../components/tasks/task';
import TaskInListView from '../components/tasks/task-in-list-view';
import { actions as menuActions } from '../store/reducers/menu';
import { actions as userActions } from '../store/reducers/users';
import theme from '../theme';

export const defaultState = {
    loading: false,
    error: null,
    organisation: null,
    mode: 'list',
    boardMode: 'list',
    statutory_due_date_comparator: { value: '>=', label: '>=' },
    internal_due_date_comparator: { value: '>=', label: '>=' },
    taskTabInFocus: 'documents',
    swimlanes: [],
    tasks: [],
    users: [],
    team: '',
    commentsLimit: 5,
    taskCommentsOffset: 0,
    totalComments: 0,
    documentsLimit: 5,
    taskDocumentsOffset: 0,
    totalDocuments: 0,
    view: window.localStorage.getItem('task_view') ? window.localStorage.getItem('task_view') : 'kanban',
    maxTasks: window.localStorage.getItem('maxTasks')
        ? JSON.parse(window.localStorage.getItem('maxTasks'))
        : {
              value: 100,
              label: '100 tasks max',
          },
    filters: window.localStorage.getItem('sorting_filters_v2')
        ? JSON.parse(window.localStorage.getItem('sorting_filters_v2'))
        : null,
    columns: window.localStorage.getItem('columns')
        ? JSON.parse(window.localStorage.getItem('columns'))
        : defaultColumnOptions,
    clientSelected: window.localStorage.getItem('clientSelected')
        ? JSON.parse(window.localStorage.getItem('clientSelected'))
        : '',
    partners: window.localStorage.getItem('partners') ? JSON.parse(window.localStorage.getItem('partners')) : '',
    reviewers: window.localStorage.getItem('reviewers') ? JSON.parse(window.localStorage.getItem('reviewers')) : '',
    assignedTo: window.localStorage.getItem('assignedTo') ? JSON.parse(window.localStorage.getItem('assignedTo')) : '',
    priority: window.localStorage.getItem('priority') ? JSON.parse(window.localStorage.getItem('priority')) : '',
    serviceFilter: window.localStorage.getItem('serviceFilter')
        ? JSON.parse(window.localStorage.getItem('serviceFilter'))
        : '',
    statuses: window.localStorage.getItem('statuses') ? JSON.parse(window.localStorage.getItem('statuses')) : '',
    searchByTitle: window.localStorage.getItem('searchByTitle') || '',
    filter: window.localStorage.getItem('myTasksFilter'),
    show_my_tasks: window.localStorage.getItem('myTasksFilter') === 'my_tasks' ? '1' : '0',
};

function saveScrollPosition() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    localStorage.setItem('tasks_savedScrollPosition', scrollPosition);
}

function restoreScrollPosition() {
    if (document.referrer && document.referrer?.includes('tasks')) {
        const savedPosition = localStorage.getItem('tasks_savedScrollPosition');
        if (savedPosition !== null) {
            setTimeout(() => {
                window.scrollTo(0, parseInt(savedPosition, 10));
                localStorage.removeItem('tasks_savedScrollPosition');
            }, 400);
        }
    } else {
        localStorage.removeItem('tasks_savedScrollPosition');
    }
}

const handleClickOnColumnHeader = (state, updateState, option, inverse) => {
    const component = sortOptions.find((x) => x.value === (state.filters?.[0]?.value === option ? inverse : option));

    updateState({
        ...state,
        filters: [component],
        tasks_for_list: sortTasks(state.tasks_for_list, [component], state.organisation),
    });

    window.localStorage.setItem('sorting_filters_v2', JSON.stringify([component]));
};

const handleClickOnClientDataColumnHeader = (state, updateState, option, inverse, clientDataKey, baseLabel) => {
    const { value, label } =
        state.filters?.[0]?.value === option
            ? { value: inverse, label: `${baseLabel} ↓` }
            : { value: option, label: `${baseLabel} ↑` };

    const component = { value, label, isClientKey: true, clientDataKey };

    updateState({
        ...state,
        filters: [component],
        tasks_for_list: sortTasks(state.tasks_for_list, [component], state.organisation),
    });

    window.localStorage.setItem('sorting_filters_v2', JSON.stringify([component]));
};

const headerConfig = {
    title: {
        label: 'Title',
    },
    client_name: {
        label: 'Client name',
    },
    internal_reference_code: {
        label: 'Client identifier',
    },
    service: {
        label: 'Service',
    },
    status: {
        label: 'Status',
    },
    priority: {
        label: 'Priority',
    },
    relevant_period_end: {
        label: 'Relevant period end',
    },
    due_date: {
        label: 'Internal due date',
    },
    statutory_due_date: {
        label: 'Statutory due date',
    },
    expected_fee: {
        label: 'Expected Fee (£)',
    },
    expected_date_billed: {
        label: 'Expected Date Billed',
    },
    preparers: {
        label: 'Preparers',
        sortDisabled: true,
    },
    reviewers: {
        label: 'Reviewers',
        sortDisabled: true,
    },
    partners: {
        label: 'Partners',
        sortDisabled: true,
    },
};

const buildHeaders = (state, updateState, columns) => {
    const headers = [];

    for (const column of columns) {
        const config = headerConfig[column.value];

        if (!config && column.value?.startsWith('client__')) {
            const clientDataKey = column.value.split('client__')[1];

            const label = ((state?.organisation || {})?.schema || {})[clientDataKey]?.label;
            headers.push(
                <th
                    onClick={() => {
                        handleClickOnClientDataColumnHeader(
                            state,
                            updateState,
                            `${clientDataKey}_asc`,
                            `${clientDataKey}_desc`,
                            clientDataKey,
                            label
                        );
                    }}
                    style={{ width: 250, textAlign: 'left', padding: 20 }}
                >
                    {label}
                </th>
            );
        }

        if (config) {
            headers.push(
                <th
                    onClick={() => {
                        !config.sortDisabled &&
                            handleClickOnColumnHeader(
                                state,
                                updateState,
                                `${column.value}_asc`,
                                `${column.value}_desc`
                            );
                    }}
                    style={{ width: 250, textAlign: 'left', padding: 20 }}
                >
                    {config.label}
                </th>
            );
        }
    }

    return headers;
};

const Tasks = ({ accountSummary, userData, refreshCoreData, organisation, logout }) => {
    const navigate = useNavigate();

    // client_id
    const queryParams = new URLSearchParams(window.location.search);
    const clientId = queryParams.get('client_id');
    const taskId = queryParams.get('id');
    const userId = queryParams.get('user_id');
    const status = queryParams.get('status');
    const status_label = queryParams.get('status_label');

    const [state, updateState] = useState({
        ...defaultState,
        organisation,
        showFrequencySetupModal: queryParams.get('show_schedule') === '1',
        statuses: status && status_label ? [{ value: status, label: status_label }] : defaultState.statuses,
    });

    const stateRef = useRef();
    stateRef.current = state;

    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    useEffect(() => {
        (async () => {
            try {
                refreshCoreData();

                await loadTaskData(state, updateState, false, clientId, taskId, navigate, userId);

                if (!taskId) {
                    restoreScrollPosition();
                }
            } catch (e) {
                logout();
                navigate('/');
            }
        })();
    }, []);

    useEffect(() => {
        if (!taskId) {
            window.addEventListener('beforeunload', saveScrollPosition);
            return () => window.removeEventListener('beforeunload', saveScrollPosition);
        }
    }, []);

    let theTitle = 'Tasks';

    if (state.mode === 'new') {
        theTitle = 'Create task';
    } else if (state.mode === 'update') {
        theTitle = 'Update task';
    } else if (state.mode === 'boards') {
        theTitle = 'Boards';
    }

    return (
        <>
            <Flex
                sx={{
                    minHeight: '100vh',
                    paddingBottom: '100px',
                    width: 'auto',
                    flexDirection: 'column',
                    position: 'relative',
                    backgroundSize: 'cover',
                    backgroundColor: '#fff',
                }}
                data-testid="home-screen"
            >
                <Flex
                    sx={{
                        minHeight: '100vh',
                        width: 'auto',
                        flexDirection: 'column',
                    }}
                >
                    <Sidebar currentPage="Tasks" accountSummary={accountSummary} user={userData} copy={{}} />

                    {state.loading && <Spinner />}

                    {state.subtasksIncompleteAlert && (
                        <ConfirmDialog
                            updateState={updateState}
                            state={state}
                            showOkOnly
                            stateKey="subtasksIncompleteAlert"
                            text="This task cannot be moved to Done until all subtasks are complete"
                        />
                    )}

                    <Flex
                        sx={{
                            minWidth: '100vw',
                            width: 'fit-content',
                            border: '0px solid lightgrey',
                            minHeight: 'calc(100vh)',
                            backgroundColor: '#ffffff',
                            borderTopLeftRadius: '25px',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            ml: '130px',
                        }}
                    >
                        <Flex sx={{ flexDirection: 'column' }}>
                            <MainLogo sxContainer={{ ml: 30 }} organisation={organisation} />

                            <Flex>
                                <Heading
                                    sx={{
                                        textAlign: 'left',
                                        fontSize: '25px',
                                        color: 'text',
                                        fontWeight: '400',
                                        mt: 33,
                                        ml: 30,
                                    }}
                                >
                                    {theTitle}
                                </Heading>
                                {state.mode === 'list' && !taskId && (
                                    <Flex>
                                        {/* <Paragraph
                                            onClick={() => updateState({ ...state, mode: 'boards' })}
                                            sx={{
                                                mt: 38,
                                                cursor: 'pointer',
                                                fontSize: 15,
                                                color: 'primary',
                                                ml: 30,
                                            }}
                                        >
                                            Switch board (
                                            {state.board_id
                                                ? state.boards?.find((x) => x.id === state.board_id)?.name
                                                : 'Default board'}
                                            )
                                        </Paragraph> */}
                                        <ButtonToolbar
                                            loadTaskData={loadTaskData}
                                            state={state}
                                            updateState={updateState}
                                            taskId={taskId}
                                        />
                                    </Flex>
                                )}
                            </Flex>
                        </Flex>

                        <Divider ml={30} width="90%" />

                        {state.error && (
                            <Paragraph
                                sx={{
                                    textAlign: 'left',
                                    fontSize: '15px',
                                    color: 'red',
                                    mt: 10,
                                    ml: 30,
                                    mb: 20,
                                }}
                            >
                                {state.error}
                            </Paragraph>
                        )}

                        {['new', 'update'].includes(state.mode) && (
                            <Task
                                refreshCallback={loadTaskData}
                                organisation={organisation}
                                updateState={updateState}
                                state={state}
                            />
                        )}

                        {['boards'].includes(state.mode) && (
                            <Boards
                                refreshCallback={loadTaskData}
                                organisation={organisation?.entity}
                                updateState={updateState}
                                state={state}
                            />
                        )}

                        {state.mode === 'list' && !taskId && (
                            <Flex sx={{ flexDirection: 'column', width: '100%', mt: -30 }}>
                                <Filters
                                    loadTaskData={loadTaskData}
                                    state={state}
                                    updateState={updateState}
                                    organisation={organisation}
                                />

                                <Divider ml={30} width="90%" />

                                {state.view === 'kanban' && (
                                    <Flex sx={{ ml: 30, mt: 10, minHeight: '100vh', mb: 70 }}>
                                        {state.swimlanes?.map((swimlane, idx) => (
                                            <Swimlane
                                                key={`swimlane_${idx}`}
                                                data={swimlane}
                                                state={state}
                                                stateRef={stateRef}
                                                updateState={updateState}
                                                organisation={organisation}
                                                navigate={navigate}
                                            />
                                        ))}
                                    </Flex>
                                )}

                                {state.view === 'list' && (
                                    <Flex sx={{ ml: 30, mt: 0, mb: 70, mr: 30, flexDirection: 'column' }}>
                                        <Paragraph sx={{ mb: 20 }}>
                                            {state.tasks_for_list?.length || 0} tasks in list
                                        </Paragraph>
                                        <table style={{ fontSize: 14, borderCollapse: 'collapse' }}>
                                            <thead
                                                style={{
                                                    backgroundColor: theme.colors.primary,
                                                    cursor: 'pointer',
                                                    position: 'sticky',
                                                    top: 0,
                                                    color: 'white',
                                                }}
                                            >
                                                {buildHeaders(state, updateState, state.columns)}
                                            </thead>

                                            {state.tasks_for_list?.map((task, idx) => (
                                                <TaskInListView
                                                    key={`task_${task.id}_${task.status}`}
                                                    data={task}
                                                    idx={idx}
                                                    statuses={state?.organisation?.default_visualisation?.statuses}
                                                    state={state}
                                                    updateState={updateState}
                                                    navigate={navigate}
                                                    organisation={state?.organisation}
                                                />
                                            ))}
                                        </table>
                                    </Flex>
                                )}

                                {state.view === 'list' && (
                                    <Flex sx={{ position: 'fixed', bottom: 0, right: 90 }}>
                                        <Button sx={{ height: 40, fontSize: 14 }} onClick={() => window.scrollTo(0, 0)}>
                                            Scroll to top
                                        </Button>
                                    </Flex>
                                )}
                            </Flex>
                        )}
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    refreshCoreData: () => dispatch({ type: userActions.REFRESH_CORE_DATA }),
    logout: () => dispatch({ type: 'LOGOUT' }),
    dismissTopMenu: () => dispatch({ type: menuActions.DISMISS_TOP_MENU }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        loggedIn: account.loggedIn,
        dataLoading: account.loading,
        userData: account.user,
        organisation: account.organisation?.entity,
        timestampOfLastDataLoad: account.timestampOfLastDataLoad,
        isMobile: account.isMobile,
        accountSummary: account.accountSummary,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
