/* eslint-disable no-undef */
import axios from 'axios';
import { useState } from 'react';
import ReactQuill from 'react-quill';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Flex, Image, Input, Paragraph } from 'theme-ui';
import theme from '../../theme';
import Divider from '../divider';

const EditableRow = ({
    localState,
    updateLocalState,
    label,
    type,
    attributeKey,
    width = 200,
    maxLength,
    showImagePreview,
    height = 40,
}) => {
    return (
        <Flex sx={{ flexDirection: 'column', mb: 20 }}>
            <Paragraph sx={{ mb: 10 }}>{label}</Paragraph>
            {type !== 'textarea' && (
                <Input
                    sx={{ width, height }}
                    onChange={(e) => updateLocalState({ ...localState, [attributeKey]: e.target.value })}
                    value={localState[attributeKey]}
                    type={type}
                    maxLength={maxLength}
                />
            )}
            {type === 'textarea' && (
                <ReactQuill
                    theme="snow"
                    value={localState[attributeKey]}
                    style={{ height: 300, width: width || 560, marginBottom: 30 }}
                    onChange={(val) =>
                        updateLocalState({
                            ...localState,
                            [attributeKey]: val,
                        })
                    }
                />
            )}
            {showImagePreview && <Image sx={{ width: 300, mb: 10, mt: 40 }} src={localState[attributeKey]} />}
        </Flex>
    );
};

const InvoiceSettings = ({ organisation, state, updateState }) => {
    const [localState, updateLocalState] = useState({
        defaultIncrements: organisation.default_visualisation?.timesheets?.defaultIncrements || 15,
        ...organisation.default_visualisation?.invoiceOptions,
        tableHeaderColor: organisation.default_visualisation?.invoiceOptions?.tableHeaderColor || theme.colors.primary,
        bcc: (organisation.default_visualisation?.invoiceOptions?.bcc || []).join(', '),
        ...organisation?.default_visualisation?.address,
        invoicePdfLogo:
            organisation?.default_visualisation?.invoicePdfLogo || organisation?.default_visualisation?.logo,
    });

    return (
        <Flex sx={{ flexDirection: 'column', mt: 20 }}>
            <Flex sx={{ mt: 0, width: 900, justifyContent: 'space-between' }}>
                <Paragraph sx={{ color: 'text', fontWeight: 600, mt: 10, fontSize: 20 }}>Invoice Settings</Paragraph>
            </Flex>
            <Divider width="900px" />
            <EditableRow
                label="Company Logo"
                attributeKey="invoicePdfLogo"
                showImagePreview
                type="text"
                width="500px"
                localState={localState}
                updateLocalState={updateLocalState}
            />
            <Divider width="900px" />
            <EditableRow
                label="Company address Line 1"
                attributeKey="addressLineOne"
                type="text"
                localState={localState}
                updateLocalState={updateLocalState}
            />
            <EditableRow
                label="Company address city"
                attributeKey="locality"
                type="text"
                localState={localState}
                updateLocalState={updateLocalState}
            />
            <EditableRow
                label="Company address post code"
                attributeKey="postalCode"
                type="text"
                localState={localState}
                updateLocalState={updateLocalState}
            />
            <EditableRow
                label="Company address country"
                attributeKey="country"
                type="text"
                localState={localState}
                updateLocalState={updateLocalState}
            />
            <EditableRow
                label="Company vatNumber"
                attributeKey="vatNumber"
                type="text"
                localState={localState}
                updateLocalState={updateLocalState}
            />
            <Divider width="900px" />
            <EditableRow
                label="Default time recording increments (minutes)"
                attributeKey="defaultIncrements"
                type="number"
                localState={localState}
                updateLocalState={updateLocalState}
            />
            <Divider width="900px" />
            <EditableRow
                label="Payable to"
                attributeKey="payableTo"
                width="300px"
                type="text"
                localState={localState}
                updateLocalState={updateLocalState}
            />
            <EditableRow
                label="Account number"
                attributeKey="accountNumber"
                type="text"
                maxLength={8}
                localState={localState}
                updateLocalState={updateLocalState}
            />
            <EditableRow
                label="Sort code"
                attributeKey="sortCode"
                maxLength={6}
                type="text"
                localState={localState}
                updateLocalState={updateLocalState}
            />
            <Divider width="900px" />
            <EditableRow
                label="Due Date Days From Today"
                attributeKey="dueDateDays"
                type="number"
                localState={localState}
                updateLocalState={updateLocalState}
            />
            <Divider width="900px" />
            <EditableRow
                label="Invoice Email Bcc (comma separated)"
                attributeKey="bcc"
                type="text"
                width="600px"
                localState={localState}
                updateLocalState={updateLocalState}
            />
            <EditableRow
                label="Invoice Email Default Subject Line"
                attributeKey="defaultSubjectLine"
                type="text"
                width="600px"
                localState={localState}
                updateLocalState={updateLocalState}
            />
            <EditableRow
                label="Invoice Email Default Body"
                attributeKey="defaultInvoiceBody"
                type="textarea"
                width="600px"
                localState={localState}
                updateLocalState={updateLocalState}
            />

            <Divider width="900px" />
            <EditableRow
                label="Invoice PDF Table Header Color"
                attributeKey="tableHeaderColor"
                type="color"
                width={50}
                height={50}
                localState={localState}
                updateLocalState={updateLocalState}
            />
            <Flex
                sx={{
                    position: 'fixed',
                    height: 80,
                    background: 'white',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    alignItems: 'center',
                    borderTop: '2px solid #EFEFEF',
                    width: '100%',
                    zIndex: 998,
                }}
            >
                <Button
                    onClick={async () => {
                        await saveInvoiceOptions(state, updateState, localState, updateLocalState);
                    }}
                    disabled={organisation?.user?.role !== 'admin'}
                    sx={{ ml: 160, width: 150 }}
                >
                    Save
                </Button>
            </Flex>
        </Flex>
    );
};

const saveInvoiceOptions = async (state, updateState, localState, updateLocalState) => {
    try {
        updateState({ ...state, loading: true });

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations`,
            method: 'PUT',
            data: {
                default_visualisation: {
                    timesheets: {
                        defaultIncrements: localState.defaultIncrements,
                    },
                    invoiceOptions: {
                        payableTo: localState.payableTo,
                        accountNumber: localState.accountNumber,
                        sortCode: localState.sortCode,
                        dueDateDays: localState.dueDateDays,
                        bcc: localState.bcc?.split(',') || [],
                        defaultSubjectLine: localState.defaultSubjectLine,
                        defaultInvoiceBody: localState.defaultInvoiceBody,
                        tableHeaderColor: localState.tableHeaderColor,
                    },
                    address: {
                        addressLineOne: localState.addressLineOne,
                        locality: localState.locality,
                        postalCode: localState.postalCode,
                        country: localState.country,
                        vatNumber: localState.vatNumber,
                    },
                    invoicePdfLogo: localState.invoicePdfLogo,
                },
            },
        });

        const { data } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations`,
            method: 'GET',
        });

        updateState({ ...state, loading: false, organisation: data });

        toast('Successfully saved', {
            type: 'success',
            autoClose: 5000,
            position: 'top-right',
        });
    } catch (e) {
        updateState({ ...state, loading: false });
        toast('We are currently unable to save your settings', {
            type: 'error',
            autoClose: 5000,
            position: 'top-right',
        });
    }
};

export default InvoiceSettings;
