/* eslint-disable no-undef */
import { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Flex, Paragraph, Textarea } from 'theme-ui';
import { deepClone } from '../settings/manage-workflow';
import moment from 'moment';
import axios from 'axios';

const statusMapping = {
    todo: 'fa-square',
    done: 'fa-check-square',
};

const suggestEmailTemplate = async (state, updateState, subtaskState) => {
    try {
        updateState({ ...state, loading: true });

        const {
            data: { templates },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/email-templates`,
            params: { limit: 500 },
        });

        const template = templates.find((x) => x.uuid === subtaskState.emailTemplateToSuggest);

        if (template) {
            let clientForEmailTemplate;
            if (state.fullTaskData?.client_for_component?.value) {
                const { data } = await axios({
                    url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${state.fullTaskData?.client_for_component?.value}`,
                    params: {
                        raw_id: '1',
                    },
                });
                clientForEmailTemplate = data;

                clientForEmailTemplate.primary_contact = data?.client_data?.key_contacts?.find(
                    (x) => x.is_primary_contact
                );
            }

            updateState({
                ...state,
                loading: false,
                showTemplateSuggestionModal: true,
                template,
                clientForEmailTemplate,
            });
        } else {
            updateState({ ...state, loading: false });
        }
    } catch (e) {
        updateState({ ...state, loading: false });
    }
};

const Subtask = ({ subtask, state, updateState, idx, organisation }) => {
    const [subtaskState, updateSubtaskState] = useState({
        edit: subtask.edit || false,
        title: subtask.title,
        status: subtask.status || 'todo',
        uuid: subtask.uuid,
        uiKey: subtask.uiKey,
        suggestEmailTemplate: subtask.suggestEmailTemplate,
        emailTemplateToSuggest: subtask.emailTemplateToSuggest,
        task_completed_by: subtask.task_completed_by,
        task_completed_at: subtask.task_completed_at,
        creator: subtask.creator,
    });

    const missingPermissionToEdit = subtask.requireAdminPermission && organisation?.user.role !== 'admin';

    let completedByName = '';
    if (subtaskState.task_completed_by) {
        if (subtaskState.task_completed_by === 'System') {
            completedByName = 'System';
        } else if (subtaskState.task_completed_by === 'Accounting Flow AI') {
            completedByName = 'Accounting Flow AI';
        } else {
            const user = state.users?.find((x) => x.id === subtaskState.task_completed_by);
            if (user) {
                completedByName = `${user.first_name} ${user.last_name}`;
            }
        }
    }

    const [collected, drag, dragPreview] = useDrag(
        () => ({
            type: 'subtask',
            item: { subtask, idx },
            end: (item, monitor) => {
                if (state.idxMovingTo === state.idxOfItemMoving) return;

                const newSubtasks = deepClone([...(state.fullTaskData?.subtasks || [])]);

                const itemToMove = deepClone(newSubtasks[state.idxOfItemMoving]);
                const existingAtLocation = deepClone(newSubtasks[state.idxMovingTo]);

                newSubtasks[state.idxMovingTo] = itemToMove;
                newSubtasks[state.idxOfItemMoving] = existingAtLocation;

                updateState({
                    ...state,
                    fullTaskData: {
                        ...state.fullTaskData,
                        subtasks: newSubtasks,
                    },
                    idxMovingTo: -1,
                    idxOfItemMoving: -1,
                });
            },
        }),
        [state.idxMovingTo, state.idxOfItemMoving, subtask]
    );

    const [, drop] = useDrop(
        () => ({
            accept: 'subtask',
            hover(item) {
                updateState({
                    ...state,
                    idxOfItemMoving: idx,
                    idxMovingTo: item.idx,
                });
            },
        }),
        [state.idxMovingTo, state.idxOfItemMoving]
    );

    return collected.isDragging ? (
        <div ref={dragPreview} />
    ) : (
        <div ref={(node) => drag(drop(node))} {...collected}>
            <Flex
                sx={{
                    padding: '15px 0px',
                    borderTop: '1px solid #EFEFEF',
                    cursor: 'pointer',
                    flexDirection: 'column',
                }}
            >
                <Flex
                    sx={{
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                        alignItems: 'center',
                    }}
                >
                    {!subtaskState.edit && (
                        <Paragraph
                            onClick={() =>
                                !subtask.autoResolveUsingWorkflow &&
                                updateSubtaskState({
                                    ...subtaskState,
                                    edit: true,
                                })
                            }
                            sx={{
                                width: 250,
                                fontSize: 14,
                                wordWrap: 'break-word',
                                textDecoration: subtaskState.status === 'done' ? 'line-through' : '',
                            }}
                        >
                            {subtaskState.title}
                        </Paragraph>
                    )}
                    {subtaskState.edit && (
                        <Textarea
                            value={subtaskState.title}
                            onChange={(e) => {
                                updateSubtaskState({
                                    ...subtaskState,
                                    title: e.target.value,
                                });
                                updateState({
                                    ...state,
                                    fullTaskData: {
                                        ...state.fullTaskData,
                                        subtasks: (state.fullTaskData.subtasks || []).map((x) => {
                                            if (x.uiKey === subtaskState.uiKey) {
                                                x.title = e.target.value;
                                            }
                                            return x;
                                        }),
                                    },
                                });
                            }}
                            sx={{ width: 350, fontWeight: '400', border: '1px solid #a3a69b', fontSize: 15, mb: 0 }}
                        />
                    )}
                    {!subtask.autoResolveUsingWorkflow && !missingPermissionToEdit && (
                        <Flex>
                            <i
                                style={{
                                    marginTop: '0px',
                                    fontSize: '18px',
                                    marginLeft: '9px',
                                    marginRight: '6px',
                                    color: '#000',
                                }}
                                onClick={async () => {
                                    let extraState = { task_completed_at: null, task_completed_by: null };
                                    if (subtaskState.status === 'todo') {
                                        extraState = {
                                            task_completed_at: moment.utc().format('YYYY-MM-DDTHH:mm:ssZ'),
                                            task_completed_by: state.userIdOfLoggedInUser,
                                        };
                                    }

                                    updateSubtaskState({
                                        ...subtaskState,
                                        status: subtaskState.status === 'todo' ? 'done' : 'todo',
                                        edit: subtaskState.status === 'todo' ? false : true,
                                        ...extraState,
                                    });
                                    updateState({
                                        ...state,
                                        fullTaskData: {
                                            ...state.fullTaskData,
                                            ...(subtask.changeStatus && subtaskState.status === 'todo'
                                                ? {
                                                      status_for_component: {
                                                          value: subtask.changeStatusTo,
                                                          label: organisation?.default_visualisation?.statuses?.find(
                                                              (x) => x.key === subtask?.changeStatusTo
                                                          )?.label,
                                                      },
                                                  }
                                                : {}),
                                            subtasks: (state.fullTaskData.subtasks || []).map((x) => {
                                                if (x.uiKey === subtaskState.uiKey) {
                                                    x.status = subtaskState.status === 'todo' ? 'done' : 'todo';
                                                    x.edit = subtaskState.status === 'todo' ? false : true;

                                                    x.task_completed_at = extraState.task_completed_at;
                                                    x.task_completed_by = extraState.task_completed_by;
                                                }
                                                return x;
                                            }),
                                        },
                                    });

                                    if (subtaskState.status === 'todo' && subtaskState.suggestEmailTemplate) {
                                        await suggestEmailTemplate(state, updateState, subtaskState);
                                    }
                                }}
                                className={`fal ${statusMapping[subtaskState.status]}`}
                            />
                            <i
                                style={{
                                    marginTop: '0px',
                                    fontSize: '18px',
                                    marginLeft: '9px',
                                    marginRight: '6px',
                                    color: 'red',
                                }}
                                className="fas fa-trash"
                                onClick={() =>
                                    updateState({
                                        ...state,
                                        showDeleteSubtaskConfirmation: true,
                                        confirmCallback: () => {
                                            updateState({
                                                ...state,
                                                showDeleteSubtaskConfirmation: false,
                                                fullTaskData: {
                                                    ...state.fullTaskData,
                                                    subtasks: (state.fullTaskData.subtasks || []).filter(
                                                        (x) => x.uiKey !== subtaskState.uiKey
                                                    ),
                                                },
                                            });
                                        },
                                    })
                                }
                            />
                        </Flex>
                    )}
                </Flex>
                <Flex sx={{ flexDirection: 'column' }}>
                    <a
                        onClick={async () => {
                            updateState({
                                ...state,
                                showSubTaskCommentsDialog: true,
                                subTaskUIKey: subtaskState.uiKey,
                            });
                        }}
                        style={{ color: 'blue', fontSize: 13, marginTop: '10px' }}
                    >
                        Comments ({subtask.comments?.length || 0})
                    </a>
                    {subtaskState.suggestEmailTemplate && subtaskState.status === 'done' && (
                        <a
                            onClick={async () => {
                                await suggestEmailTemplate(state, updateState, subtaskState);
                            }}
                            style={{ color: 'blue', fontSize: 13, marginTop: '10px' }}
                        >
                            View Email Template
                        </a>
                    )}
                    {subtaskState.creator && (
                        <Flex>
                            <Paragraph sx={{ mt: 10, fontSize: 13 }}>Created by {subtaskState.creator}</Paragraph>
                        </Flex>
                    )}
                    {subtaskState.task_completed_at && subtaskState.status === 'done' && (
                        <Flex>
                            <Paragraph sx={{ mt: 10, fontSize: 13 }}>
                                Completed: {moment(subtaskState.task_completed_at).format('HH:mm Do MMM YY')}
                                {completedByName ? ` by ${completedByName}` : ''}
                            </Paragraph>
                        </Flex>
                    )}
                </Flex>
            </Flex>
        </div>
    );
};

export default Subtask;
