/* eslint-disable no-undef */
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Flex, Paragraph } from 'theme-ui';
import theme from '../../theme';
import Divider from '../divider';
import ConfirmDialog from '../tasks/confirm-dialog';

export const loadInvoices = async (state, updateState, setInvoices) => {
    if (!state.clientInView.id) return;
    updateState({ ...state, loading: true });

    try {
        const {
            data: { invoices },
        } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/invoices`, {
            params: {
                client_uuid: state.clientInView.id,
            },
        });

        updateState({ ...state, loading: false });
        setInvoices(invoices);
    } catch (e) {
        updateState({ ...state, loading: false });
        setInvoices([]);
    }
};

const Invoices = ({ state, updateState }) => {
    const [invoices, setInvoices] = useState([]);
    useEffect(() => {
        loadInvoices(state, updateState, setInvoices);
    }, []);

    return (
        <Flex sx={{ flexDirection: 'column', mt: 20, ml: 20 }}>
            <Paragraph sx={{ fontWeight: 600, fontSize: 20 }}>Invoices</Paragraph>
            <Divider width={'95%'} />
            <Button
                onClick={() =>
                    updateState({
                        ...state,
                        view: 'generate_invoice',
                        error: null,
                        setInvoices,
                    })
                }
                sx={{ width: 100, height: 40, fontSize: 14 }}
            >
                <i
                    style={{
                        marginRight: '7px',
                    }}
                    className={`fas fa-plus-circle`}
                />
                New
            </Button>

            {state.showDeleteInvoiceConfirmation && (
                <ConfirmDialog
                    updateState={updateState}
                    state={state}
                    stateKey="showDeleteInvoiceConfirmation"
                    text="Are you sure you wish to delete this invoice?"
                />
            )}

            <table
                style={{
                    borderCollapse: 'collapse',
                    width: '1000px',
                    marginTop: 20,
                    marginRight: 20,
                    fontSize: 14,
                }}
            >
                <thead
                    style={{
                        backgroundColor: theme.colors.primary,
                        opacity: 0.9,
                        height: 50,
                        color: 'white',
                    }}
                >
                    <tr style={{ borderTop: '0px solid lightGrey' }}>
                        <th style={{ width: 150, textAlign: 'center', paddingLeft: 20 }}>Invoice Number</th>
                        <th style={{ width: 150, textAlign: 'center', padding: 20 }}>Invoice Date</th>
                        <th style={{ width: 150, textAlign: 'center', padding: 20 }}>Total Amount</th>
                        <th style={{ width: 150, textAlign: 'center', padding: 20 }}>Created At</th>
                        <th style={{ width: 125 }}></th>
                    </tr>
                </thead>
                <tbody style={{ borderCollapse: 'collapse' }}>
                    {invoices?.map((invoice, idx) => (
                        <tr key={`invoice_${idx}`}>
                            <td style={{ padding: 25, borderLeft: 'none', borderRight: 'none', textAlign: 'center' }}>
                                {invoice.invoice_number}
                            </td>
                            <td style={{ padding: 25, borderLeft: 'none', borderRight: 'none', textAlign: 'center' }}>
                                {moment(invoice.invoice_date).format('DD/MM/YYYY')}
                            </td>
                            <td style={{ padding: 25, borderLeft: 'none', borderRight: 'none', textAlign: 'center' }}>
                                {new Intl.NumberFormat('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(invoice.total || 0)}
                            </td>
                            <td style={{ padding: 25, borderLeft: 'none', borderRight: 'none', textAlign: 'center' }}>
                                {moment(invoice.created_at).format('HH:mm DD/MM/YYYY')}
                            </td>
                            <td style={{ padding: 25, width: 125 }}>
                                <i
                                    style={{
                                        marginLeft: 20,
                                        fontSize: '17px',
                                        cursor: 'pointer',
                                        color: 'orange',
                                    }}
                                    className="fas fa-pencil"
                                    aria-hidden="true"
                                    onClick={async () => {
                                        updateState({
                                            ...state,
                                            view: 'generate_invoice',
                                            error: null,
                                            invoice,
                                            setInvoices,
                                        });
                                    }}
                                />
                                <i
                                    style={{
                                        marginLeft: 20,
                                        fontSize: '17px',
                                        cursor: 'pointer',
                                        color: 'green',
                                    }}
                                    className="fas fa-download"
                                    aria-hidden="true"
                                    onClick={async () => {
                                        const {
                                            data: { url },
                                        } = await axios.get(
                                            `${process.env.REACT_APP_AQRU_AI_API}/documents/${invoice.finalised_doc_uuid}`,
                                            {}
                                        );

                                        const a = window.document.createElement('a');

                                        a.href = url;
                                        a.target = '_blank';
                                        a.download = 'Invoice.pdf';

                                        a.click();
                                    }}
                                />
                                <i
                                    style={{
                                        marginLeft: 20,
                                        fontSize: '17px',
                                        cursor: 'pointer',
                                        color: 'red',
                                    }}
                                    className="fas fa-trash"
                                    aria-hidden="true"
                                    onClick={async () => {
                                        updateState({
                                            ...state,
                                            showDeleteInvoiceConfirmation: true,
                                            confirmCallback: async () => {
                                                updateState({ ...state, loading: true });

                                                try {
                                                    await axios.delete(
                                                        `${process.env.REACT_APP_AQRU_AI_API}/invoices/${invoice.uuid}`,
                                                        {}
                                                    );

                                                    await loadInvoices(state, updateState, setInvoices);

                                                    toast('Invoice successfully deleted', {
                                                        type: 'info',
                                                        autoClose: 5000,
                                                        position: 'top-right',
                                                    });
                                                } catch (e) {
                                                    updateState({
                                                        ...state,
                                                        loading: false,
                                                    });
                                                    toast('We are currently unable to delete your invoice', {
                                                        type: 'error',
                                                        autoClose: 5000,
                                                        position: 'top-right',
                                                    });
                                                }
                                            },
                                        });
                                    }}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Flex>
    );
};

export default Invoices;
