/* eslint-disable no-undef */
import { Auth } from 'aws-amplify';
import axios from 'axios';
import moment from 'moment';
import { PDFDocument } from 'pdf-lib';
import { useEffect, useState } from 'react';
import { Box, Button, Flex, Image, Input, Label, Paragraph } from 'theme-ui';
import * as uuid from 'uuid';
import { textInputSvgWithoutBorder } from '../clients/doc-sign';
import Divider from '../divider';
import Overlay from '../overlay/overlay';
import ConfirmDialog from '../tasks/confirm-dialog';
import Spinner from '../spinner';
import SignatureSetupModal from './signature-setup-modal';
import { resizeFont } from '../clients/pdf-frame';
import { fireOffNotification } from '../../pages/dashboard';

const defaultLocalState = { loading: false, page: 1 };

const signatureWidth = 250;
const signatureHeight = 60;

const ReferenceDocs = ({ localState }) => {
    if (!localState.reference_docs?.length) return null;

    return (
        <Flex sx={{ alignSelf: 'flex-start', mt: 30, flexDirection: 'column' }}>
            <Paragraph sx={{ textAlign: 'left', fontWeight: 600, mt: 20, fontSize: 20 }}>Reference documents</Paragraph>
            <Box sx={{ height: 20 }} />
            {localState.reference_docs?.map((doc) => (
                <Flex sx={{ mt: 10, flexDirection: 'column' }} key={doc.uuid}>
                    <Flex sx={{ alignItems: 'center' }}>
                        <Paragraph sx={{ width: 400 }}>{doc.fileName}</Paragraph>
                        <Button
                            onClick={async () => {
                                const {
                                    data: { url },
                                } = await axios.get(`${process.env.REACT_APP_AQRU_AI_API}/documents/${doc.uuid}`, {});

                                const a = window.document.createElement('a');

                                a.href = url;
                                a.target = '_blank';

                                return a.click();
                            }}
                            sx={{ height: 40, ml: 20 }}
                        >
                            View
                        </Button>
                    </Flex>
                    <Divider />
                </Flex>
            ))}
        </Flex>
    );
};

const isAllDone = (localState) => {
    const countOfSignaturesRequired = localState.pdfs
        ?.map((x) => x.signatureLocations.filter((y) => y.isCompanySignature !== 'true').length || 0)
        .reduce((accum, count) => {
            accum = accum + count;
            return accum;
        }, 0);

    const countOfSignaturesDone = localState.pdfs
        ?.map(
            (pdf) =>
                Object.entries(pdf.userSignatureLocations)
                    .filter((x) => !x[0].includes('last_signature'))
                    .map((x) => x[1])
                    ?.flat()?.length || 0
        )
        .reduce((accum, count) => {
            accum = accum + count;
            return accum;
        }, 0);

    return countOfSignaturesRequired === countOfSignaturesDone;
};

const isAllDoneForSpecificUser = (localState, userId) => {
    const countOfSignaturesRequired = localState.pdfs
        ?.map(
            (x) =>
                x.signatureLocations.filter((y) => y.isCompanySignature !== 'true' && y.clientUserId === userId)
                    .length || 0
        )
        .reduce((accum, count) => {
            accum = accum + count;
            return accum;
        }, 0);

    const countOfSignaturesDone = localState.pdfs
        ?.map((pdf) => pdf.userSignatureLocations[userId]?.filter((x) => x.clientUserId === userId)?.length || 0)
        .reduce((accum, count) => {
            accum = accum + count;
            return accum;
        }, 0);

    return countOfSignaturesRequired === countOfSignaturesDone;
};

const isAllDoneForSpecificUserForSpecificDoc = (localState, userId, docIdx) => {
    const countOfSignaturesRequired =
        localState.pdfs[docIdx].signatureLocations.filter(
            (y) => y.isCompanySignature !== 'true' && y.clientUserId === userId
        ).length || 0;

    const countOfSignaturesDone =
        localState.pdfs[docIdx].userSignatureLocations[userId]?.filter((x) => x.clientUserId === userId)?.length || 0;

    return countOfSignaturesRequired === countOfSignaturesDone;
};

const save = async (localState, updateLocalState, state, updateState, finalise = false) => {
    try {
        updateLocalState({ ...localState, loading: true });

        const username = state.organisation.user_id;

        const {
            data: { status, finalised_document_uuid, additional_pdfs },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/docsign/${localState.docsignId}`,
            method: 'PUT',
            data: {
                signature_locations: {
                    [`${username}_last_signature`]: localState.signatureBase64,
                    [username]: localState.pdfs?.[0].userSignatureLocations[username]?.filter(
                        (x) => x.clientUserId === username
                    ),
                },
                additional_pdfs: localState.pdfs
                    ?.slice(1)
                    ?.map((pdf) => pdf.userSignatureLocations[username]?.filter((x) => x.clientUserId === username)),
                finalise,
            },
        });

        const {
            data: { documents },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/docsign`,
        });

        const newLocalState = {
            ...localState,
            loading: false,
            error: null,
            page: finalised_document_uuid ? 1 : localState.page,
        };

        newLocalState.pdfs[0].finalised_document_uuid = finalised_document_uuid;
        newLocalState.pdfs?.forEach((pdf) => {
            pdf.status = status;
        });

        if (Array.isArray(additional_pdfs)) {
            additional_pdfs?.forEach((rec, idx) => {
                newLocalState.pdfs[idx + 1].finalised_document_uuid = rec.finalisedDocUuid;
            });
        }

        updateLocalState(newLocalState);

        const allDone = isAllDone(newLocalState);

        const newState = {
            ...state,
            documents,
            error: null,
        };

        updateState({
            ...newState,
            pdfIndex: newState.finalise ? 0 : newState.pdfIndex,
            page: newState.finalise ? 1 : newState.page,
            showFinaliseConfirmation: newState.finalise ? false : allDone,
            confirmCallback: async () => {
                await save(newLocalState, updateLocalState, newState, updateState, true);
            },
        });

        if (!allDone && isAllDoneForSpecificUser(localState, username)) {
            updateState({
                ...newState,
                showAllDoneForYouDialog: true,
                confirmCallback: () => {},
            });
            fireOffNotification(`has signed document: ${localState.pdfs?.[0]?.name}`);
        }

        if (newState.finalise) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    } catch (e) {
        updateLocalState({
            ...localState,
            loading: false,
            error: e?.response?.data?.message || 'We have been unable to save your data',
        });
    }
};

function drawImageOnCanvas(svg, x, y, canvasId = 'signature-canvas', additionalParams) {
    const svgContainer = document.getElementById(canvasId);

    if (!svgContainer) return;

    const resizeWrapper = document.createElement('div');
    resizeWrapper.classList.add('resize-handle');
    resizeWrapper.style.left = `${x}px`;
    resizeWrapper.style.top = `${y}px`;
    resizeWrapper.style.position = 'absolute';
    resizeWrapper.id = `resize_${additionalParams?.uniqueId}`;
    resizeWrapper.dataset.type = additionalParams.type;

    let wrapper;
    if (svg.includes('data:image')) {
        wrapper = document.createElement('img');
        wrapper.src = svg;
        wrapper.draggable = false;
        if (additionalParams.newFontSize) {
            wrapper.style.width = `${(additionalParams.newFontSize / 20) * 200}px`;
        }
    } else {
        wrapper = document.createElement('div');
        wrapper.innerHTML = svg;
    }

    wrapper.classList.add('svg-element');
    wrapper.classList.add('draggable-svg');

    wrapper.id = additionalParams?.uniqueId;

    if (additionalParams?.isCompanySignature) {
        wrapper.style.fontSize = additionalParams.newFontSize ? `${additionalParams.newFontSize}px` : '20px';
        wrapper.style.fontFamily = additionalParams?.font;
        wrapper.style.color = 'black';
        resizeWrapper.style.border = '0px';
    }

    resizeWrapper.appendChild(wrapper);
    svgContainer.appendChild(resizeWrapper);

    if (additionalParams.type === 'textInput' && additionalParams.newFontSize) {
        const svgTextElement = document.getElementById(`${additionalParams?.uniqueId}_text`);
        if (svgTextElement) {
            svgTextElement.setAttribute('font-size', additionalParams.newFontSize + 'px');
            const textHeight = svgTextElement.getBBox().height;
            svgTextElement.setAttribute('y', textHeight / 2);
            svgTextElement.parentNode.setAttribute('height', svgTextElement.getBBox().height);
        }
    }
}

const renderExistingSignatures = (localState, pageNo, userId) => {
    localState.pdfs?.[localState.pdfIndex]?.signatureLocations
        ?.filter((item) => {
            return item.page === pageNo;
        })
        .forEach((item) => {
            if (item.clientUserId === userId) {
                const signedLocation = localState.pdfs?.[localState.pdfIndex]?.userSignatureLocations[userId]?.find(
                    (x) => x.page === pageNo && x.type === item.type
                );

                if (!signedLocation) {
                    if (item.type === 'textInput') {
                        drawImageOnCanvas(item.svgText, item.x, item.y, `canvas-wrapper-${localState.pdfIndex}`, {
                            uniqueId: item.uuid,
                            type: item.type,
                            newFontSize: item.newFontSize,
                        });
                    } else {
                        drawImageOnCanvas(item.svgText, item.x, item.y, `canvas-wrapper-${localState.pdfIndex}`, {
                            uniqueId: item.uuid,
                            type: item.type,
                            newFontSize: item.newFontSize,
                        });
                    }
                }
            } else {
                drawImageOnCanvas(item.svgText, item.x, item.y, `canvas-wrapper-${localState.pdfIndex}`, {
                    isCompanySignature: item.isCompanySignature,
                    newFontSize: item.newFontSize,
                    font: item.font,
                    uniqueId: item.uuid,
                    type: item.type,
                });
            }
        });

    const signedLocations =
        localState.pdfs?.[localState.pdfIndex]?.userSignatureLocations?.[userId]?.filter(
            (x) => x.clientUserId === userId && x.page === pageNo
        ) || [];

    for (const item of signedLocations) {
        if (item.type === 'textInput' && item.userInput) {
            drawImageOnCanvas(
                textInputSvgWithoutBorder(`${item.uuid}_text`, item.userInput),
                item.x,
                item.y,
                `canvas-wrapper-${localState.pdfIndex}`,
                {
                    uniqueId: item.uuid,
                    type: item.type,
                    newFontSize: item.newFontSize,
                }
            );
        }

        if (item.type !== 'textInput' && localState.signatureBase64) {
            drawImageOnCanvas(localState.signatureBase64, item.x, item.y, `canvas-wrapper-${localState.pdfIndex}`, {
                signatureSize: localState.signatureSize,
                uniqueId: item.uuid,
                newFontSize: item.newFontSize,
                type: item.type,
            });
        }
    }
};

const renderPage = async (doc, pageNo, localState, updateState) => {
    document.querySelectorAll('div.draggable-svg').forEach((div) => {
        div.remove();
    });
    document.querySelectorAll('img.draggable-svg').forEach((div) => {
        div.remove();
    });
    document.querySelectorAll('div.resize-handle').forEach((div) => {
        div.remove();
    });

    const page = await doc.getPage(pageNo);

    const username = localState.organisation?.user_id;

    var scale = 1;
    var viewport = page.getViewport({ scale: scale });

    const canvas = document.getElementById('pdf-canvas');

    const signaturesRequired = [];

    localState.pdfs?.[localState.pdfIndex]?.signatureLocations?.forEach((item) => {
        if (
            item.clientUserId === username &&
            item.type === 'signature' &&
            !signaturesRequired?.find((x) => x.clientUserId === username && x.page === item.page)
        ) {
            signaturesRequired.push(item);
        }
    });

    const newLocalState = {
        ...localState,
        signaturesRequired,
    };

    updateState(newLocalState);

    if (!canvas) return;

    const context = canvas.getContext('2d');

    canvas.height = viewport.height;
    canvas.width = viewport.width;

    await page.render({
        canvasContext: context,
        viewport: viewport,
    }).promise;

    canvas.addEventListener('dragover', (e) => {
        e.preventDefault();
    });

    renderExistingSignatures(newLocalState, pageNo, username);
};

const downloadFinalisedDoc = async (doc, state, updateState) => {
    try {
        updateState({ ...state, loading: true, error: null });

        const {
            data: { url },
        } = await axios.get(
            `${process.env.REACT_APP_AQRU_AI_API}/documents/portal-user/${doc.finalised_document_uuid}`
        );

        const a = document.createElement('a');
        a.href = url;
        a.download = 'Finalised.pdf';
        a.click();

        updateState({ ...state, loading: false, error: null });
    } catch (e) {
        console.log(e);
        updateState({ ...state, loading: false, error: 'We have been unable to download your document' });
    }
};

const ClientSignature = ({ state, updateState, organisation }) => {
    const [localState, updateLocalState] = useState({
        ...defaultLocalState,
        pdfIndex: 0,
        organisation,
        signatureBase64: state.docsign?.last_signature,
        docsignId: state.docsign?.id,
        reference_docs: state.docsign?.additional_data?.reference_docs || [],
        archive_information: state.docsign?.additional_data?.archive_information || [],
        pdfs: [
            {
                status: state.docsign.status,
                name: state.docsign.name,
                description: state.docsign.description,
                document_for_signing_uuid: state.docsign.document_for_signing_uuid,
                finalised_document_uuid: state.docsign.finalised_document_uuid,
                signatureLocations: state.docsign?.signature_placeholder_locations?.signatureLocations || [],
                signatureSize:
                    state.docsign?.signature_placeholder_locations?.signatureSizes?.[state.userId] || 'medium',
                userSignatureLocations: state.docsign?.signature_locations || {},
                reference_docs: state.docsign?.additional_data?.reference_docs || [],
            },
            ...(state.docsign?.additional_data?.pdfs?.map((x) => ({
                status: state.docsign.status,
                name: state.docsign.name,
                description: state.docsign.description,
                reference_docs: state.docsign?.additional_data?.reference_docs || [],

                document_for_signing_uuid: x.doc_uuid,
                finalised_document_uuid: x.finalisedDocUuid,
                signatureLocations: x?.signature_placeholder_locations?.signatureLocations || [],
                signatureSize: x?.signature_placeholder_locations?.signatureSizes?.[state.userId] || 'medium',
                userSignatureLocations: x?.signature_locations || {},
            })) || []),
        ],
    });

    useEffect(() => {
        (async () => {
            try {
                await document.fonts.load('10pt "Great Vibes"');
                await document.fonts.load('10pt "Pacifico"');
                await document.fonts.load('10pt "Dancing Script"');
            } catch (e) {
                console.log('Force load failed', e);
            }
        })();

        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        (async () => {
            if (localState.pdfs?.[localState.pdfIndex]) {
                updateLocalState({ ...localState, loading: true });

                const [
                    {
                        data: { url },
                    },
                    {
                        data: { relations },
                    },
                ] = await Promise.all([
                    axios.get(
                        `${process.env.REACT_APP_AQRU_AI_API}/documents/portal-user/${
                            localState.pdfs?.[localState.pdfIndex]?.finalised_document_uuid ||
                            localState.pdfs?.[localState.pdfIndex].document_for_signing_uuid
                        }`
                    ),
                    axios.get(`${process.env.REACT_APP_AQRU_AI_API}/docsign/${state.docsign.id}`),
                ]);

                const { data } = await axios.get(url, {
                    headers: { 'Content-Type': 'application/pdf' },
                    responseType: 'arraybuffer',
                });

                const pdfDoc = await PDFDocument.load(data);

                const blob = new Blob([data], { type: 'application/pdf' });

                const fileUrl = URL.createObjectURL(blob);

                const pdfLib = window.pdfjsLib;
                pdfLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';

                const doc = await pdfLib.getDocument(fileUrl).promise;

                const newState = {
                    ...localState,
                    loading: false,
                    relations,
                };

                newState.pdfs[localState.pdfIndex] = {
                    ...newState.pdfs[localState.pdfIndex],
                    pdfDoc,
                    pdfPageWidth: pdfDoc.getPage(0).getWidth(),
                    pdfPageHeight: pdfDoc.getPage(0).getHeight(),
                    pdfData: data,
                    pdfDocument: doc,
                    document_uuid:
                        localState.pdfs?.[localState.pdfIndex].finalised_document_uuid ||
                        localState.pdfs?.[localState.pdfIndex].document_for_signing_uuid,
                };

                await renderPage(doc, newState.page, newState, updateLocalState);
            }
        })();
    }, [localState.page, localState.pdfIndex, localState.pdfs?.[localState.pdfIndex]?.status]);

    if (localState.pdfs?.[localState.pdfIndex]?.status === 'finalised') {
        return (
            <Flex
                sx={{
                    mb: 50,
                    backgroundColor: 'white',
                    padding: 30,
                    borderRadius: 10,
                    maxWidth: 1400,
                    width: ['95%', '95%', '90%'],
                    overflowX: ['scroll', 'scroll', 'unset'],
                }}
            >
                <Flex sx={{ flexDirection: 'column' }}>
                    {localState.loading && <Spinner />}

                    <Button sx={{ width: 100 }} onClick={() => window.location.assign('/home')} variant="light">
                        <i
                            style={{
                                fontSize: '12px',
                                cursor: 'pointer',
                                marginRight: '5px',
                            }}
                            className="fas fa-chevron-left"
                            aria-hidden="true"
                        />
                        Back
                    </Button>

                    <Paragraph sx={{ mb: 20, fontWeight: 600, mt: 30, fontSize: 24 }}>
                        {localState.pdfs?.[localState.pdfIndex]?.name}
                    </Paragraph>

                    {localState.pdfs?.[localState.pdfIndex]?.description && (
                        <Paragraph sx={{ mb: 20, fontWeight: 400, mt: -10, fontSize: 18 }}>
                            {localState.pdfs?.[localState.pdfIndex]?.description}
                        </Paragraph>
                    )}

                    <Paragraph sx={{ mb: 10, fontWeight: 400, fontSize: 18 }}>
                        All signatures have been collected.
                    </Paragraph>
                    <Paragraph sx={{ mb: 20, fontWeight: 400, fontSize: 18 }}>
                        This document will be saved to your portal to access in future if needed.
                    </Paragraph>

                    {localState.error && (
                        <Paragraph sx={{ fontWeight: 600, mb: 20, mt: 20, color: 'red' }}>{localState.error}</Paragraph>
                    )}

                    <Flex
                        sx={{
                            borderBottom: '1px solid lightgray',
                            width: localState.pdfs?.[localState.pdfIndex]?.pdfPageWidth || '500px',
                        }}
                    >
                        {localState.pdfs?.map((x, pdfIndex) => (
                            <Flex
                                key={`tab_${pdfIndex}`}
                                sx={{
                                    mb: '0',
                                    pb: '0',
                                    height: '60px',
                                    borderRadius: '10px',
                                    border: '1px solid lightgray',
                                    borderBottom: '0px solid lightgray',
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 0,
                                    ml: '5px',
                                    mt: '5px',
                                    mr: '5px',
                                    width: 200,
                                }}
                            >
                                <Flex
                                    sx={{
                                        flex: 1,
                                        textAlign: 'center',
                                        alignSelf: 'center',
                                        cursor: 'pointer',
                                        height: 60,
                                        width: 200,
                                    }}
                                    onClick={() => updateLocalState({ ...localState, pdfIndex: pdfIndex })}
                                >
                                    <Paragraph
                                        sx={{
                                            textAlign: 'center',
                                            width: '100%',
                                            fontSize: '15px',
                                            alignSelf: 'center',
                                            ml: 10,
                                            fontWeight: localState.pdfIndex === pdfIndex ? '600' : '400',
                                        }}
                                    >
                                        Document {pdfIndex + 1}{' '}
                                    </Paragraph>
                                </Flex>
                            </Flex>
                        ))}
                    </Flex>

                    <Button
                        sx={{ width: 100, fontSize: 14, height: 40, mb: 0, mt: 20 }}
                        onClick={async () => {
                            const files = [];

                            for (const pdf of localState.pdfs) {
                                files.push(pdf?.finalised_document_uuid);
                            }

                            const response = await axios({
                                url: `${process.env.REACT_APP_AQRU_AI_API}/documents/portal/download-multiple`,
                                method: 'POST',
                                data: { files },
                                responseType: 'blob',
                            });

                            const fileBlob = new Blob([response.data], {
                                type: `application/${files.length > 1 ? 'zip' : 'pdf'}`,
                            });

                            const fileURL = URL.createObjectURL(fileBlob);

                            const link = document.createElement('a');
                            link.href = fileURL;
                            link.download = `${localState.pdfs?.[0]?.name}.${files.length > 1 ? 'zip' : 'pdf'}`;
                            document.body.appendChild(link);
                            link.click();

                            document.body.removeChild(link);
                            URL.revokeObjectURL(fileURL);

                            return;
                        }}
                    >
                        Download
                    </Button>

                    <Box
                        sx={{
                            padding: 10,
                            border: '1px solid lightGrey',
                            position: 'relative',
                            width: localState.pdfs?.[localState.pdfIndex]?.pdfPageWidth + 2 || '500px',
                            height: localState.pdfs?.[localState.pdfIndex]?.pdfPageHeight + 2 || '1px',
                            mt: 20,
                            cursor: 'grab',
                        }}
                    >
                        <canvas
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: localState.pdfs?.[localState.pdfIndex]?.pdfPageWidth || '500px',
                                height: localState.pdfs?.[localState.pdfIndex]?.pdfPageHeight || '1px',
                            }}
                            id="pdf-canvas"
                        />
                    </Box>

                    <Flex sx={{ flexDirection: 'column' }}>
                        <Flex
                            sx={{
                                ml: [20, 20, localState.pdfs?.[localState.pdfIndex]?.pdfPageWidth / 2 - 160],
                                mt: 20,
                            }}
                        >
                            <Button
                                disabled={localState.page === 1}
                                onClick={async () => {
                                    updateLocalState({ ...localState, page: 1 });
                                }}
                                sx={{ width: 60, opacity: localState.page === 1 ? 0.5 : 1 }}
                            >
                                <i
                                    style={{
                                        fontSize: '15px',
                                        cursor: 'pointer',
                                        color: 'white',
                                    }}
                                    className="fas fa-fast-backward"
                                    aria-hidden="true"
                                />
                            </Button>
                            <Button
                                disabled={localState.page === 1}
                                onClick={async () => {
                                    const page = Math.max(localState.page - 1, 1);
                                    updateLocalState({ ...localState, page });
                                }}
                                sx={{ width: 60, ml: 20, opacity: localState.page === 1 ? 0.5 : 1 }}
                            >
                                <i
                                    style={{
                                        fontSize: '15px',
                                        cursor: 'pointer',
                                        color: 'white',
                                    }}
                                    className="fas fa-step-backward"
                                    aria-hidden="true"
                                />
                            </Button>
                            <Button
                                disabled={
                                    localState.page >= localState.pdfs?.[localState.pdfIndex]?.pdfDocument?.numPages
                                }
                                onClick={async () => {
                                    const page = Math.min(
                                        localState.page + 1,
                                        localState.pdfs?.[localState.pdfIndex]?.pdfDocument?.numPages
                                    );

                                    updateLocalState({ ...localState, page });
                                }}
                                sx={{
                                    width: 60,
                                    ml: 20,
                                    opacity:
                                        localState.page >= localState.pdfs?.[localState.pdfIndex]?.pdfDocument?.numPages
                                            ? 0.5
                                            : 1,
                                }}
                            >
                                <i
                                    style={{
                                        fontSize: '15px',
                                        cursor: 'pointer',
                                        color: 'white',
                                    }}
                                    className="fas fa-step-forward"
                                    aria-hidden="true"
                                />
                            </Button>
                            <Button
                                disabled={
                                    localState.page >= localState.pdfs?.[localState.pdfIndex]?.pdfDocument?.numPages
                                }
                                onClick={async () => {
                                    updateLocalState({
                                        ...localState,
                                        page: localState.pdfs?.[localState.pdfIndex]?.pdfDocument?.numPages,
                                    });
                                }}
                                sx={{
                                    width: 60,
                                    ml: 20,
                                    opacity:
                                        localState.page >= localState.pdfs?.[localState.pdfIndex]?.pdfDocument?.numPages
                                            ? 0.5
                                            : 1,
                                }}
                            >
                                <i
                                    style={{
                                        fontSize: '15px',
                                        cursor: 'pointer',
                                        color: 'white',
                                    }}
                                    className="fas fa-fast-forward"
                                    aria-hidden="true"
                                />
                            </Button>
                        </Flex>
                        <Flex sx={{ alignSelf: 'center', mt: 20 }}>
                            <Paragraph sx={{ textAlign: 'center', mr: 10 }}>
                                Page {localState.page} of{' '}
                                {localState.pdfs?.[localState.pdfIndex]?.pdfDocument?.numPages}
                            </Paragraph>
                        </Flex>
                        <ReferenceDocs localState={localState} />
                    </Flex>
                </Flex>
            </Flex>
        );
    }

    const allDone = isAllDone(localState);

    return (
        <Flex
            sx={{
                mb: 50,
                backgroundColor: 'white',
                padding: [20, 20, 30],
                borderRadius: 10,
                maxWidth: 1400,
                width: ['95%', '95%', '90%'],
                overflowX: ['scroll', 'scroll', 'unset'],
            }}
        >
            {localState.loading && <Spinner />}

            {state.showBackConfirmation && (
                <ConfirmDialog
                    updateState={updateState}
                    state={state}
                    stateKey="showBackConfirmation"
                    text="Are you sure you wish to exit this Document? Make sure your changes have been saved."
                />
            )}

            {state.showFinaliseConfirmation && (
                <ConfirmDialog
                    updateState={updateState}
                    state={state}
                    stateKey="showFinaliseConfirmation"
                    height="300px"
                    text="Would you like to finalise this document now we have all the signatures required?"
                />
            )}

            {state.showAllDoneForYouDialog && (
                <ConfirmDialog
                    updateState={updateState}
                    state={state}
                    stateKey="showAllDoneForYouDialog"
                    height="300px"
                    text="Congratulations, you're all done for now, we're now going to collect the other signatures."
                    showOkOnly
                />
            )}

            {state.showIncompleteConfirmation && (
                <ConfirmDialog
                    updateState={updateState}
                    state={state}
                    showOkOnly
                    stateKey="showIncompleteConfirmation"
                    text="Please make sure you have signed everything and completed all the text fields required"
                />
            )}

            {localState.showSignatureSetupModal && (
                <Overlay
                    copy={{}}
                    embeddedComponent={<SignatureSetupModal state={localState} updateState={updateLocalState} />}
                    updateOverlay={() => updateLocalState({ ...localState, showSignatureSetupModal: false })}
                />
            )}

            <Flex sx={{ flexDirection: ['column', 'column', 'row'] }}>
                <Flex sx={{ flexDirection: 'column', ml: 0 }}>
                    <Flex sx={{ mb: 20, flexDirection: ['column', 'column', 'row'] }}>
                        <Flex>
                            <Button sx={{ width: 100 }} onClick={() => window.location.assign('/home')} variant="light">
                                <i
                                    style={{
                                        fontSize: '12px',
                                        cursor: 'pointer',
                                        marginRight: '5px',
                                    }}
                                    className="fas fa-chevron-left"
                                    aria-hidden="true"
                                />
                                Back
                            </Button>
                            <Button
                                onClick={async () => {
                                    const doneForThisUser = isAllDoneForSpecificUser(localState, state.userId);

                                    updateState({ ...state, showIncompleteConfirmation: !doneForThisUser });

                                    await save(
                                        localState,
                                        updateLocalState,
                                        { ...state, showIncompleteConfirmation: !doneForThisUser },
                                        updateState,
                                        false
                                    );
                                }}
                                sx={{ ml: 10, height: 35, width: 100 }}
                                variant="light"
                            >
                                Save
                            </Button>
                        </Flex>
                        <Flex sx={{ mt: [10, 10, 0] }}>
                            <Button
                                onClick={async () => {
                                    const files = [];

                                    for (const pdf of localState.pdfs) {
                                        files.push(pdf?.document_for_signing_uuid);
                                    }

                                    const response = await axios({
                                        url: `${process.env.REACT_APP_AQRU_AI_API}/documents/portal/download-multiple`,
                                        method: 'POST',
                                        data: { files },
                                        responseType: 'blob',
                                    });

                                    const fileBlob = new Blob([response.data], {
                                        type: `application/${files.length > 1 ? 'zip' : 'pdf'}`,
                                    });

                                    const fileURL = URL.createObjectURL(fileBlob);

                                    const link = document.createElement('a');
                                    link.href = fileURL;
                                    link.download = `${localState.pdfs?.[0]?.name}.${files.length > 1 ? 'zip' : 'pdf'}`;
                                    document.body.appendChild(link);
                                    link.click();

                                    document.body.removeChild(link);
                                    URL.revokeObjectURL(fileURL);

                                    return;
                                }}
                                sx={{ ml: [0, 0, 10], height: 35, width: 180 }}
                                variant="light"
                            >
                                Download PDF{localState.pdfs?.length > 1 ? 's' : ''}
                            </Button>
                            {allDone && (
                                <Button
                                    onClick={async () => {
                                        allDone && (await save(localState, updateLocalState, state, updateState, true));
                                    }}
                                    sx={{
                                        ml: 10,
                                        fontSize: 13,
                                        height: 35,
                                        width: 180,
                                        ...(allDone ? {} : { backgroundColor: '#CCC' }),
                                    }}
                                >
                                    Save and Finish
                                </Button>
                            )}
                        </Flex>
                    </Flex>

                    <Paragraph sx={{ mb: 20, fontWeight: 400, fontSize: 25, mt: 20 }}>
                        {localState.pdfs?.[localState.pdfIndex]?.name}
                    </Paragraph>

                    {localState.pdfs?.[localState.pdfIndex]?.description && (
                        <Paragraph
                            dangerouslySetInnerHTML={{
                                __html: localState.pdfs?.[localState.pdfIndex]?.description?.replace(/\n/g, '<br />'),
                            }}
                            sx={{ mb: 20, width: 600, fontWeight: 400, mt: -10, fontSize: 18 }}
                        />
                    )}
                    <Paragraph sx={{ mt: 30, mb: 10, fontSize: 20 }}>Please follow the steps below:</Paragraph>

                    <Divider />

                    <Paragraph sx={{ mb: 20, fontWeight: 400, mt: 10 }}>1. Setup Signature</Paragraph>
                    {localState.signatureBase64 && (
                        <Flex
                            sx={{
                                width: 250,
                                height: 60,
                                padding: 20,
                                backgroundColor: 'white',
                                alignItems: 'center',
                                mb: 30,
                            }}
                        >
                            <Image
                                sx={{ width: signatureWidth, height: signatureHeight }}
                                src={`${localState.signatureBase64}`}
                            />
                        </Flex>
                    )}
                    <Button
                        onClick={() => updateLocalState({ ...localState, showSignatureSetupModal: true })}
                        sx={{ fontSize: 14, width: 150, mb: 20, height: 40 }}
                    >
                        Edit
                    </Button>

                    <Divider />

                    <Paragraph sx={{ width: [280, 500], mb: 20, mt: 20 }}>
                        2. Read the document and check you are happy with the information provided.{' '}
                        {localState.reference_docs?.length
                            ? 'Please note if there are reference documents at the bottom of the page please review before signing.'
                            : ''}
                    </Paragraph>

                    <Divider />

                    <Paragraph sx={{ mb: 0, fontWeight: 400, mt: 20, width: [280, 400] }}>
                        3. This is where you need to sign in the document:
                    </Paragraph>
                    {localState.configSigError ? (
                        <Paragraph sx={{ mb: 0, fontWeight: 600, mt: 20, color: 'red' }}>
                            {localState.configSigError}
                        </Paragraph>
                    ) : null}

                    {localState.pdfs?.map((pdf, pdfIdx) => {
                        let signaturesRequiredInThisDoc = [];
                        let textInputsRequiredInThisDoc = [];

                        const username = localState.organisation?.user_id;

                        pdf?.signatureLocations?.forEach((item) => {
                            if (item.clientUserId === username) {
                                if (item.type === 'signature') {
                                    signaturesRequiredInThisDoc.push(item);
                                }
                                if (item.type === 'textInput') {
                                    textInputsRequiredInThisDoc.push(item);
                                }
                            }
                        });

                        const requiredToSign = [
                            ...signaturesRequiredInThisDoc.map((x) => ({ ...x, signableType: 'signature' })),
                            ...textInputsRequiredInThisDoc.map((x) => ({ ...x, signableType: 'textInput' })),
                        ].sort((a, b) => a.page - b.page);

                        const archivedRecord = localState?.archive_information?.find((x) => x.pdfIndex === pdfIdx);

                        return (
                            <Flex
                                key={`sig_locations_doc_${pdfIdx}`}
                                sx={{ mt: pdfIdx === 0 ? 50 : 0, mb: 20, flexDirection: 'column' }}
                            >
                                {requiredToSign?.length ? (
                                    <Paragraph sx={{ fontSize: 20 }}>Document {pdfIdx + 1}</Paragraph>
                                ) : null}

                                {archivedRecord ? (
                                    <Flex sx={{ flexDirection: 'column' }}>
                                        <Paragraph sx={{ mt: 20 }}>This document has been archived</Paragraph>
                                        <Paragraph sx={{ mt: 10, width: 400 }}>{archivedRecord.reason || ''}</Paragraph>
                                    </Flex>
                                ) : null}

                                {!archivedRecord ? (
                                    <Flex
                                        sx={{
                                            flexDirection: 'column',
                                            backgroundColor: 'white',
                                            border: '1px solid lightGrey',
                                            borderRadius: 10,
                                            mt: 20,
                                            padding: [15, 20],
                                        }}
                                    >
                                        {requiredToSign?.length
                                            ? requiredToSign?.map((x, idx) => (
                                                  <Flex key={`sig_${idx}`} sx={{ flexDirection: 'column' }}>
                                                      <Flex
                                                          sx={{
                                                              alignItems: 'center',
                                                              width: [350, 500],

                                                              flexDirection: 'row',
                                                              justifyContent: 'space-between',
                                                          }}
                                                      >
                                                          <Flex sx={{ flexDirection: 'column' }}>
                                                              <Flex
                                                                  sx={{
                                                                      alignItems: [
                                                                          'flex-start',
                                                                          'flex-start',
                                                                          'flex-start',
                                                                      ],
                                                                      flexDirection: ['column', 'column', 'column'],
                                                                  }}
                                                              >
                                                                  {idx === 0 ||
                                                                  requiredToSign?.[idx].page !==
                                                                      requiredToSign?.[idx - 1]?.page ? (
                                                                      <>
                                                                          <Flex sx={{ mt: idx === 0 ? 0 : 40 }}>
                                                                              <Paragraph sx={{ width: 100 }}>
                                                                                  Page {x.page}
                                                                              </Paragraph>

                                                                              {pdf?.userSignatureLocations[
                                                                                  state.userId
                                                                              ]?.filter(
                                                                                  (item) =>
                                                                                      item.clientUserId ===
                                                                                          state.userId &&
                                                                                      item.page === x.page
                                                                              )?.length ===
                                                                              pdf?.signatureLocations?.filter(
                                                                                  (item) =>
                                                                                      item.clientUserId ===
                                                                                          state.userId &&
                                                                                      item.page === x.page &&
                                                                                      item.isCompanySignature !== 'true'
                                                                              )?.length ? (
                                                                                  <i
                                                                                      style={{
                                                                                          marginTop: '0px',
                                                                                          fontSize: '18px',
                                                                                          cursor: 'pointer',
                                                                                          color: 'green',
                                                                                          marginLeft: '0px',
                                                                                          marginRight: '20px',
                                                                                      }}
                                                                                      className={'far fa-check'}
                                                                                  />
                                                                              ) : (
                                                                                  <i
                                                                                      style={{
                                                                                          marginTop: '0px',
                                                                                          fontSize: '18px',
                                                                                          cursor: 'pointer',
                                                                                          color: '#ccc',
                                                                                          marginLeft: '0px',
                                                                                          marginRight: '20px',
                                                                                      }}
                                                                                      className={'far fa-circle'}
                                                                                  />
                                                                              )}
                                                                          </Flex>
                                                                          <Divider width="500px" />
                                                                      </>
                                                                  ) : null}

                                                                  {idx === 0 ||
                                                                  requiredToSign?.[idx].page !==
                                                                      requiredToSign?.[idx - 1]?.page ? (
                                                                      <Flex sx={{ mt: 10 }}>
                                                                          <Button
                                                                              variant="light"
                                                                              onClick={async () => {
                                                                                  updateLocalState({
                                                                                      ...localState,
                                                                                      page: x.page,
                                                                                      pdfIndex: pdfIdx,
                                                                                  });
                                                                              }}
                                                                              sx={{ width: 100, height: 35 }}
                                                                          >
                                                                              Go
                                                                          </Button>
                                                                          <Button
                                                                              variant="light"
                                                                              onClick={async () => {
                                                                                  document
                                                                                      .querySelectorAll(
                                                                                          'div.draggable-svg'
                                                                                      )
                                                                                      .forEach((div) => {
                                                                                          div.remove();
                                                                                      });
                                                                                  document
                                                                                      .querySelectorAll(
                                                                                          'img.draggable-svg'
                                                                                      )
                                                                                      .forEach((div) => {
                                                                                          div.remove();
                                                                                      });
                                                                                  document
                                                                                      .querySelectorAll(
                                                                                          'div.resize-handle'
                                                                                      )
                                                                                      .forEach((div) => {
                                                                                          div.remove();
                                                                                      });

                                                                                  const username =
                                                                                      localState.organisation?.user_id;

                                                                                  const newLocalState = {
                                                                                      ...localState,
                                                                                  };

                                                                                  newLocalState.pdfs[
                                                                                      pdfIdx
                                                                                  ].userSignatureLocations = {
                                                                                      ...(newLocalState.pdfs[pdfIdx]
                                                                                          .userSignatureLocations ||
                                                                                          {}),
                                                                                      [username]: newLocalState.pdfs[
                                                                                          pdfIdx
                                                                                      ].userSignatureLocations?.[
                                                                                          username
                                                                                      ]?.filter(
                                                                                          (sig) => sig.page !== x.page
                                                                                      ),
                                                                                  };
                                                                                  newLocalState.pdfs[
                                                                                      pdfIdx
                                                                                  ].signatureLocations =
                                                                                      newLocalState.pdfs[
                                                                                          pdfIdx
                                                                                      ].signatureLocations.map(
                                                                                          (item) => {
                                                                                              if (
                                                                                                  item.clientUserId ===
                                                                                                      state.userId &&
                                                                                                  item.page ===
                                                                                                      x.page &&
                                                                                                  item.type ===
                                                                                                      'textInput'
                                                                                              ) {
                                                                                                  item.userInput = '';
                                                                                              }
                                                                                              return item;
                                                                                          }
                                                                                      );

                                                                                  updateLocalState(newLocalState);

                                                                                  await renderPage(
                                                                                      newLocalState.pdfs?.[
                                                                                          localState.pdfIndex
                                                                                      ]?.pdfDocument,
                                                                                      x.page,
                                                                                      newLocalState,
                                                                                      updateLocalState
                                                                                  );
                                                                              }}
                                                                              sx={{ width: 100, height: 35, ml: 10 }}
                                                                          >
                                                                              Clear
                                                                          </Button>
                                                                          {requiredToSign.find(
                                                                              (_x) =>
                                                                                  _x.page === x.page &&
                                                                                  _x.signableType === 'signature'
                                                                          ) ? (
                                                                              <Button
                                                                                  onClick={async () => {
                                                                                      if (!localState.signatureBase64) {
                                                                                          setTimeout(() => {
                                                                                              updateLocalState({
                                                                                                  ...localState,
                                                                                                  configSigError: null,
                                                                                              });
                                                                                          }, 1500);
                                                                                          return updateLocalState({
                                                                                              ...localState,
                                                                                              configSigError:
                                                                                                  'Please setup a signature',
                                                                                          });
                                                                                      }

                                                                                      const username =
                                                                                          localState.organisation
                                                                                              ?.user_id;

                                                                                      const userSignatureLocations =
                                                                                          localState.pdfs?.[pdfIdx]
                                                                                              ?.userSignatureLocations?.[
                                                                                              username
                                                                                          ] || [];

                                                                                      const signaturesOnThisPageToBeAddedIn =
                                                                                          localState.pdfs?.[
                                                                                              pdfIdx
                                                                                          ].signatureLocations
                                                                                              ?.filter(
                                                                                                  (x) =>
                                                                                                      x.clientUserId ===
                                                                                                      username
                                                                                              )
                                                                                              ?.filter(
                                                                                                  (item) =>
                                                                                                      item.page ===
                                                                                                          x.page &&
                                                                                                      (item.type !==
                                                                                                          'textInput' ||
                                                                                                          (item.type ===
                                                                                                              'textInput' &&
                                                                                                              item.userInput))
                                                                                              );

                                                                                      const signaturesNotOnThisPageToBeAddedIn =
                                                                                          userSignatureLocations?.filter(
                                                                                              (item) =>
                                                                                                  x.clientUserId ===
                                                                                                      username &&
                                                                                                  item.page !== x.page
                                                                                          );

                                                                                      localState.pdfs[
                                                                                          pdfIdx
                                                                                      ].userSignatureLocations[
                                                                                          username
                                                                                      ] = [
                                                                                          ...signaturesNotOnThisPageToBeAddedIn,
                                                                                          ...signaturesOnThisPageToBeAddedIn.map(
                                                                                              (x) => ({
                                                                                                  ...x,
                                                                                                  timestamp:
                                                                                                      moment().format(
                                                                                                          'YYYY-MM-DDTHH:mm:ssZ'
                                                                                                      ),
                                                                                                  uuid: uuid.v4(),
                                                                                              })
                                                                                          ),
                                                                                      ];

                                                                                      updateLocalState(localState);

                                                                                      await renderPage(
                                                                                          localState.pdfs?.[
                                                                                              localState.pdfIndex
                                                                                          ]?.pdfDocument,
                                                                                          x.page,
                                                                                          localState,
                                                                                          updateLocalState
                                                                                      );

                                                                                      await save(
                                                                                          localState,
                                                                                          updateLocalState,
                                                                                          state,
                                                                                          updateState,
                                                                                          false
                                                                                      );
                                                                                  }}
                                                                                  sx={{
                                                                                      ml: 10,
                                                                                      mt: '1px',
                                                                                      width: 100,
                                                                                      height: 35,
                                                                                      fontSize: 14,
                                                                                  }}
                                                                              >
                                                                                  Sign
                                                                              </Button>
                                                                          ) : null}
                                                                      </Flex>
                                                                  ) : null}
                                                              </Flex>
                                                          </Flex>
                                                      </Flex>
                                                      {x.signableType === 'textInput' && (
                                                          <Flex sx={{ mt: 0 }} key={`ti_${pdfIdx}_${idx}_${x}`}>
                                                              <TextInputOnDoc
                                                                  ti={x}
                                                                  localState={localState}
                                                                  updateLocalState={updateLocalState}
                                                                  pdfIdx={pdfIdx}
                                                                  state={state}
                                                                  updateState={updateState}
                                                              />
                                                          </Flex>
                                                      )}
                                                  </Flex>
                                              ))
                                            : null}
                                    </Flex>
                                ) : null}
                            </Flex>
                        );
                    })}

                    <Paragraph sx={{ mb: 0, fontWeight: 400, mt: 40, fontSize: 20 }}>Signatures required</Paragraph>

                    {localState.relations?.map((item, idx) => {
                        const doneForThisUser = isAllDoneForSpecificUser(localState, item.user_id);

                        return (
                            <Flex key={`idx_cus_${idx}`} sx={{ flexDirection: 'column', width: [300, 400] }}>
                                {idx === 0 ? <Divider /> : null}

                                <Flex sx={{ justifyContent: 'space-between' }}>
                                    <Flex sx={{ fontSize: 14 }}>{`${item.first_name} ${item.last_name}`}</Flex>

                                    <i
                                        style={{
                                            marginTop: '5px',
                                            fontSize: '18px',
                                            cursor: 'pointer',
                                            color: doneForThisUser ? 'green' : 'lightGrey',
                                        }}
                                        className={`far ${doneForThisUser ? 'fa-check' : 'fa-circle'} `}
                                    />
                                </Flex>
                                <Divider />
                            </Flex>
                        );
                    })}
                </Flex>

                <Flex sx={{ flexDirection: 'column', ml: ['unset', 'unset', 80], mt: [0, 0, 30] }}>
                    {localState.error && (
                        <Paragraph sx={{ fontWeight: 600, mb: 20, color: 'red' }}>{localState.error}</Paragraph>
                    )}

                    <Flex
                        sx={{
                            borderBottom: '1px solid lightgray',
                            width: localState.pdfs?.[localState.pdfIndex]?.pdfPageWidth || '500px',
                        }}
                    >
                        {localState.pdfs?.map((x, pdfIndex) => (
                            <Flex
                                key={`tab_${pdfIndex}`}
                                sx={{
                                    mb: '0',
                                    pb: '0',
                                    height: '60px',
                                    borderRadius: '10px',
                                    border: '1px solid lightgray',
                                    borderBottom: '0px solid lightgray',
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 0,
                                    ml: '5px',
                                    mt: '5px',
                                    mr: '5px',
                                    width: 200,
                                }}
                            >
                                <Flex
                                    sx={{
                                        flex: 1,
                                        textAlign: 'center',
                                        alignSelf: 'center',
                                        cursor: 'pointer',
                                        height: 60,
                                        width: 200,
                                    }}
                                    onClick={() => updateLocalState({ ...localState, page: 1, pdfIndex: pdfIndex })}
                                >
                                    <Paragraph
                                        sx={{
                                            textAlign: 'center',
                                            width: '100%',
                                            fontSize: '15px',
                                            alignSelf: 'center',
                                            ml: 10,
                                            fontWeight: localState.pdfIndex === pdfIndex ? '600' : '400',
                                        }}
                                    >
                                        Document {pdfIndex + 1}{' '}
                                        {isAllDoneForSpecificUserForSpecificDoc(localState, state.userId, pdfIndex) ? (
                                            <i
                                                style={{
                                                    marginTop: '0px',
                                                    fontSize: '18px',
                                                    cursor: 'pointer',
                                                    color: 'green',
                                                    marginLeft: '10px',
                                                }}
                                                className={'far fa-check'}
                                            />
                                        ) : (
                                            <i
                                                style={{
                                                    marginTop: '0px',
                                                    fontSize: '18px',
                                                    cursor: 'pointer',
                                                    color: '#efefef',
                                                    marginLeft: '10px',
                                                }}
                                                className={'far fa-circle'}
                                            />
                                        )}
                                    </Paragraph>
                                </Flex>
                            </Flex>
                        ))}
                    </Flex>

                    <Box
                        sx={{
                            padding: 10,
                            border: '1px solid lightGrey',
                            position: 'relative',
                            width: localState.pdfs?.[localState.pdfIndex]?.pdfPageWidth + 2 || '500px',
                            height: localState.pdfs?.[localState.pdfIndex]?.pdfPageHeight + 2 || '1px',
                            mt: 10,
                            cursor: 'grab',
                        }}
                        id={`canvas-wrapper-${localState.pdfIndex}`}
                        onMouseDown={(event) => {
                            const archivedRecord = localState?.archive_information?.find(
                                (x) => x.pdfIndex === localState.pdfIndex
                            );
                            if (archivedRecord) return;

                            let selectedElement = null;
                            let offset = { x: 0, y: 0 };
                            let isDragging = false;
                            if (event.target.classList.contains('draggable-svg')) {
                                selectedElement = event.target;
                            } else if (event.target.parentNode.classList.contains('draggable-svg')) {
                                selectedElement = event.target.parentNode;
                            } else if (event.target.parentNode.parentNode.classList.contains('draggable-svg')) {
                                selectedElement = event.target.parentNode.parentNode;
                            }

                            if (event.target.classList.contains('resize-handle')) {
                                const rect = event.target.getBoundingClientRect();
                                offset.x = event.clientX - rect.left;
                                offset.y = event.clientY - rect.top;

                                lastX = event.clientX;
                                lastY = event.clientY;
                                lastWidth = event.target.offsetWidth;
                                originalRatio = event.target.offsetHeight / event.target.offsetWidth;

                                return updateLocalState({
                                    ...localState,
                                    selectedElement: event.target,
                                    lastX: event.clientX,
                                    lastY: event.clientY,
                                    offset,
                                    isDragging: false,
                                    isResizing: true,
                                });
                            }

                            if (selectedElement) {
                                const matchingUuid = selectedElement.id;
                                selectedElement = selectedElement.parentNode;
                                const signedObject = localState.pdfs?.[localState.pdfIndex]?.userSignatureLocations[
                                    state.userId
                                ]?.find((x) => {
                                    return x.uuid === matchingUuid;
                                });

                                if (!signedObject) {
                                    return;
                                }

                                const rect = selectedElement.getBoundingClientRect();
                                offset.x = event.clientX - rect.left;
                                offset.y = event.clientY - rect.top;

                                isDragging = true;

                                updateLocalState({
                                    ...localState,
                                    selectedElement,
                                    offset,
                                    isDragging,
                                });
                            }
                        }}
                        onDragOver={(e) => {
                            e.preventDefault();
                        }}
                        onMouseMove={(event) => {
                            if (localState.isResizing && localState.selectedElement && localState.offset) {
                                const containerRect = document
                                    .getElementById(`canvas-wrapper-${localState.pdfIndex}`)
                                    ?.getBoundingClientRect();

                                if (!containerRect) return;

                                const dx = event.clientX - lastX;

                                const newWidth = lastWidth + dx;

                                const newHeight = 0.23 * newWidth;

                                localState.selectedElement.style.width = `${Math.max(10, newWidth)}px`;
                                localState.selectedElement.style.height = `${Math.max(10, newHeight)}px`;
                                localState.selectedElement.style.fontSize = `${newWidth * 0.1}px`;

                                document.getElementById(
                                    localState.selectedElement.id.replace('resize_', '')
                                ).style.width = `${newWidth}px`;

                                document.getElementById(
                                    localState.selectedElement.id.replace('resize_', '')
                                ).style.height = `${newHeight}px`;

                                lastX = event.clientX;
                                lastY = event.clientY;
                                lastWidth = newWidth;

                                let newFontSize = newWidth * 0.1;

                                if (localState.selectedElement.dataset.type === 'textInput') {
                                    const textComponent = document.getElementById(
                                        `${localState.selectedElement.id.replace('resize_', '')}_text`
                                    );
                                    if (textComponent) {
                                        const { fontSize } = resizeFont(textComponent, newWidth, newHeight);
                                        newFontSize = fontSize;
                                    }
                                }

                                updateLocalState({
                                    ...localState,
                                    lastX: event.clientX,
                                    lastY: event.clientY,
                                    newFontSize,
                                });
                            }

                            if (localState.isDragging && localState.selectedElement && localState.offset) {
                                const containerRect = document
                                    .getElementById(`canvas-wrapper-${localState.pdfIndex}`)
                                    ?.getBoundingClientRect();

                                if (!containerRect) return;

                                const newLeft = event.clientX - containerRect.left - localState.offset.x;
                                const newTop = event.clientY - containerRect.top - localState.offset.y;

                                localState.selectedElement.style.left = `${newLeft}px`;
                                localState.selectedElement.style.top = `${newTop}px`;

                                updateLocalState({
                                    ...localState,
                                });
                            }
                        }}
                        onMouseUp={(event) => {
                            const containerRect = document
                                .getElementById(`canvas-wrapper-${localState.pdfIndex}`)
                                ?.getBoundingClientRect();

                            if (!containerRect || (!localState.isDragging && !localState.isResizing)) return;

                            if (localState.isDragging) {
                                const newLeft = event.clientX - containerRect.left - localState.offset.x;
                                const newTop = event.clientY - containerRect.top - localState.offset.y;

                                if (!localState.pdfs[localState.pdfIndex].userSignatureLocations[state.userId]) {
                                    localState.pdfs[localState.pdfIndex].userSignatureLocations[state.userId] = [];
                                }

                                localState.pdfs[localState.pdfIndex].userSignatureLocations[state.userId] =
                                    localState.pdfs[localState.pdfIndex].userSignatureLocations[state.userId].map(
                                        (item) => {
                                            if (item.uuid === localState.selectedElement.id.replace('resize_', '')) {
                                                item.x = newLeft;
                                                item.y = newTop;
                                            }

                                            return item;
                                        }
                                    );
                            }

                            if (localState.isResizing) {
                                if (!localState.pdfs[localState.pdfIndex].userSignatureLocations[state.userId]) {
                                    localState.pdfs[localState.pdfIndex].userSignatureLocations[state.userId] = [];
                                }

                                localState.pdfs[localState.pdfIndex].userSignatureLocations[state.userId] =
                                    localState.pdfs[localState.pdfIndex].userSignatureLocations[state.userId].map(
                                        (item) => {
                                            if (item.uuid === localState.selectedElement.id.replace('resize_', '')) {
                                                item.svgText = item.svgText.replace(
                                                    /width="\d+"/g,
                                                    `width="${parseFloat(
                                                        localState.selectedElement.style.width.replace('px', '')
                                                    )}"`
                                                );
                                                item.svgText = item.svgText.replace(
                                                    /height="\d+"/g,
                                                    `height="${parseFloat(
                                                        localState.selectedElement.style.height.replace('px', '')
                                                    )}"`
                                                );
                                                item.newFontSize = localState.newFontSize;
                                                item.finalWidth = parseFloat(
                                                    localState.selectedElement.style.width.replace('px', '')
                                                );
                                                item.finalHeight = parseFloat(
                                                    localState.selectedElement.style.height.replace('px', '')
                                                );
                                            }

                                            return item;
                                        }
                                    );
                            }

                            updateLocalState({
                                ...localState,
                                isDragging: false,
                                isResizing: false,
                                selectedElement: null,
                                offset: null,
                                lastX: null,
                                lastY: null,
                                newFontSize: null,
                            });
                        }}
                        onMouseLeave={() => {
                            updateLocalState({
                                ...localState,
                                isDragging: false,
                                selectedElement: null,
                                isResizing: false,
                                offset: null,
                                lastX: null,
                                lastY: null,
                                newFontSize: null,
                            });
                        }}
                    >
                        <canvas
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: localState.pdfs?.[localState.pdfIndex]?.pdfPageWidth || '500px',
                                height: localState.pdfs?.[localState.pdfIndex]?.pdfPageHeight || '1px',
                            }}
                            id="pdf-canvas"
                            onDragOver={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </Box>
                    <Flex sx={{ flexDirection: 'column' }}>
                        <Flex sx={{ alignSelf: 'center', mt: 40 }}>
                            <Button
                                disabled={localState.page === 1}
                                onClick={async () => {
                                    updateLocalState({ ...localState, page: 1 });
                                }}
                                sx={{ width: 60, fontSize: [13, 15], opacity: localState.page === 1 ? 0.5 : 1 }}
                            >
                                <i
                                    style={{
                                        fontSize: '15px',
                                        cursor: 'pointer',
                                        color: 'white',
                                    }}
                                    className="fas fa-fast-backward"
                                    aria-hidden="true"
                                />
                            </Button>
                            <Button
                                disabled={localState.page === 1}
                                onClick={async () => {
                                    const page = Math.max(localState.page - 1, 1);
                                    updateLocalState({ ...localState, page });
                                }}
                                sx={{
                                    width: 60,
                                    fontSize: [13, 15],
                                    ml: 20,
                                    opacity: localState.page === 1 ? 0.5 : 1,
                                }}
                            >
                                <i
                                    style={{
                                        fontSize: '15px',
                                        cursor: 'pointer',
                                        color: 'white',
                                    }}
                                    className="fas fa-step-backward"
                                    aria-hidden="true"
                                />
                            </Button>
                            <Button
                                disabled={
                                    localState.page >= localState.pdfs?.[localState.pdfIndex]?.pdfDocument?.numPages
                                }
                                onClick={async () => {
                                    const page = Math.min(
                                        localState.page + 1,
                                        localState.pdfs?.[localState.pdfIndex]?.pdfDocument?.numPages
                                    );
                                    updateLocalState({ ...localState, page });
                                }}
                                sx={{
                                    width: 60,
                                    fontSize: [13, 15],
                                    ml: 20,
                                    opacity:
                                        localState.page >= localState.pdfs?.[localState.pdfIndex]?.pdfDocument?.numPages
                                            ? 0.5
                                            : 1,
                                }}
                            >
                                <i
                                    style={{
                                        fontSize: '15px',
                                        cursor: 'pointer',
                                        color: 'white',
                                    }}
                                    className="fas fa-step-forward"
                                    aria-hidden="true"
                                />
                            </Button>
                            <Button
                                disabled={
                                    localState.page >= localState.pdfs?.[localState.pdfIndex]?.pdfDocument?.numPages
                                }
                                onClick={async () => {
                                    updateLocalState({
                                        ...localState,
                                        page: localState.pdfs?.[localState.pdfIndex]?.pdfDocument?.numPages,
                                    });
                                }}
                                sx={{
                                    width: 60,
                                    fontSize: [13, 15],
                                    ml: 20,
                                    opacity:
                                        localState.page >= localState.pdfs?.[localState.pdfIndex]?.pdfDocument?.numPages
                                            ? 0.5
                                            : 1,
                                }}
                            >
                                <i
                                    style={{
                                        fontSize: '15px',
                                        cursor: 'pointer',
                                        color: 'white',
                                    }}
                                    className="fas fa-fast-forward"
                                    aria-hidden="true"
                                />
                            </Button>
                        </Flex>
                        <Flex sx={{ alignSelf: 'center', mt: 20 }}>
                            <Paragraph sx={{ textAlign: 'center', mr: 10 }}>
                                Page {localState.page} of{' '}
                                {localState.pdfs?.[localState.pdfIndex]?.pdfDocument?.numPages}
                            </Paragraph>
                        </Flex>
                        <ReferenceDocs localState={localState} />
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

let lastX;
let lastY;
let lastWidth;
let originalRatio;

const TextInputOnDoc = ({ ti, localState, updateLocalState, pdfIdx, state, updateState }) => {
    // date_with_slashes
    const isDateType = ['date', 'date_with_slashes'].includes(ti.typeOfTextEntry);
    let outputDateFormat;
    if (isDateType && ti.typeOfTextEntry === 'date') {
        outputDateFormat = 'DD-MM-YYYY';
    }
    if (isDateType && ti.typeOfTextEntry === 'date_with_slashes') {
        outputDateFormat = 'DD/MM/YYYY';
    }

    return (
        <Flex sx={{ width: '500px', flexDirection: 'column' }} key={`sig_text_input_${ti}`}>
            <Label sx={{ mt: 15, mb: '10px' }}>{ti.label || ''}</Label>
            <Flex sx={{ flexDirection: ['column', 'column', 'row'] }}>
                <Input
                    value={ti.userInput || ''}
                    placeholder={`${ti.label} ${isDateType ? outputDateFormat : ''}`}
                    type={isDateType ? 'date' : 'text'}
                    onChange={(e) => {
                        const username = localState.organisation?.user_id;

                        const userSignatureLocations =
                            localState.pdfs?.[pdfIdx]?.userSignatureLocations?.[username] || [];

                        const textInputRecord = userSignatureLocations?.find((item) => ti.uuid === item.uuid);

                        if (textInputRecord) {
                            textInputRecord.userInput = e.target.value;
                        }

                        updateLocalState({
                            ...localState,
                            signatureLocations: localState.pdfs?.[pdfIdx]?.signatureLocations.map((item) => {
                                if (item.uuid === ti.uuid) {
                                    item.userInput = e.target.value;
                                }
                                return item;
                            }),
                        });
                    }}
                    sx={{ width: '300px', border: ti.userInput ? '1px solid #efefef' : '2px solid red' }}
                />
                <Button
                    onClick={async () => {
                        if (!ti.userInput && !isDateType) return;

                        const username = localState.organisation?.user_id;

                        const userSignatureLocations =
                            localState.pdfs?.[pdfIdx]?.userSignatureLocations?.[username] || [];

                        const textInputRecord = userSignatureLocations?.find((item) => ti.uuid === item.uuid);

                        if (textInputRecord) {
                            textInputRecord.userInput = isDateType
                                ? moment(
                                      textInputRecord.userInput || moment().format('YYYY-MM-DD'),
                                      'YYYY-MM-DD'
                                  ).format(outputDateFormat)
                                : ti.userInput;
                        } else {
                            userSignatureLocations.push({
                                ...ti,
                                userInput:
                                    isDateType && !ti.userInput
                                        ? moment(ti.userInput || moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').format(
                                              outputDateFormat
                                          )
                                        : ti.userInput,
                                timestamp: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
                                uuid: uuid.v4(),
                                newFontSize: ti.newFontSize,
                                type: 'textInput',
                            });
                        }

                        localState.pdfs[pdfIdx].userSignatureLocations[username] = userSignatureLocations;

                        updateLocalState(localState);

                        await renderPage(
                            localState.pdfs?.[localState.pdfIndex]?.pdfDocument,
                            ti.page,
                            localState,
                            updateLocalState
                        );

                        await save(localState, updateLocalState, state, updateState, false);
                    }}
                    sx={{
                        ml: [0, 0, 10],
                        mt: [10, 10, 0],
                        width: 80,
                        height: 40,
                        fontSize: 14,
                    }}
                >
                    Update
                </Button>
            </Flex>
        </Flex>
    );
};

export default ClientSignature;
