export const getContactsFilteredByThoseUsingClientPortal = (clientInView, clientUsers) => {
    const contacts = [];

    clientInView?.client_data?.key_contacts?.forEach((keyContact) => {
        const clientUser = clientUsers?.find((clientUser) => keyContact.email_address === clientUser.email_address);
        if (clientUser) {
            keyContact.user_id = clientUser.user_id;
            contacts.push(keyContact);
        }
    });

    return contacts;
};
