const theme = {
    colors: {
        text: '#000',
        primary: '#4285f4',
        background: '#fff',
        secondary: '#ffffff',
        color3: '#f4f95e', // yellow
        color4: '#7F4BD5',
        fontDark: '#1C1C1C',
        validationError: '#F56132',
        greyBackground: '#E5E5E5',
        darkBackground: '#09042B',
        inputBorder: '#D1D1D1',
        errorLight: '#FFE8E2',
        validationSuccessDark: '#219653',
        validationSuccessLight: '#DDF3E6',
        linkColor: '#5100C9',
        lightGrey: '#F8F8F8',
    },

    select: {
        borderColor: 'inputBorder',
        height: 50,
        borderRadius: 6,
        color: 'fontDark',
        paddingLeft: 15,
        outline: 'none',
    },
    input: {
        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.3)',
        borderRadius: 10,
        border: '1px solid #ccc',
        color: 'text',
    },
    alerts: {
        primary: {
            color: 'background',
            bg: 'primary',
        },
        success: {
            bg: 'validationSuccessLight',
            color: 'fontDark',
            border: '1px solid #219653',
        },
        error: {
            bg: 'errorLight',
            color: 'fontDark',
            border: '1px solid #F56132',
        },
    },
    close: {
        error: {
            color: 'validationError',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        success: {
            color: 'validationSuccessDark',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        '&:hover': {
            cursor: 'pointer',
        },
    },
    fonts: {
        body: '"Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
        heading:
            '"Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
        monospace:
            '"Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
        label: '"Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
        textarea:
            '"Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    },
    fontWeights: {
        body: 400,
        heading: 700,
    },
    styles: {
        spinner: {
            color: 'primary',
        },
        h1: {
            fontSize: 36,
            fontFamily: 'body',
            fontWeight: 'heading',
            color: 'fontDark',
            mt: 4,
            mb: 2,
        },
        h2: {
            fontFamily: 'body',
            fontWeight: 'heading',
            color: 'fontDark',
            lineHeight: '49.18px',
        },

        a: {
            '&:hover': {
                cursor: 'pointer',
            },
            color: 'primary',
            fontFamily: 'body',
        },
        p: {
            fontFamily: 'body',
            color: 'text',
        },
        label: {
            fontFamily: 'body',
            color: 'primary',
        },
        body: {
            backgroundColor: 'greyBackground',
        },
    },
    breakpoints: ['40em', '@media (min-width: 56em) and (orientation: landscape)', '64em'],
    label: {
        fontFamily: 'body',
        fontSize: 14,
        fontWeight: 400,
        color: 'text',
    },
    switch: {
        boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.3)',
    },
    textarea: {
        border: '1px solid lightGrey',
        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.3)',
        borderRadius: 15,
    },
    buttons: {
        primary: {
            boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.3)',
            backgroundColor: 'primary',
            cursor: 'pointer',
            '&:hover': {
                cursor: 'pointer',
                filter: 'brightness(85%)',
            },
            height: 49,
            borderRadius: '30px',
        },
        'primary-wide': {
            backgroundColor: 'primary',
            '&:hover': {
                cursor: 'pointer',
                filter: 'brightness(85%)',
            },
            height: 49,
            width: ['90%', 404],
            marginRight: ['5%', 'unset'],
            marginLeft: ['5%', 'unset'],
            borderRadius: '30px',
        },
        secondary: {
            backgroundColor: 'secondary',
            '&:hover': {
                cursor: 'pointer',
                filter: 'brightness(85%)',
            },
            color: 'white',
            height: 49,
            borderRadius: '30px',
        },
        'secondary-wide': {
            backgroundColor: 'secondary',
            '&:hover': {
                cursor: 'pointer',
                filter: 'brightness(85%)',
            },
            height: 49,
            width: ['90%', 404],
            marginRight: ['5%', 'unset'],
            marginLeft: ['5%', 'unset'],
            borderRadius: '30px',
        },
        light: {
            color: '#000',
            border: '1px solid lightGrey',
            fontSize: 14,
            backgroundColor: 'white',
            boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.3)',
            borderRadius: '30px',
            '&:hover': {
                cursor: 'pointer',
                filter: 'brightness(85%)',
            },
            cursor: 'pointer',
        },
        'disabled-primary': {
            color: 'fontDark',
            backgroundColor: 'greyBackground',
            '&:hover': {
                cursor: 'not-allowed',
                filter: 'brightness(85%)',
            },
            height: 49,
            borderRadius: '30px',
        },
    },
    box: {
        formSplitScreenLeftHandSide: {
            backgroundColor: 'darkBackground',
            width: [0, 0, '35%'],
            display: ['none', 'none', 'flex'],
            justifyContent: 'center',
        },
        formSplitScreenRightHandSide: {
            alignSelf: 'center',
            backgroundColor: 'greyBackground',
            paddingLeft: ['unset', 'unset', 150],
            paddingTop: 40,
        },
    },
};

export default theme;
