/** @jsxImportSource theme-ui */
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { Flex, Box, Image, Paragraph } from 'theme-ui';
import { useNavigate } from 'react-router-dom';
import { actions as menuActions } from '../store/reducers/menu';
import { useEffect, useState } from 'react';

const returnMenuItems = (
    currentPage: any,
    navigate: any,
    closeAllMenus: any,
    accountSummary: any,
    user: any,
    copy: any,
    settingsJourney: any,
    updateSettingsJourney: any,
    enableAll = false,
    modules: any
) => {
    const items = [
        {
            name: 'Home',
            icon: 'fal fa-home',
            url: '/home',
            testId: 'sidebar-home',
        },
        {
            name: 'Clients',
            icon: 'fal fa-building',
            url: '/clients',
            testId: 'sidebar-dashboard',
        },
        {
            name: 'Tasks',
            icon: 'fal fa-list',
            url: '/tasks',
            testId: 'sidebar-tasks',
        },
        {
            name: 'Schedule',
            icon: 'fal fa-calendar',
            url: '/schedule',
            testId: 'sidebar-schedule',
        },
        {
            name: 'Questionnaires',
            icon: 'fal fa-question',
            type: 'questionnaires',
            url: '/questionnaires',
            testId: 'sidebar-questionnaires',
            fontSize: '9px',
        },
        {
            name: 'Chat',
            icon: 'fal fa-comments',
            type: 'chat',
            testId: 'sidebar-chat',
        },
        {
            name: 'Timesheets',
            icon: 'fal fa-clock',
            url: '/timesheets',
            testId: 'sidebar-timesheets',
        },
        {
            name: 'Settings',
            icon: 'fal fa-cog',
            type: 'settings',
            url: '/settings',
            testId: 'sidebar-settings',
        },

        {
            name: 'Sign out',
            icon: 'fal fa-sign-out-alt',
            url: '/',
            logout: true,
            testId: 'sidebar-sign-out',
        },
    ];

    const menuItems = items
        // @ts-ignore
        .filter((item) => enableAll || !item.moduleKey || modules?.[item.moduleKey])
        .map((item, i) => {
            const component = (
                <Flex
                    sx={{
                        width: '85px',
                        ml: '16px',
                        height: '70px',
                        backgroundColor: currentPage === item.name ? '#ffffff' : 'primary',
                        color: currentPage === item.name ? 'primary' : 'white',
                        '&:hover': {
                            backgroundColor: '#ffffff',
                            color: 'primary',
                            fontWeight: '800',
                        },
                        borderRadius: '6px',
                        fontSize: currentPage === item.name ? '12px' : '12px',
                        fontWeight: currentPage === item.name ? '800' : '800',
                        lineHeight: '32px',
                        cursor: 'pointer',
                        mb: '25px',
                        pt: '10px',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    key={`${item.name}`}
                    data-testid={item.testId}
                    onClick={async () => {
                        if (item.logout) {
                            await Auth.signOut();
                            window.location.assign('/');
                        }
                        // @ts-ignore
                        if (item.type === 'chat' && window.toggleChatMini) {
                            // @ts-ignore
                            window.toggleChatMini();
                        }
                    }}
                >
                    <Box
                        sx={{
                            fontSize: '23px',
                            marginRight: 'auto',
                            marginLeft: 'auto',
                            marginTop: '0px',
                        }}
                        className={`${item.icon}`}
                    />
                    <Paragraph
                        sx={{
                            fontWeight: '600',
                            fontSize: item.fontSize || '11px',
                            width: '100%',
                            textAlign: 'center',
                        }}
                    >
                        {item.name?.toUpperCase()}
                    </Paragraph>
                </Flex>
            );

            if (item.logout || item.type === 'chat') {
                return component;
            }

            return (
                // @ts-ignore
                <a style={{ textDecoration: 'none' }} key={`${item.name}`} href={item.url}>
                    {component}
                </a>
            );
        });

    return <Flex sx={{ flexDirection: 'column', mt: '18px' }}>{menuItems}</Flex>;
};

const SidebarComponent = ({
    currentPage,
    showSideMenu,
    logout,
    showTopMenu,
    toggleSideMenu,
    closeAllMenus,
    accountSummary,
    user,
    copy,
    settingsJourney,
    organisation,
    updateSettingsJourney,
}: any) => {
    const navigate = useNavigate();

    const allowAll = organisation?.entity === null;

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        // Listen to window resize events
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Flex data-testid="sidebar" sx={{ position: 'relative' }}>
            <Flex
                sx={{
                    width: '120px',
                    height: '100%',
                    position: 'fixed',
                    top: 0,
                    letf: 0,
                    backgroundColor: 'primary',
                    flexDirection: 'column',
                    '@media screen and (max-width: 1024px)': {
                        left: showSideMenu ? '0' : '-300px',
                    },
                    zIndex: 999,
                }}
            >
                {returnMenuItems(
                    currentPage,
                    navigate,
                    closeAllMenus,
                    accountSummary,
                    user,
                    copy,
                    settingsJourney,
                    updateSettingsJourney,
                    allowAll,
                    organisation?.entity?.modules
                )}

                {windowHeight > 930 ? (
                    <Box
                        sx={{
                            height: '60px',
                            mt: '53px',
                            ml: 'auto',
                            mr: 'auto',
                            mb: '10px',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            position: 'absolute',
                            bottom: '10px',
                            left: '25px',
                        }}
                        onClick={() => navigate('/home')}
                    >
                        <Image
                            src={`https://cdn.accru.finance/af/icon-light.png`}
                            width="60px"
                            height="auto"
                            alt="Home"
                            data-testid="login-logo"
                        />
                    </Box>
                ) : null}
            </Flex>
        </Flex>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    toggleSideMenu: () => dispatch({ type: menuActions.TOGGLE_SIDE_MENU }),
    closeAllMenus: () => dispatch({ type: menuActions.CLOSE_ALL_MENUS }),
});

const mapStateToProps = (state: any) => ({
    showSideMenu: state?.menu?.showSideMenu,
    showTopMenu: state?.menu?.showTopMenu,
    organisation: state?.account?.organisation,
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarComponent);
