/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-undef */
import { useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import { Button, Flex, Input, Paragraph } from 'theme-ui';
import { deleteBoard, loadBoards, saveBoard } from './api';
import ConfirmDialog from './confirm-dialog';

const Boards = ({ state, updateState, refreshCallback, organisation }) => {
    useEffect(() => {
        loadBoards(state, updateState);
    }, []);

    return (
        <Flex>
            {state.showDeleteBoardConfirmation && (
                <ConfirmDialog
                    updateState={updateState}
                    state={state}
                    stateKey="showDeleteBoardConfirmation"
                    text="Are you sure you wish to delete this board?"
                />
            )}

            <Flex sx={{ ml: 30, mt: 30, mb: 80, flexDirection: 'column' }}>
                <Flex sx={{ justifyContent: 'flex-start', mb: 10 }}>
                    <Paragraph
                        sx={{
                            width: '95px',
                            height: '40px',
                            p: '10px 5px',
                            mb: '25px',
                            fontSize: '13px',
                            cursor: 'pointer',
                            border: '1px solid #eaecf0',
                        }}
                        onClick={async () => {
                            updateState({
                                ...state,
                                mode: 'list',
                                fullTaskData: null,
                                boardMode: 'list',
                                task: null,
                                comments: [],
                            });
                        }}
                    >
                        <i
                            style={{
                                marginTop: '0px',
                                fontSize: '12px',
                                marginLeft: '9px',
                                marginRight: '6px',
                            }}
                            className={`fas fa-chevron-left`}
                        />{' '}
                        Back
                    </Paragraph>

                    {state.boardMode === 'list' && (
                        <Button
                            onClick={async () => {
                                updateState({ ...state, boardMode: 'new', boardsValidationError: null });
                            }}
                            sx={{ ml: 20, width: '145px', height: '40px', fontSize: 13, borderRadius: 10 }}
                        >
                            New Task Board
                        </Button>
                    )}
                </Flex>

                {state.boardsError && (
                    <Paragraph sx={{ fontWeight: '400', fontSize: 15, color: 'red', mb: 20 }}>
                        {state.boardsError}
                    </Paragraph>
                )}

                {state.boardMode === 'list' && (
                    <Flex sx={{ flexDirection: 'column', width: 800 }}>
                        <Flex sx={{ mb: 0, borderBottom: '1px solid #CCC', height: '2px', width: '90%' }} />
                        {[{ name: 'Default board (shows all tasks)' }, ...(state.boards || [])].map((board) => (
                            <>
                                <Flex sx={{ justifyContent: 'space-between', mt: 20, width: '100%' }}>
                                    <Paragraph sx={{ width: '73%', fontSize: 14 }}>{board.name}</Paragraph>
                                    <Flex sx={{ width: '27%' }}>
                                        <Paragraph
                                            onClick={() => {
                                                board.id &&
                                                    updateState({
                                                        ...state,
                                                        showDeleteBoardConfirmation: true,
                                                        confirmCallback: deleteBoard(board.id, state, updateState),
                                                    });
                                            }}
                                            sx={{
                                                mt: '3px',
                                                cursor: board.id ? 'pointer' : 'auto',
                                                fontSize: 13,
                                                color: 'red',
                                                opacity: board.id ? 1 : 0,
                                            }}
                                        >
                                            <i
                                                style={{
                                                    marginTop: '0px',
                                                    fontSize: '12px',
                                                    marginLeft: '9px',
                                                    marginRight: '6px',
                                                }}
                                                className={`fas fa-trash`}
                                            />
                                        </Paragraph>
                                        <Paragraph
                                            onClick={() => {
                                                board.id &&
                                                    updateState({
                                                        ...state,
                                                        boardMode: 'update',
                                                        boardName: board.name,
                                                        boardIdBeingUpdated: board.id,
                                                    });
                                            }}
                                            sx={{
                                                mt: '3px',
                                                ml: 10,
                                                cursor: board.id ? 'pointer' : 'auto',
                                                fontSize: 13,
                                                opacity: board.id ? 1 : 0,
                                            }}
                                        >
                                            <i
                                                style={{
                                                    marginTop: '0px',
                                                    fontSize: '12px',
                                                    marginLeft: '9px',
                                                    marginRight: '6px',
                                                }}
                                                className={`fas fa-pencil`}
                                            />
                                        </Paragraph>
                                        <Paragraph
                                            onClick={() => {
                                                const tempState = {
                                                    ...state,
                                                    board_id: board.id,
                                                    mode: 'list',
                                                    boardMode: 'list',
                                                };
                                                updateState(tempState);
                                                refreshCallback(tempState, updateState);
                                            }}
                                            sx={{ mt: '3px', ml: 10, cursor: 'pointer', fontSize: 13, color: 'blue' }}
                                        >
                                            <i
                                                style={{
                                                    marginTop: '0px',
                                                    fontSize: '12px',
                                                    marginLeft: '9px',
                                                    marginRight: '6px',
                                                }}
                                                className={`fas fa-eye`}
                                            />
                                        </Paragraph>
                                    </Flex>
                                </Flex>
                                <Flex sx={{ mt: 20, borderBottom: '1px solid #CCC', height: '2px', width: '90%' }} />
                            </>
                        ))}
                    </Flex>
                )}

                {['new', 'update'].includes(state.boardMode) && (
                    <Flex sx={{ flexDirection: 'column' }}>
                        <Paragraph sx={{ mb: 10 }}>Board name</Paragraph>
                        <Input
                            value={state.boardName || ''}
                            type="text"
                            sx={{ borderRadius: 10, border: '1px solid #ccc', width: 400 }}
                            onChange={(e) => updateState({ ...state, boardName: e.target.value })}
                        />
                        <Flex>
                            <Button
                                sx={{
                                    width: 100,
                                    height: 40,
                                    fontSize: 14,
                                    borderRadius: 10,
                                    mt: 10,

                                    color: 'black',
                                    backgroundColor: 'white',
                                    border: '1px solid #ccc',
                                }}
                                onClick={() =>
                                    updateState({
                                        ...state,
                                        boardMode: 'list',
                                        boardIdBeingUpdated: null,
                                        boardName: null,
                                    })
                                }
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={() => saveBoard(state, updateState)}
                                sx={{ width: 100, ml: 10, height: 40, fontSize: 14, borderRadius: 10, mt: 10 }}
                            >
                                Save
                            </Button>
                        </Flex>
                        {state.boardsValidationError && (
                            <Paragraph sx={{ fontWeight: '400', fontSize: 15, color: 'red', mt: 20 }}>
                                {state.boardsValidationError}
                            </Paragraph>
                        )}
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
};

export default Boards;
