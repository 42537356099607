/** @jsxImportSource theme-ui */
import { Spinner, Flex } from 'theme-ui';

const BLSpinner = ({ customSx = {} }) => (
    <Flex
        className="bl_spinner"
        data-testid="bl_spinner"
        sx={{
            position: 'fixed',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 100000000,
            ...customSx,
        }}
        onClick={(e) => e.stopPropagation()}
    >
        {/* <Spinner sx={{ width: 100, height: 100 }} /> */}
        <div style={{ width: 100, height: 100 }} className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </Flex>
);

export default BLSpinner;
