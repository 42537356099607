/* eslint-disable no-undef */
import { Button, Flex, Paragraph } from 'theme-ui';
import { PgPaginationV2 } from '../documents/paging';
import axios from 'axios';
import Divider from '../divider';

const removeInvite = (id, state, updateState) => async () => {
    try {
        updateState({ ...state, loading: true, error: null });

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations/invites/${id}`,
            method: 'DELETE',
        });

        const {
            data: { invites, total: totalInvites },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/organisations/invites`,
        });

        updateState({ ...state, loading: false, error: null, invites, totalInvites });
    } catch (e) {
        console.log(e);
        updateState({
            ...state,
            loading: false,
            error: 'Uh oh, there has been an error removing this invite from your organisation',
        });
    }
};

const Invites = ({ organisation, updateState, state }) => {
    return (
        <Flex sx={{ flexDirection: 'column', mt: 0 }}>
            <Paragraph sx={{ fontSize: 20, mt: 20, fontWeight: 600 }}>Invites</Paragraph>
            <Divider width="1200px" />

            <Flex sx={{ flexDirection: 'column', mt: 20, fontSize: 14 }}>
                <Flex
                    sx={{
                        width: 580,
                        borderLeft: '1px solid #ccc',
                        borderRight: '1px solid #ccc',
                        borderTop: '1px solid #ccc',
                        borderBottom: '1px solid #ccc',
                        backgroundColor: 'primary',
                        color: 'white',
                    }}
                >
                    <Flex sx={{ padding: 20, wordWrap: 'break-word', width: 500, borderRight: '1px solid #ccc' }}>
                        <Paragraph sx={{ fontWeight: 600 }}>Email address</Paragraph>
                    </Flex>
                    <Flex sx={{ width: 80 }}></Flex>
                </Flex>
                {state?.invites?.map((x, idx) => (
                    <Flex
                        key={x.title}
                        sx={{
                            boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.2)',
                            borderLeft: '1px solid #ccc',
                            borderRight: '1px solid #ccc',
                            borderBottom: idx === state.invites.length - 1 ? '1px solid #ccc' : 'unset',
                            width: 580,
                        }}
                    >
                        <Flex sx={{ padding: 20, wordWrap: 'break-word', width: 500, borderRight: '1px solid #ccc' }}>
                            <Paragraph>{x.email_address}</Paragraph>
                        </Flex>
                        <Flex sx={{ padding: 20, width: 80, justifyContent: 'center' }}>
                            {organisation?.user?.role === 'admin' && (
                                <>
                                    <i
                                        style={{
                                            fontSize: '12px',
                                            cursor: 'pointer',
                                            color: 'red',
                                        }}
                                        className="fas fa-trash"
                                        aria-hidden="true"
                                        onClick={() =>
                                            updateState({
                                                ...state,
                                                showDeleteInviteConfirmation: true,
                                                confirmCallback: removeInvite(x.id, state, updateState),
                                            })
                                        }
                                    />
                                </>
                            )}
                        </Flex>
                    </Flex>
                ))}
                <Flex sx={{ width: 500 }}>
                    <PgPaginationV2
                        currentState={state}
                        updateState={updateState}
                        totalPages={state.totalInvites ? Math.ceil(state.totalInvites / state.invitesLimit) : 1}
                        totalRecords={state.totalInvites}
                        offsetKey="invitesOffset"
                        totalKey="totalInvites"
                        limitKey="invitesLimit"
                    />
                </Flex>
            </Flex>
        </Flex>
    );
};

export default Invites;
