/* eslint-disable no-undef */
/** @jsxImportSource theme-ui */
import axios from 'axios';
import moment from 'moment';
import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ReactSelect from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Box, Button, Flex, Input, Paragraph, Textarea } from 'theme-ui';
import Divider from '../divider';
import Overlay from '../overlay/overlay';
import { generatePdf } from './generate-invoice';
import { loadInvoices } from './invoices';

export const vatOptions = [
    { value: '20', label: '20% Standard Rated', multiplier: 1.2 },
    { value: '0', label: '0% Zero Rated', multiplier: 1 },
    { value: '0_exempt', label: '0% Exempt', multiplier: 1 },
    { value: 'no-vat', label: 'No VAT', multiplier: 1 },
];

const EmailDialog = ({ state, updateState }) => {
    const [localState, updateLocalState] = useState({
        toOptions: state.clientInView.client_data?.key_contacts?.map((x) => ({
            value: x.email_address,
            label: `${x.first_name} ${x.last_name}`,
        })),
        to: [],
        subject: state.clientInView.organisation?.default_visualisation?.invoiceOptions?.defaultSubjectLine
            ?.replace(/{clientName}/g, state.clientInView.name)
            ?.replace(/{dueDate}/g, state.invoice.model?.bank_details?.payable_by)
            ?.replace(/{invoiceNumber}/g, state.invoice.invoice_number)
            ?.replace(/{totalDue}/g, state.invoice.model.totals.total),
        body: state.clientInView.organisation?.default_visualisation?.invoiceOptions?.defaultInvoiceBody
            ?.replace(/{clientName}/g, state.clientInView.name)
            ?.replace(/{dueDate}/g, state.invoice.model?.bank_details?.payable_by)
            ?.replace(/{invoiceNumber}/g, state.invoice.invoice_number)
            ?.replace(/{totalDue}/g, state.invoice.model.totals.total),
    });
    return (
        <Flex sx={{ flexDirection: 'column', m: 15 }}>
            <Paragraph sx={{ fontSize: 18 }}>Email Invoice</Paragraph>
            <Divider />
            <Paragraph sx={{ mb: 10 }}>To</Paragraph>
            <ReactSelect
                styles={{
                    control: (provided) => ({
                        ...provided,
                        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                        width: '480px',
                        minHeight: '40px',
                        borderRadius: 10,
                        marginBottom: '30px',
                        border: localState.emailError ? '1px solid red' : '1px solid #a3a69b',
                        fontSize: '14px',
                    }),
                }}
                onChange={async (c) => {
                    updateLocalState({
                        ...localState,
                        to: c,
                        emailError: null,
                    });
                }}
                isMulti
                placeholder={'Select the contacts to be emailed'}
                value={localState.to}
                options={localState.toOptions}
            />
            <Paragraph sx={{ mb: 10 }}>Subject</Paragraph>
            <Input
                onChange={(e) => updateLocalState({ ...localState, subject: e.target.value })}
                value={localState.subject}
                sx={{ mb: 10 }}
            />
            <Paragraph sx={{ mb: 10 }}>Body</Paragraph>
            <ReactQuill
                theme="snow"
                value={localState.body}
                style={{ height: 300, width: 560 }}
                onChange={(val) =>
                    updateLocalState({
                        ...localState,
                        body: val,
                    })
                }
            />
            <Flex sx={{ mt: 80 }}>
                <Button
                    sx={{ width: 100, height: 40, fontSize: 14 }}
                    onClick={async () => {
                        try {
                            if (!localState.to?.length) {
                                return updateLocalState({
                                    ...localState,
                                    emailError: true,
                                });
                            }
                            updateLocalState({
                                ...localState,
                                error: null,
                            });
                            updateState({ ...state, loading: true });
                            await axios({
                                url: `${process.env.REACT_APP_AQRU_AI_API}/invoices/${state.invoice.uuid}/email`,
                                method: 'POST',
                                data: {
                                    to: localState.to?.map((x) => x.value?.trim().toLowerCase()),
                                    subject: localState.subject,
                                    body: localState.body,
                                },
                            });
                            updateState({ ...state, loading: false, showEmailDialog: false });
                        } catch (e) {
                            updateState({ ...state, loading: false });
                            updateLocalState({
                                ...localState,
                                error: 'We are unable to send your email at this time, please try again later',
                            });
                        }
                    }}
                >
                    Send
                </Button>
                <Button
                    onClick={() => updateState({ ...state, showEmailDialog: false })}
                    variant="light"
                    sx={{ ml: 10, width: 100, height: 40, fontSize: 14 }}
                >
                    Cancel
                </Button>
            </Flex>
            {localState.error && <Paragraph sx={{ mt: 20, color: 'red' }}>{localState.error}</Paragraph>}
        </Flex>
    );
};

const InvoiceOptions = ({ state, updateState, lineItems }) => {
    const [localState, updateLocalState] = useState({
        invoiceNumber: '',
        bankDetails: {
            accountName: state?.organisation?.default_visualisation?.invoiceOptions?.payableTo,
            accountNumber: state?.organisation?.default_visualisation?.invoiceOptions?.accountNumber,
            sortCode: state?.organisation?.default_visualisation?.invoiceOptions?.sortCode,
            payableBy: state.invoice?.model?.payableBy
                ? state.invoice?.model?.payableBy
                : state?.organisation?.default_visualisation?.invoiceOptions?.dueDateDays
                ? moment()
                      .add(state?.organisation?.default_visualisation?.invoiceOptions?.dueDateDays, 'days')
                      .format('YYYY-MM-DD')
                : null,
        },
        autoGenerateInvoiceNumber: true,
        invoiceDate: state.invoice?.model?.invoiceDate || moment().format('YYYY-MM-DD'),
        displayBankDetails: !!state?.organisation?.default_visualisation?.invoiceOptions,
        comment: state.invoice?.model?.comment || '',
    });

    const handleBankDetailsChange = (e) => {
        updateLocalState({
            ...localState,
            bankDetails: { ...localState.bankDetails, [e.target.name]: e.target.value },
        });
    };

    return (
        <Box sx={{ mt: 20 }}>
            {state.showEmailDialog && (
                <Overlay
                    overlay={{ hideNavigation: true }}
                    copy={{}}
                    maxWidth={600}
                    maxHeight={900}
                    embeddedComponent={<EmailDialog state={state} updateState={updateState} />}
                    updateOverlay={() => updateState({ ...state, showEmailDialog: false })}
                />
            )}

            <Box mb={3}>
                <Paragraph sx={{ mb: 10 }}>Add a comment to the invoice</Paragraph>
                <Textarea
                    value={localState.comment}
                    onChange={(e) => updateLocalState({ ...localState, comment: e.target.value })}
                    rows={4}
                    cols={40}
                />
            </Box>
            <Box mb={3}>
                <Paragraph sx={{ mb: 10 }} htmlFor="invoice-date">
                    Invoice Date
                </Paragraph>
                <Input
                    sx={{ height: 50 }}
                    id="invoice-date"
                    type="date"
                    value={localState.invoiceDate}
                    onChange={(e) => updateLocalState({ ...localState, invoiceDate: e.target.value })}
                />
            </Box>

            {/* <Flex
                sx={{ alignItems: 'center' }}
                mb={localState.autoGenerateInvoiceNumber ? 4 : 3}
                onClick={() =>
                    updateLocalState({
                        ...localState,
                        autoGenerateInvoiceNumber: !localState.autoGenerateInvoiceNumber,
                    })
                }
            >
                <Checkbox checked={localState.autoGenerateInvoiceNumber} />
                <Paragraph>Auto generate invoice number</Paragraph>
            </Flex> */}

            {!localState.autoGenerateInvoiceNumber && (
                <Box mb={4}>
                    <Paragraph sx={{ mb: 10 }} htmlFor="invoice-number">
                        Invoice number:
                    </Paragraph>
                    <Input
                        sx={{ height: 50 }}
                        id="invoice-number"
                        name="invoiceNumber"
                        value={localState.invoiceNumber}
                        onChange={(e) =>
                            updateLocalState({
                                ...localState,
                                invoiceNumber: e.target.value,
                            })
                        }
                        placeholder="Invoice number"
                    />
                </Box>
            )}

            {/* <Flex
                sx={{ alignItems: 'center' }}
                mb={3}
                onClick={() =>
                    updateLocalState({
                        ...localState,
                        displayBankDetails: !localState.displayBankDetails,
                    })
                }
            >
                <Checkbox checked={localState.displayBankDetails} />
                <Paragraph>Include Bank Account Details</Paragraph>
            </Flex> */}

            {localState.displayBankDetails && (
                <Box mb={3}>
                    <Box mb={3}>
                        <Paragraph sx={{ mb: 10 }} htmlFor="account-name">
                            Payable to:
                        </Paragraph>
                        <Input
                            sx={{ height: 50, background: '#efefef' }}
                            id="account-name"
                            disabled
                            name="accountName"
                            value={localState.bankDetails.accountName}
                            onChange={handleBankDetailsChange}
                            placeholder="Bank account name"
                        />
                    </Box>

                    <Box mb={3}>
                        <Paragraph sx={{ mb: 10 }} htmlFor="account-number">
                            Account Number:
                        </Paragraph>
                        <Input
                            sx={{ height: 50, background: '#efefef' }}
                            id="account-number"
                            name="accountNumber"
                            disabled
                            value={localState.bankDetails.accountNumber}
                            onChange={handleBankDetailsChange}
                            placeholder="Account number"
                        />
                    </Box>

                    <Box mb={3}>
                        <Paragraph sx={{ mb: 10 }} htmlFor="sort-code">
                            Sort Code:
                        </Paragraph>
                        <Input
                            sx={{ height: 50, background: '#efefef' }}
                            id="sort-code"
                            name="sortCode"
                            disabled
                            value={localState.bankDetails.sortCode}
                            onChange={handleBankDetailsChange}
                            placeholder="Sort code"
                        />
                    </Box>

                    <Box mb={3}>
                        <Paragraph sx={{ mb: 10 }} htmlFor="payable-by">
                            Payable by:
                        </Paragraph>
                        <Input
                            sx={{ height: 50 }}
                            id="payable-by"
                            type="date"
                            name="payableBy"
                            value={localState.bankDetails.payableBy}
                            onChange={handleBankDetailsChange}
                        />
                    </Box>
                </Box>
            )}
            <Flex
                sx={{
                    position: 'fixed',
                    height: 80,
                    background: 'white',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    alignItems: 'center',
                    borderTop: '2px solid #EFEFEF',
                    width: '100%',
                    zIndex: 998,
                }}
            >
                <Flex sx={{ ml: 150, alignItems: 'center' }}>
                    <Button
                        sx={{ width: 200 }}
                        onClick={async () => {
                            await generatePdf(state, updateState, lineItems, localState, state.setInvoices);
                        }}
                    >
                        Generate Invoice <i style={{ marginLeft: '5px' }} className="fa fa-file-pdf"></i>
                    </Button>
                    {state.invoice && (
                        <Button
                            sx={{ ml: 10, width: 200, height: 50 }}
                            variant="light"
                            onClick={async () => {
                                updateState({ ...state, showEmailDialog: true });
                            }}
                        >
                            Email Invoice <i style={{ marginLeft: '5px' }} className="fa fa-envelope"></i>
                        </Button>
                    )}
                    {state.invoice && (
                        <Button
                            sx={{ ml: 10, width: 200, height: 50 }}
                            variant="light"
                            onClick={async () => {
                                updateState({
                                    ...state,
                                    error: null,
                                    showDeleteInvoiceConfirmation: true,
                                    confirmCallback: async () => {
                                        updateState({ ...state, loading: true });

                                        try {
                                            await axios.delete(
                                                `${process.env.REACT_APP_AQRU_AI_API}/invoices/${state.invoice.uuid}`,
                                                {}
                                            );

                                            await loadInvoices(
                                                { ...state, error: null, invoice: null },
                                                updateState,
                                                state.setInvoices
                                            );

                                            toast('Invoice successfully deleted', {
                                                type: 'success',
                                                autoClose: 5000,
                                                position: 'top-right',
                                            });
                                        } catch (e) {
                                            updateState({
                                                ...state,
                                                loading: false,
                                            });
                                            toast('We are currently unable to delete your invoice', {
                                                type: 'error',
                                                autoClose: 5000,
                                                position: 'top-right',
                                            });
                                        }
                                    },
                                });
                            }}
                        >
                            Delete Invoice <i style={{ marginLeft: '5px' }} className="fa fa-trash"></i>
                        </Button>
                    )}
                </Flex>
            </Flex>
            <ToastContainer />
        </Box>
    );
};

export default InvoiceOptions;
