/* eslint-disable no-undef */
import { Button, Flex, Input, Paragraph, Switch } from 'theme-ui';
import * as uuid from 'uuid';
import { useEffect, useState } from 'react';
import Divider from '../divider';
import Overlay from '../overlay/overlay';
import Spinner from '../spinner';
import moment from 'moment';
import axios from 'axios';
import ConfirmDialog from '../tasks/confirm-dialog';

const sortFiles = (files) =>
    files.sort((a, b) => {
        // Both types are undefined, maintain original order
        if (a.type === undefined && b.type === undefined) return 0;

        // A's type is undefined, it should come first
        if (a.type === undefined) return -1;

        // B's type is undefined, it should come first
        if (b.type === undefined) return 1;

        // Both types are 'file', maintain original order
        if (a.type === 'file' && b.type === 'file') return 0;

        // A's type is 'file', it should come after
        if (a.type === 'file') return 1;

        // B's type is 'file', it should come after
        if (b.type === 'file') return -1;

        // If none of the above, maintain original order
        return 0;
    });

const saveFileData = async (state, updateState, clientId, updateClientData, reload = false) => {
    try {
        updateState({ ...state, loading: true, error: null });

        const { data } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${clientId}`,
            method: 'GET',
        });

        const client_data = {
            ...(data.client_data || {}),
            file_browser_data: {
                documents: state.documents,
            },
        };

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${clientId}`,
            method: 'PUT',
            data: {
                client_data,
            },
        });

        const newState = { ...state, loading: false, error: null };

        updateState(newState);
        updateClientData(client_data);

        if (reload) {
            await getFileData(newState, updateState, clientId);
        }
    } catch (e) {
        updateState({
            ...state,
            loading: false,
            error: 'There has been an error saving your data, please try again or contact support',
        });
    }
};

const getFileData = async (state, updateState, clientId) => {
    try {
        updateState({ ...state, loading: true, error: null });

        const [
            { data },
            {
                data: { documents: allDocuments },
            },
        ] = await Promise.all([
            axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${clientId}`,
            }),
            axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/clients/${clientId}/documents`,
            }),
        ]);

        updateState({
            ...state,
            loading: false,
            error: null,
            documents: data.client_data?.file_browser_data?.documents || [],
            allDocuments,
        });
    } catch (e) {
        updateState({
            ...state,
            loading: false,
            error: 'There has been an error saving your data, please try again or contact support',
        });
    }
};

const TextInputLabelModal = ({ okHandler, state, updateState }) => {
    const [localState, updateLocalState] = useState({ label: '' });
    return (
        <Flex sx={{ ml: 20, flexDirection: 'column', mt: 25 }}>
            <Paragraph>Enter a name for the folder</Paragraph>
            <Input
                value={localState.label}
                onChange={(e) => updateLocalState({ ...localState, label: e.target.value })}
                sx={{ mt: 10, width: 350 }}
            />
            <Flex>
                <Button
                    onClick={() => {
                        if (!localState.label) {
                            return;
                        }
                        updateState({ ...state, showTextInputLabelModal: false });
                        okHandler && okHandler('textInput', localState.label);
                    }}
                    sx={{ width: 80, height: 40, mt: 20 }}
                >
                    OK
                </Button>
                <Button
                    onClick={() => {
                        updateState({ ...state, showTextInputLabelModal: false });
                    }}
                    sx={{ width: 100, height: 40, mt: 20, ml: 10, color: '#000', backgroundColor: 'white' }}
                >
                    Cancel
                </Button>
            </Flex>
        </Flex>
    );
};

const RenameFileModal = ({ okHandler, state, updateState }) => {
    const [localState, updateLocalState] = useState({ label: state.documentForEdit?.name });
    return (
        <Flex sx={{ ml: 20, flexDirection: 'column', mt: 25 }}>
            <Paragraph>Name</Paragraph>
            <Input
                value={localState.label}
                onChange={(e) => updateLocalState({ ...localState, label: e.target.value })}
                sx={{ mt: 10, width: 350 }}
            />
            <Flex>
                <Button
                    onClick={() => {
                        if (!localState.label) {
                            return;
                        }
                        updateState({ ...state, showTextInputLabelModal: false });
                        okHandler && okHandler(localState.label);
                    }}
                    sx={{ width: 80, height: 40, mt: 20 }}
                >
                    Save
                </Button>
                <Button
                    onClick={() => {
                        updateState({ ...state, showRenameFileModal: false });
                    }}
                    sx={{ width: 100, height: 40, mt: 20, ml: 10, color: '#000', backgroundColor: 'white' }}
                >
                    Cancel
                </Button>
            </Flex>
        </Flex>
    );
};

const CannotDeleteDialog = ({ state, updateState }) => {
    return (
        <Flex sx={{ flexDirection: 'column', mt: 25, justifyContent: 'center', alignItems: 'center' }}>
            <Paragraph sx={{ textAlign: 'center' }}>Please delete the contents of this folder first</Paragraph>
            <Button
                onClick={() => {
                    updateState({ ...state, showCannotDeleteDialog: false });
                }}
                sx={{ width: 80, height: 40, mt: 20 }}
            >
                OK
            </Button>
        </Flex>
    );
};

const AddFileDialog = ({ okHandler, state, updateState }) => {
    const [localState, updateLocalState] = useState({ file: null });
    return (
        <Flex sx={{ ml: 20, flexDirection: 'column', mt: 25 }}>
            <Input
                type="file"
                value={localState.label}
                onChange={(e) => updateLocalState({ ...localState, error: null, file: [...e.target.files]?.[0] })}
                sx={{ mt: 10, width: 350 }}
            />
            {localState.error && <Paragraph sx={{ mt: 10, mb: 0, color: 'red' }}>{localState.error}</Paragraph>}
            <Flex>
                <Button
                    onClick={() => {
                        const MAX_SIZE = 20 * 1024 * 1024;

                        if (localState.file.size > MAX_SIZE) {
                            return updateLocalState({ ...localState, error: 'The maximum file size allowed is 20MB.' });
                        }

                        okHandler && okHandler(localState.file);
                    }}
                    sx={{ width: 100, height: 40, mt: 20 }}
                >
                    Upload
                </Button>
                <Button
                    onClick={() => {
                        updateState({ ...state, showFileUploadDialog: false });
                    }}
                    sx={{ width: 100, height: 40, mt: 20, ml: 10, color: '#000', backgroundColor: 'white' }}
                >
                    Cancel
                </Button>
            </Flex>
        </Flex>
    );
};

export const MyFileBrowser = ({ clientId, updateClientData }) => {
    const [state, updateState] = useState({
        documents: [],
        chain: [],
        breadcrumbs: [],
    });

    let documents = state.documents;
    for (let index = 0; index < state.chain.length; index++) {
        documents = documents[state.chain[index]]?.documents || [];
    }

    useEffect(() => {
        (async () => {
            await getFileData(state, updateState, clientId);
        })();
    }, []);

    return (
        <Flex sx={{ minHeight: 300, ml: '20px', mr: '20px', flexDirection: 'column' }}>
            <Paragraph sx={{ fontWeight: 600, fontSize: 20, color: 'text', mt: 20 }}>Documents</Paragraph>

            <Divider width="1300px" />

            {state.showCannotDeleteDialog && (
                <Overlay
                    overlay={{ hideNavigation: true }}
                    copy={{}}
                    maxWidth={400}
                    maxHeight={140}
                    embeddedComponent={<CannotDeleteDialog state={state} updateState={updateState} />}
                    updateOverlay={() => updateState({ ...state, showCannotDeleteDialog: false })}
                />
            )}
            {state.showTextInputLabelModal && (
                <Overlay
                    overlay={{ hideNavigation: true }}
                    copy={{}}
                    maxWidth={400}
                    maxHeight={180}
                    embeddedComponent={
                        <TextInputLabelModal okHandler={state.okHandler} state={state} updateState={updateState} />
                    }
                    updateOverlay={() => updateState({ ...state, showTextInputLabelModal: false })}
                />
            )}
            {state.showRenameFileModal && (
                <Overlay
                    overlay={{ hideNavigation: true }}
                    copy={{}}
                    maxWidth={400}
                    maxHeight={180}
                    embeddedComponent={
                        <RenameFileModal okHandler={state.okHandler} state={state} updateState={updateState} />
                    }
                    updateOverlay={() => updateState({ ...state, showRenameFileModal: false })}
                />
            )}
            {state.showFileUploadDialog && (
                <Overlay
                    overlay={{ hideNavigation: true }}
                    copy={{}}
                    maxWidth={400}
                    maxHeight={190}
                    embeddedComponent={
                        <AddFileDialog okHandler={state.okHandler} state={state} updateState={updateState} />
                    }
                    updateOverlay={() => updateState({ ...state, showFileUploadDialog: false })}
                />
            )}

            {state.showDeleteFileConfirmation && (
                <ConfirmDialog
                    updateState={updateState}
                    state={state}
                    stateKey="showDeleteFileConfirmation"
                    text="Are you sure you wish to delete this?"
                />
            )}

            {state.loading && <Spinner />}

            <Flex sx={{ mb: '30px', mt: '0px' }}>
                {state.chain?.length ? (
                    <Button
                        onClick={() => {
                            updateState({
                                ...state,
                                chain: [...state.chain.slice(0, state.chain.length - 1)],
                                breadcrumbs: [...state.breadcrumbs.slice(0, state.breadcrumbs.length - 1)],
                            });
                        }}
                        sx={{ height: 40, mr: '20px', fontSize: 14, backgroundColor: 'white', color: 'black' }}
                    >
                        Back
                    </Button>
                ) : null}
                <Button
                    onClick={() => {
                        const okHandler = async (key, name) => {
                            const newDocument = {
                                id: uuid.v4(),
                                name,
                                documents: [],
                                created_at: moment.utc().format('YYYY-MM-DDTHH:mm:ssZ'),
                            };

                            let pointer = state.documents;
                            if (state.chain.length) {
                                for (let index = 0; index < state.chain.length; index++) {
                                    pointer = pointer[state.chain[index]]?.documents || [];
                                    if (index === state.chain.length - 1) {
                                        pointer.push(newDocument);
                                    }
                                }
                            } else {
                                pointer.push(newDocument);
                            }

                            updateState({
                                ...state,
                                showTextInputLabelModal: false,
                            });
                            await saveFileData(state, updateState, clientId, updateClientData);
                        };

                        updateState({
                            ...state,
                            showTextInputLabelModal: true,
                            okHandler,
                        });
                    }}
                    sx={{ height: 40, fontSize: 14 }}
                >
                    <i
                        style={{
                            marginRight: '7px',
                        }}
                        className={`fas fa-plus-circle`}
                    />
                    Add Folder
                </Button>

                <Button
                    onClick={() => {
                        const okHandler = async (newFile) => {
                            updateState({ ...state, loading: true });

                            const { data } = await axios({
                                method: 'POST',
                                url: `${process.env.REACT_APP_AQRU_AI_API}/uploads`,
                                data: {
                                    description: clientId,
                                    files: [newFile].map((x) => ({
                                        content_type: x?.type,
                                        file_name: x?.name,
                                        labels: [],
                                    })),
                                    client_id: clientId,
                                },
                            });

                            await axios.put(data.urls[0], newFile, {
                                headers: { 'Content-Type': newFile.type },
                            });

                            const newDocument = {
                                id: uuid.v4(),
                                type: 'file',
                                name: newFile.name,
                                document_uuid: data.doc_uuids[0],
                                created_at: moment.utc().format('YYYY-MM-DDTHH:mm:ssZ'),
                            };

                            let pointer = state.documents;
                            if (state.chain.length) {
                                for (let index = 0; index < state.chain.length; index++) {
                                    pointer = pointer[state.chain[index]]?.documents || [];
                                    if (index === state.chain.length - 1) {
                                        pointer.push(newDocument);
                                    }
                                }
                            } else {
                                pointer.push(newDocument);
                            }

                            await saveFileData(
                                {
                                    ...state,
                                    okHandler: null,
                                    showFileUploadDialog: false,
                                },
                                updateState,
                                clientId,
                                updateClientData
                            );
                        };

                        updateState({
                            ...state,
                            showFileUploadDialog: true,
                            okHandler,
                        });
                    }}
                    sx={{ height: 40, fontSize: 14, ml: '10px', backgroundColor: 'white', color: 'black' }}
                >
                    <i
                        style={{
                            marginRight: '7px',
                        }}
                        className={`fas fa-upload`}
                    />
                    Upload File
                </Button>
            </Flex>

            {state.breadcrumbs?.length ? (
                <Flex sx={{ ml: '20px', mb: '30px' }}>
                    {state.breadcrumbs?.length ? (
                        <>
                            <Flex
                                onClick={() => {
                                    updateState({
                                        ...state,
                                        breadcrumbs: [],
                                        chain: [],
                                    });
                                }}
                                sx={{ cursor: 'pointer' }}
                                key={`bc_root`}
                            >
                                <Paragraph sx={{ textDecoration: 'underline', mr: '10px' }}>...</Paragraph>
                                {' >'}
                                <Flex sx={{ width: '10px' }} />
                            </Flex>
                            {state.breadcrumbs.map((item, idx) => (
                                <Flex
                                    onClick={() => {
                                        if (idx === state.breadcrumbs.length - 1) return;
                                        updateState({
                                            ...state,
                                            breadcrumbs: state.breadcrumbs.slice(0, idx + 1),
                                            chain: state.chain.slice(0, idx + 1),
                                        });
                                    }}
                                    sx={{ cursor: 'pointer' }}
                                    key={`bc_${idx}`}
                                >
                                    <Paragraph sx={{ textDecoration: 'underline', mr: '10px' }}>{item}</Paragraph>
                                    {state.breadcrumbs.length === idx + 1 ? '' : ' >'}
                                    <Flex sx={{ width: '10px' }} />
                                </Flex>
                            ))}
                        </>
                    ) : null}
                </Flex>
            ) : null}

            <Flex sx={{ flexDirection: 'column', ml: '20px' }}>
                <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', width: '1000px' }}>
                    <Paragraph sx={{ textAlign: 'left', fontWeight: 600, maxWidth: '150px' }}>Name</Paragraph>
                    <Paragraph sx={{ textAlign: 'center', fontWeight: 600, maxWidth: '150px', ml: '10px' }}>
                        Created at
                    </Paragraph>
                </Flex>
                <Divider width="800px" />

                {sortFiles(documents)?.map((document, idx) => (
                    <Flex
                        key={`folder_${state.chain.join('_')}_${idx}`}
                        sx={{
                            cursor: 'pointer',
                            flexDirection: 'column',
                            mr: '40px',
                            alignItems: 'flex-start',
                        }}
                    >
                        <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', width: '1000px' }}>
                            <Flex
                                sx={{ alignItems: 'center' }}
                                onClick={async () => {
                                    if (document.type === 'file') {
                                        const {
                                            data: { url },
                                        } = await axios.get(
                                            `${process.env.REACT_APP_AQRU_AI_API}/documents/${document.document_uuid}`,
                                            {}
                                        );

                                        const a = window.document.createElement('a');

                                        a.href = url;
                                        a.target = '_blank';
                                        a.download = document.name;

                                        return a.click();
                                    }
                                    updateState({
                                        ...state,
                                        chain: [...state.chain, idx],
                                        breadcrumbs: [...state.breadcrumbs, document.name],
                                    });
                                }}
                            >
                                <i
                                    style={{ fontSize: '40px', color: '#CCC' }}
                                    className={`fa fa-${document.type === 'file' ? 'file' : 'folder'}`}
                                />
                                <Paragraph
                                    sx={{ textAlign: 'center', maxWidth: '450px', ml: '10px', fontSize: '15px' }}
                                >
                                    {document.name}
                                </Paragraph>
                            </Flex>
                            <Flex sx={{ alignItems: 'center' }}>
                                {document.type === 'file' && (
                                    <Flex sx={{ width: 130 }}>
                                        <Paragraph sx={{ mr: 30 }}>Share</Paragraph>
                                        <Switch
                                            onClick={async (e) => {
                                                e.stopPropagation();

                                                let newDocumentsList = [...state.documents];

                                                if (state.chain.length) {
                                                    let pointerList = [...newDocumentsList];
                                                    for (let index = 0; index < state.chain.length; index++) {
                                                        pointerList = pointerList[state.chain[index]]?.documents || [];
                                                        if (index === state.chain.length - 1) {
                                                            pointerList[idx].share = pointerList[idx].share
                                                                ? false
                                                                : true;
                                                        }
                                                    }
                                                } else {
                                                    newDocumentsList[idx].share = newDocumentsList[idx].share
                                                        ? false
                                                        : true;
                                                }

                                                const newState = {
                                                    ...state,
                                                    documents: newDocumentsList,
                                                };

                                                await saveFileData(newState, updateState, clientId, updateClientData);
                                            }}
                                            defaultChecked={document.share}
                                        />
                                    </Flex>
                                )}
                                <i
                                    onClick={async (e) => {
                                        e.stopPropagation();

                                        const okHandler = async (newName) => {
                                            updateState({ ...state, loading: true });

                                            document.name = newName;

                                            await saveFileData(
                                                {
                                                    ...state,
                                                    okHandler: null,
                                                    documentForEdit: null,
                                                    showRenameFileModal: false,
                                                },
                                                updateState,
                                                clientId,
                                                updateClientData
                                            );
                                        };

                                        updateState({
                                            ...state,
                                            documentForEdit: document,
                                            showRenameFileModal: true,
                                            okHandler,
                                        });
                                    }}
                                    style={{ fontSize: '20px', color: 'green' }}
                                    className={`fa fa-pencil`}
                                />
                                <Button
                                    onClick={async (e) => {
                                        e.stopPropagation();

                                        if (document.documents?.length) {
                                            return updateState({ ...state, showCannotDeleteDialog: true });
                                        }

                                        const confirmCallback = async () => {
                                            let newDocumentsList = [...state.documents];

                                            if (state.chain.length) {
                                                let pointerList = [...newDocumentsList];
                                                for (let index = 0; index < state.chain.length; index++) {
                                                    pointerList = pointerList[state.chain[index]]?.documents || [];
                                                    if (index === state.chain.length - 1) {
                                                        pointerList.splice(idx);
                                                    }
                                                }
                                            } else {
                                                newDocumentsList.splice(idx);
                                            }

                                            const newState = {
                                                ...state,
                                                documents: newDocumentsList,
                                                showDeleteFileConfirmation: false,
                                            };

                                            updateState(newState);

                                            await saveFileData(newState, updateState, clientId, updateClientData, true);

                                            if (document.document_uuid) {
                                                await axios({
                                                    url: `${process.env.REACT_APP_AQRU_AI_API}/documents/${document.document_uuid}`,
                                                    method: 'DELETE',
                                                });
                                            }
                                        };

                                        updateState({ ...state, showDeleteFileConfirmation: true, confirmCallback });
                                    }}
                                    sx={{
                                        ml: '20px',
                                        height: 40,
                                        mr: '20px',
                                        fontSize: 14,
                                        backgroundColor: 'white',
                                        color: 'black',
                                    }}
                                >
                                    Delete
                                </Button>
                                <Paragraph
                                    sx={{ textAlign: 'center', maxWidth: '190px', fontSize: '13px', ml: '10px' }}
                                >
                                    {moment.utc(document.created_at).format('HH:mm Do MMM YYYY')}
                                </Paragraph>
                            </Flex>
                        </Flex>
                        <Divider width="800px" />
                    </Flex>
                ))}
            </Flex>
        </Flex>
    );
};
