/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-undef */
/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox, Flex, Heading, Input, Label, Paragraph, Switch, Textarea } from 'theme-ui';
import MainLogo from '../components/main-logo';
import Sidebar from '../components/sidebar';
import Spinner from '../components/spinner';
import { actions as menuActions } from '../store/reducers/menu';
import { actions as userActions } from '../store/reducers/users';
import Divider from '../components/divider';
import ReactSelect from 'react-select';
import ProgressIndicator from '../components/tasks/progress-indicator';
import axios from 'axios';
import { loadClientsAsync, loadTasksAsync } from '../components/tasks/filters';
import AsyncSelect from 'react-select/async';
import moment from 'moment';
import * as uuid from 'uuid';
import theme from '../theme';
import ConfirmDialog from '../components/tasks/confirm-dialog';

export const defaultState = {
    loading: true,
    initialLoad: true,
    partialLoading: false,
    errors: {},
};

export const timeStatuses = [
    { value: 'locked', label: 'Approved' },
    { value: 'open', label: 'Unapproved' },
    { value: 'written_off', label: 'Written off' },
];

const activityTypes = [
    {
        value: 'task_for_client',
        label: 'Task for client',
    },
    {
        value: 'holiday',
        label: 'Holiday',
    },
    {
        value: 'training',
        label: 'Training',
    },
    {
        value: 'bus_dev',
        label: 'Business Development',
    },
];

export const defaultErrorState = { selectedDocs: false, description: false, shareEmails: false };

export function convertMinutesToHours(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours === 0) return `${remainingMinutes} minutes`;

    return `${hours} Hour${hours > 1 ? 's' : ''} ${remainingMinutes} minutes`;
}

const Title = ({ state, updateState }) => (
    <Flex
        sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            mb: '10px',
        }}
    >
        <Flex sx={{ flexDirection: 'column' }}>
            <Heading
                sx={{
                    fontWeight: '400',
                    fontSize: '25px',
                    color: 'text',
                    textAlign: 'left',
                    mt: '30px',
                }}
            >
                Timesheets
            </Heading>
            {state.organisation?.user?.role === 'admin' && (
                <Flex sx={{ alignItems: 'center', mt: 20 }}>
                    <Label sx={{ width: 250 }}>Week view</Label>
                    <Switch
                        defaultChecked={state.view === 'summary'}
                        onClick={async (e) => {
                            const newState = { ...state, view: state.view === 'week' ? 'summary' : 'week' };
                            updateState(newState);
                            if (newState.view === 'week') {
                                await loadTimeEntries(newState, updateState);
                            } else {
                                await loadSummaryData(newState, updateState);
                            }
                        }}
                    />
                    <Label sx={{ ml: -70, textAlign: 'left' }}>Summary view</Label>
                </Flex>
            )}
        </Flex>
    </Flex>
);

const updateStatus = async (entries, state, updateState) => {
    try {
        updateState({ ...state, loading: true, error: null });

        await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/time-entries/bulk-update`,
            method: 'PUT',
            data: {
                entries,
            },
        });

        if (state.view === 'drill_in') {
            await loadDetailedList(state, updateState);
        } else {
            await loadSummaryData(state, updateState);
        }
    } catch (e) {
        updateState({ ...state, loading: false, error: e?.response?.data?.message });
    }
};

const loadSummaryData = async (state, updateState) => {
    try {
        updateState({ ...state, loading: true, error: null });

        let { fromDate, toDate } = buildDateRange(state);

        const {
            data: { entries: summaryEntries },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/time-entries/summary`,
            params: {
                from: fromDate,
                to: toDate,
                status: state.statusSelected?.value || 'locked,open,written_off',
            },
        });

        const uniqueUsers = {};

        summaryEntries.forEach((entry) => {
            if (uniqueUsers[entry.org_user_id]) {
                uniqueUsers[entry.org_user_id].total =
                    parseFloat(uniqueUsers[entry.org_user_id].total || 0) + parseFloat(entry.sum);
            } else {
                uniqueUsers[entry.org_user_id] = entry;
                uniqueUsers[entry.org_user_id].total = parseFloat(entry.sum);
            }
        });

        updateState({
            ...state,
            loading: false,
            error: null,
            summaryEntries: summaryEntries?.map((x) => ({ ...x, uiKey: uuid.v4() })),
            allEntriesSelected: false,
            uniqueUsers: Object.values(uniqueUsers),
            detailedTimeEntries: summaryEntries
                ?.filter((x) => parseFloat(x.timeSpent) > 0)
                ?.map((x) => ({ ...x, uiKey: uuid.v4() })),
        });
    } catch (e) {
        updateState({ ...state, loading: false, error: e?.response?.data?.message });
    }
};

const loadDetailedList = async (state, updateState, returnState = false) => {
    try {
        updateState({ ...state, loading: true, error: null });

        let { fromDate, toDate } = buildDateRange(state);

        const {
            data: { entries: summaryEntries },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/time-entries/summary`,
            params: {
                from: fromDate,
                to: toDate,
                status: state.statusSelected?.value || 'locked,open,written_off',
                group_by_service: '0',
                org_user_id: state.user.org_user_id,
            },
        });

        const newState = {
            ...state,
            loading: false,
            error: null,
            detailedTimeEntries: summaryEntries
                ?.filter((x) => parseFloat(x.timeSpent) > 0)
                ?.map((x) => ({ ...x, uiKey: uuid.v4() })),
            allEntriesSelected: false,
        };

        if (returnState) {
            return newState;
        }

        updateState(newState);
    } catch (e) {
        updateState({ ...state, loading: false, error: e?.response?.data?.message });
    }
};

const save = async (state, updateState) => {
    try {
        const errors = {};

        state.timeEntries?.forEach((entry, idx) => {
            if (entry.activity_type === 'task_for_client' && !entry.client?.id) {
                errors[`${idx}_client`] = true;
            }
            if (!entry.description) {
                errors[`${idx}_description`] = true;
            }
            if (
                entry.activity_type === 'task_for_client' &&
                entry.additional_data?.billable &&
                !entry.billable_amount
            ) {
                errors[`${idx}_billable_amount`] = true;
            }
        });

        if (Object.keys(errors)?.length === 0 && state.timeEntries?.length) {
            const data = {
                entries: state.timeEntries?.map((item) => ({
                    uuid: item.uuid,
                    description: item.description,
                    additional_data: item.additional_data,
                    billable_amount: parseFloat(item.billable_amount || 0),
                    service: item.service,
                    time_spent: item.time_spent,
                    belongs_to: state.org_user.value,
                    task: item.task,
                    client: item.client,
                    activity_type: item.activity_type,
                    week_start_date: moment(state.week?.value, 'DD_MM_YYYY').format('YYYY-MM-DD'),
                    time_day_entries: [0, 1, 2, 3, 4, 5, 6, 7].map((x) => ({
                        time_spent: item.additional_data?.days?.[x] || 0,
                        status: item.additional_data?.locked_days?.includes(x) ? 'locked' : 'open',
                    })),
                })),
            };

            updateState({ ...state, loading: true, errors: {} });

            await axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/time-entries`,
                method: 'POST',
                data,
            });

            await loadTimeEntries(state, updateState);
        } else {
            updateState({ ...state, errors });
        }
    } catch (e) {
        updateState({ ...state, loading: false, error: e?.response?.data?.message });
    }
};

const loadInitialData = async (state, updateState) => {
    try {
        const [
            {
                data: { users },
            },
            { data: organisation },
        ] = await Promise.all([
            axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/organisations/users`,
                params: {
                    limit: 300,
                    offset: 0,
                },
            }),
            axios({
                url: `${process.env.REACT_APP_AQRU_AI_API}/organisations`,
            }),
        ]);

        const newState = {
            ...state,
            loading: false,
            users,
            organisation,
            defaultIncrements: organisation?.default_visualisation?.timesheets?.defaultIncrements || 15,
            org_user: {
                value: organisation?.user?.raw_id,
                additional_data: organisation?.user?.additional_data,
                label: organisation?.user?.first_name
                    ? `${organisation?.user?.first_name} ${organisation?.user?.last_name}`
                    : organisation?.user?.email_address,
            },
        };

        if (newState.week && newState.org_user?.value && newState.org_user?.label) {
            await loadTimeEntries(newState, updateState);
        } else {
            updateState(newState);
        }
    } catch (e) {
        updateState({ ...state, loading: false, error: e?.response?.data?.message });
    }
};

export const buildIncrementOptions = (defaultIncrements = 15) => {
    const options = [{ value: 0, label: '0 minutes' }];

    let position = defaultIncrements;
    while (position <= 60 * 8) {
        options.push({
            value: position,
            label: position > 60 ? convertMinutesToHours(position) : `${position} minutes`,
        });

        position += defaultIncrements;
    }

    return options;
};

const generateWeekOptions = () => {
    const startDate = '30_06_2024';
    const endDate = '27_12_2028';

    const dateArray = [];
    let currentDate = moment(startDate, 'DD_MM_YYYY');
    const now = moment();

    while (currentDate.isSameOrBefore(moment(endDate, 'DD_MM_YYYY'))) {
        const value = currentDate.format('DD_MM_YYYY');
        const labelStart = currentDate.format('DD/MM/YYYY');

        const defaultToThis = currentDate.isSameOrBefore(now) && moment(currentDate).add(6, 'days').isSameOrAfter(now);

        const labelEnd = currentDate.add(6, 'days').format('DD/MM/YYYY');
        const label = `${labelStart} - ${labelEnd}`;

        dateArray.push({
            value,
            label,
            defaultToThis,
        });

        currentDate.add(1, 'days');
    }

    return dateArray;
};

const loadTimeEntries = async (state, updateState) => {
    try {
        const {
            data: { entries: timeEntries },
        } = await axios({
            url: `${process.env.REACT_APP_AQRU_AI_API}/time-entries`,
            params: {
                belongs_to: state.org_user.value,
                week_start_date: state.week?.value,
            },
        });

        updateState({
            ...state,
            loading: false,
            timeEntries: timeEntries?.length
                ? timeEntries?.map((x) => ({
                      ...x,
                      additional_data: {
                          ...x.additional_data,
                          days: [0, 1, 2, 3, 4, 5, 6, 7].map((y) => parseFloat(x.time_day_entries?.[y]?.timeSpent)),
                          locked_days: [0, 1, 2, 3, 4, 5, 6, 7].filter(
                              (y) => x.time_day_entries?.[y]?.status === 'locked'
                          ),
                      },
                  }))
                : [templateRecord(state)],
            totalTimeSpent: timeEntries.reduce((accum, value) => {
                accum = parseInt(accum || 0, 10) + parseInt(value.time_spent || 0, 10);
                return accum;
            }, 0),
        });
    } catch (e) {
        updateState({ ...state, loading: false, error: e?.response?.data?.message });
    }
};

const templateRecord = (state) => ({
    time_spent: 0,
    activity_type: 'task_for_client',
    billable_amount: state.org_user?.additional_data?.billable_hourly_amount || '',
    additional_data: { billable: true, days: [0, 0, 0, 0, 0, 0, 0] },
});

const addRow = (state, updateState) => {
    updateState({
        ...state,
        timeEntries: [...(state.timeEntries || []), templateRecord(state)],
    });
};

const Timesheet = ({ refreshCoreData, userData, accountSummary, isMobile, organisation, logout }) => {
    const weekOptions = generateWeekOptions();

    const [state, updateState] = useState({
        ...defaultState,
        organisation,
        weekOptions: weekOptions,
        week: weekOptions?.find((x) => x.defaultToThis),
        view: 'week',
        timePeriod: { value: 'this_month', label: 'This Month' },
        statusSelected: { value: 'open,locked', label: 'All' },
    });
    const [errorState, updateErrorState] = useState(defaultErrorState);
    const [user, updateUser] = useState(userData);
    const navigate = useNavigate();

    const stateRef = useRef();
    stateRef.current = state;

    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         (async () => {
    //             await save(stateRef.current, updateState);
    //         })();
    //     }, 5000);

    //     return () => clearInterval(intervalId);
    // }, [state.fullTaskData]);

    useEffect(() => {
        (async () => {
            // const queryParams = new URLSearchParams(window.location.search);
            refreshCoreData();
            await loadInitialData(state, updateState);
        })();
    }, []);

    const billableTime = state.timeEntries?.reduce((accum, value) => {
        if (value.additional_data?.billable) {
            accum = parseInt(accum || 0, 10) + parseInt(value.time_spent || 0, 10);
        }
        return accum;
    }, 0);

    const billableTimePct =
        billableTime > 0 && state.totalTimeSpent > 0 ? (billableTime / state.totalTimeSpent) * 100 : 0;

    const timeRecordingOptions = buildIncrementOptions(state.defaultIncrements || 15);

    return (
        <>
            <Flex
                sx={{
                    minHeight: '100vh',
                    paddingBottom: '100px',
                    // width: '100vw',
                    width: 'auto',
                    flexDirection: 'column',
                    position: 'relative',
                    backgroundSize: 'cover',
                    backgroundColor: '#fff',
                }}
                data-testid="home-screen"
            >
                <Flex
                    sx={{
                        minHeight: '100vh',
                        width: 'auto',
                        flexDirection: 'column',
                    }}
                >
                    <Sidebar currentPage="Timesheets" accountSummary={accountSummary} user={user} copy={{}} />

                    {(state.loading || state.dataLoading) && <Spinner />}

                    {state.showDeleteItemConfirmation && (
                        <ConfirmDialog
                            updateState={updateState}
                            state={state}
                            stateKey="showDeleteItemConfirmation"
                            text="Are you sure you want to delete this row?"
                        />
                    )}

                    {state.showConfirmServiceChangeDialog && (
                        <ConfirmDialog
                            updateState={updateState}
                            state={state}
                            stateKey="showConfirmServiceChangeDialog"
                            text="Are you sure you want to change the service to one different to that of the selected task?"
                        />
                    )}

                    <Flex
                        sx={{
                            minWidth: '100vw',
                            width: 'fit-content',
                            border: '0px solid lightgrey',
                            minHeight: 'calc(100vh)',
                            backgroundColor: '#ffffff',
                            borderTopLeftRadius: '25px',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            mt: '15px',
                            ml: '130px',
                        }}
                    >
                        <>
                            <Flex
                                sx={{
                                    flexDirection: 'column',
                                    ml: '30px',
                                    position: 'relative',
                                    width: 'auto',
                                }}
                            >
                                <MainLogo organisation={organisation} />

                                <Flex sx={{ flexDirection: 'column' }}>
                                    <Title state={state} updateState={updateState} />
                                    <Divider width="1000px" />

                                    {['drill_in', 'summary'].includes(state.view) && (
                                        <Flex sx={{ flexDirection: 'column' }}>
                                            <Flex>
                                                <Flex sx={{ flexDirection: 'column', mr: 20 }}>
                                                    <Label sx={{ mt: 0, mb: 10 }} id="tp-label">
                                                        Date Range
                                                    </Label>
                                                    <ReactSelect
                                                        aria-labelledby="tp-label"
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                                width: '200px',
                                                                minHeight: '40px',
                                                                marginTop: '0px',
                                                                borderRadius: 10,
                                                                marginBottom: '10px',
                                                                fontSize: '14px',
                                                                border: '1px solid #a3a69b',
                                                            }),
                                                        }}
                                                        onChange={async (c) => {
                                                            updateState({ ...state, timePeriod: c });
                                                            await loadSummaryData(
                                                                { ...state, timePeriod: c },
                                                                updateState
                                                            );
                                                        }}
                                                        placeholder="Select..."
                                                        value={state.timePeriod}
                                                        options={[
                                                            { value: 'today', label: 'Today' },
                                                            { value: 'this_week', label: 'This Week' },
                                                            { value: 'this_month', label: 'This Month' },
                                                            { value: 'last_week', label: 'Last Week' },
                                                            { value: 'last_month', label: 'Last Month' },
                                                            { value: 'custom', label: 'Custom' },
                                                        ]}
                                                    />
                                                </Flex>
                                                {state.timePeriod?.value === 'custom' && (
                                                    <Flex sx={{ flexDirection: 'column', mr: 20 }}>
                                                        <Label sx={{ mt: 0, mb: 10 }} id="from-label">
                                                            From
                                                        </Label>
                                                        <Input
                                                            value={state.fromDate}
                                                            onChange={(e) =>
                                                                updateState({ ...state, fromDate: e.target.value })
                                                            }
                                                            onBlur={async () => {
                                                                await loadSummaryData(state, updateState);
                                                            }}
                                                            type="date"
                                                        />
                                                    </Flex>
                                                )}
                                                {state.timePeriod?.value === 'custom' && (
                                                    <Flex sx={{ flexDirection: 'column', mr: 20 }}>
                                                        <Label sx={{ mt: 0, mb: 10 }} id="to-label">
                                                            To
                                                        </Label>
                                                        <Input
                                                            value={state.toDate}
                                                            onChange={(e) =>
                                                                updateState({ ...state, toDate: e.target.value })
                                                            }
                                                            onBlur={async () => {
                                                                await loadSummaryData(state, updateState);
                                                            }}
                                                            type="date"
                                                        />
                                                    </Flex>
                                                )}
                                                <Flex sx={{ flexDirection: 'column', mr: 20 }}>
                                                    <Label sx={{ mt: 0, mb: 10 }} id="status-label">
                                                        Status
                                                    </Label>
                                                    <ReactSelect
                                                        aria-labelledby="status-label"
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                                width: '200px',
                                                                minHeight: '40px',
                                                                marginTop: '0px',
                                                                borderRadius: 10,
                                                                marginBottom: '10px',
                                                                fontSize: '14px',
                                                                border: '1px solid #a3a69b',
                                                            }),
                                                        }}
                                                        onChange={async (c) => {
                                                            updateState({ ...state, statusSelected: c });
                                                            await loadSummaryData(
                                                                { ...state, statusSelected: c },
                                                                updateState
                                                            );
                                                        }}
                                                        placeholder="Select..."
                                                        value={state.statusSelected}
                                                        options={[
                                                            { value: 'open,locked,written_off', label: 'All' },
                                                            { value: 'open', label: 'Open' },
                                                            { value: 'locked', label: 'Approved' },
                                                            { value: 'written_off', label: 'Written off' },
                                                        ]}
                                                    />
                                                </Flex>
                                            </Flex>

                                            {state.summaryEntries?.find((x) => x.checked) ? (
                                                <Flex sx={{ mt: 10, mb: 10 }}>
                                                    <Button
                                                        onClick={async () => {
                                                            const { fromDate, toDate } = buildDateRange(state);
                                                            await updateStatus(
                                                                state.summaryEntries
                                                                    ?.filter((x) => x.checked)
                                                                    ?.map((x) => ({
                                                                        status: 'locked',
                                                                        user_id: x.org_user_id,
                                                                        from: fromDate,
                                                                        to: toDate,
                                                                    })),
                                                                state,
                                                                updateState
                                                            );
                                                        }}
                                                        sx={{ height: 40 }}
                                                        variant="primary"
                                                    >
                                                        Approve Selected
                                                    </Button>
                                                    <Button
                                                        onClick={async () => {
                                                            const { fromDate, toDate } = buildDateRange(state);
                                                            await updateStatus(
                                                                state.summaryEntries
                                                                    ?.filter((x) => x.checked)
                                                                    ?.map((x) => ({
                                                                        status: 'open',
                                                                        user_id: x.org_user_id,
                                                                        from: fromDate,
                                                                        to: toDate,
                                                                    })),
                                                                state,
                                                                updateState
                                                            );
                                                        }}
                                                        variant="light"
                                                        sx={{ height: 40, ml: 20 }}
                                                    >
                                                        Unapprove Selected
                                                    </Button>
                                                </Flex>
                                            ) : null}

                                            {state.view === 'summary' && (
                                                <table
                                                    style={{
                                                        border: '1px solid lightGrey',
                                                        borderCollapse: 'collapse',
                                                        marginTop: 20,
                                                    }}
                                                >
                                                    <thead
                                                        style={{
                                                            boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.2)',
                                                            backgroundColor: theme.colors.primary,
                                                            opacity: 0.9,
                                                            height: 50,
                                                            color: 'white',
                                                        }}
                                                    >
                                                        <th
                                                            onClick={() => {
                                                                updateState({
                                                                    ...state,
                                                                    allEntriesSelected: state.allEntriesSelected
                                                                        ? false
                                                                        : true,
                                                                    summaryEntries: state.summaryEntries?.map(
                                                                        (item) => ({
                                                                            ...item,
                                                                            checked: state.allEntriesSelected
                                                                                ? false
                                                                                : true,
                                                                        })
                                                                    ),
                                                                });
                                                            }}
                                                            style={{ width: 100 }}
                                                        >
                                                            <Checkbox
                                                                sx={{ ml: 25, backgroundColor: 'white' }}
                                                                checked={state.allEntriesSelected}
                                                            />
                                                        </th>
                                                        <th style={{ width: 100 }}>Name</th>
                                                        <th style={{ width: 200 }}>Total</th>
                                                        <th style={{ width: 120 }}>Action</th>
                                                    </thead>
                                                    {state.summaryEntries?.map((item, idx) => (
                                                        <tr key={`summ_${idx}`}>
                                                            <td style={{ width: 40, padding: 25 }}>
                                                                <Flex
                                                                    onClick={() => {
                                                                        state.summaryEntries[idx].checked =
                                                                            !!item.checked ? false : true;
                                                                        updateState({
                                                                            ...state,
                                                                            summaryEntries: state.summaryEntries,
                                                                        });
                                                                    }}
                                                                >
                                                                    <Checkbox checked={item.checked} />
                                                                </Flex>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding: 25,
                                                                    textAlign: 'center',
                                                                }}
                                                                onClick={async () => {
                                                                    updateState({
                                                                        ...state,
                                                                        view: 'drill_in',
                                                                        user: item,
                                                                    });
                                                                    await loadDetailedList(
                                                                        { ...state, view: 'drill_in', user: item },
                                                                        updateState
                                                                    );
                                                                }}
                                                            >
                                                                <Paragraph sx={{ color: 'primary', cursor: 'pointer' }}>
                                                                    {item.first_name + ' ' + item.last_name}
                                                                </Paragraph>
                                                            </td>
                                                            <td style={{ padding: 25, textAlign: 'center' }}>
                                                                {convertMinutesToHours(item.sum || 0)}
                                                            </td>
                                                            <td style={{ padding: 25, textAlign: 'center' }}>
                                                                <Flex sx={{ justifyContent: 'center' }}>
                                                                    <ReactSelect
                                                                        styles={{
                                                                            control: (provided) => ({
                                                                                ...provided,
                                                                                boxShadow:
                                                                                    '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                                                width: '200px',
                                                                                minHeight: '40px',
                                                                                marginTop: '10px',
                                                                                borderRadius: 10,
                                                                                marginBottom: '10px',
                                                                                fontSize: '14px',
                                                                                border: '1px solid #a3a69b',
                                                                            }),
                                                                        }}
                                                                        onChange={async (c) => {
                                                                            const { fromDate, toDate } =
                                                                                buildDateRange(state);

                                                                            await updateStatus(
                                                                                [
                                                                                    {
                                                                                        status: c.value,
                                                                                        user_id: item.org_user_id,
                                                                                        from: fromDate,
                                                                                        to: toDate,
                                                                                    },
                                                                                ],
                                                                                state,
                                                                                updateState
                                                                            );
                                                                        }}
                                                                        placeholder="Set status"
                                                                        value={timeStatuses?.find(
                                                                            (x) => x.value === item.status
                                                                        )}
                                                                        options={timeStatuses}
                                                                    />
                                                                </Flex>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </table>
                                            )}
                                        </Flex>
                                    )}

                                    {state.view === 'week' && (
                                        <Flex>
                                            <Flex sx={{ flexDirection: 'column', mr: 20 }}>
                                                <Label sx={{ mt: 0 }} id="weeks-label">
                                                    Weeks
                                                </Label>
                                                <ReactSelect
                                                    aria-labelledby="weeks-label"
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                            width: '400px',
                                                            minHeight: '40px',
                                                            marginTop: '10px',
                                                            borderRadius: 10,
                                                            marginBottom: '10px',
                                                            fontSize: '14px',
                                                            border: '1px solid #a3a69b',
                                                        }),
                                                    }}
                                                    onChange={async (c) => {
                                                        const newState = {
                                                            ...state,
                                                            week: c,
                                                        };
                                                        updateState(newState);
                                                        await loadTimeEntries(newState, updateState);
                                                    }}
                                                    placeholder="Select..."
                                                    value={state.week}
                                                    options={state.weekOptions}
                                                />
                                            </Flex>

                                            {state.organisation?.user?.role === 'admin' && (
                                                <Flex sx={{ flexDirection: 'column' }}>
                                                    <Label sx={{ mt: 0 }} id="org-users-label">
                                                        Accountant
                                                    </Label>
                                                    <ReactSelect
                                                        aria-labelledby="org-users-label"
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                                width: '400px',
                                                                minHeight: '40px',
                                                                marginTop: '10px',
                                                                borderRadius: 10,
                                                                marginBottom: '10px',
                                                                fontSize: '14px',
                                                                border: '1px solid #a3a69b',
                                                            }),
                                                        }}
                                                        onChange={async (c) => {
                                                            const newState = {
                                                                ...state,
                                                                org_user: c,
                                                            };
                                                            updateState(newState);
                                                            await loadTimeEntries(newState, updateState);
                                                        }}
                                                        placeholder="Select..."
                                                        value={state.org_user}
                                                        options={[
                                                            ...(state?.users?.map((x) => ({
                                                                value: x.raw_id,
                                                                additional_data: x?.additional_data,
                                                                label: x.first_name
                                                                    ? `${x.first_name} ${x.last_name} (${x.email_address})`
                                                                    : x.email_address,
                                                            })) || []),
                                                        ]}
                                                    />
                                                </Flex>
                                            )}
                                        </Flex>
                                    )}

                                    {state.view === 'week' && state.org_user && state.week && (
                                        <Flex>
                                            <Button
                                                onClick={() => addRow(state, updateState)}
                                                variant="light"
                                                sx={{ mt: 10 }}
                                            >
                                                Add row
                                            </Button>
                                        </Flex>
                                    )}

                                    {state.view === 'drill_in' && (
                                        <Flex sx={{ mt: 30, mb: 50 }}>
                                            <Flex
                                                sx={{
                                                    flexDirection: 'column',
                                                    borderRight: '1px solid lightGrey',
                                                    minHeight: 700,
                                                }}
                                            >
                                                <Button
                                                    onClick={() => updateState({ ...state, view: 'summary' })}
                                                    sx={{ mb: 20, width: 100 }}
                                                    variant="light"
                                                >
                                                    Back
                                                </Button>
                                                {!state.uniqueUsers?.length && (
                                                    <Flex
                                                        sx={{
                                                            padding: 25,
                                                            paddingTop: 10,
                                                            flexDirection: 'column',
                                                            mb: 20,
                                                            mr: 20,
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <Paragraph>No Results Found</Paragraph>
                                                    </Flex>
                                                )}
                                                {state.uniqueUsers?.map((item, idx) => (
                                                    <Flex
                                                        sx={{
                                                            padding: 25,
                                                            paddingTop: 20,
                                                            borderBottom: '1px solid lightGrey',
                                                            flexDirection: 'column',
                                                            mb: 20,
                                                            mr: 20,
                                                            color:
                                                                state.user?.org_user_id === item.org_user_id
                                                                    ? 'white'
                                                                    : 'unset',
                                                            backgroundColor:
                                                                state.user?.org_user_id === item.org_user_id
                                                                    ? 'primary'
                                                                    : 'unset',
                                                            cursor: 'pointer',
                                                        }}
                                                        key={`entre_${idx}`}
                                                        onClick={async () => {
                                                            updateState({ ...state, user: item });
                                                            await loadDetailedList(
                                                                { ...state, user: item },
                                                                updateState
                                                            );
                                                        }}
                                                    >
                                                        <Paragraph>{`${item.first_name} ${item.last_name}`}</Paragraph>
                                                        <Paragraph>{`${convertMinutesToHours(item.total)}`}</Paragraph>
                                                    </Flex>
                                                ))}
                                            </Flex>
                                            <Flex sx={{ ml: 20, flexDirection: 'column' }}>
                                                <Paragraph sx={{ fontSize: 24 }}>Time Recordings</Paragraph>
                                                <Divider width="900px" />
                                                {state.detailedTimeEntries?.map((entry, idx) => (
                                                    <Flex sx={{ flexDirection: 'column' }} key={`entry_${idx}`}>
                                                        <Flex sx={{ alignItems: 'center' }}>
                                                            <Flex sx={{ flexDirection: 'column' }}>
                                                                <Paragraph sx={{ fontSize: 22, mb: '5px' }}>
                                                                    {entry.timeWeekEntry?.client?.name ||
                                                                        activityTypes?.find(
                                                                            (x) =>
                                                                                x.value ===
                                                                                entry.timeWeekEntry?.activityType
                                                                        )?.label ||
                                                                        ''}
                                                                </Paragraph>
                                                                {entry.timeWeekEntry?.service && (
                                                                    <Paragraph sx={{ fontSize: 18, mb: '5px' }}>
                                                                        {
                                                                            state.organisation?.workflow_definition
                                                                                ?.services?.[
                                                                                entry.timeWeekEntry?.service
                                                                            ]?.label
                                                                        }
                                                                    </Paragraph>
                                                                )}
                                                                <Paragraph sx={{ fontSize: 14 }}>
                                                                    {moment
                                                                        .utc(entry.date, 'YYYY-MM-DDTHH:mm:ssZ')
                                                                        .format('Do MMMM YYYY')}
                                                                </Paragraph>
                                                                <Paragraph sx={{ fontSize: 14 }}>
                                                                    {entry.timeWeekEntry?.description}
                                                                </Paragraph>
                                                                <Paragraph sx={{ fontSize: 14 }}>
                                                                    {convertMinutesToHours(entry.timeSpent)}
                                                                </Paragraph>
                                                                <Divider width="700px" />
                                                            </Flex>
                                                            <ReactSelect
                                                                styles={{
                                                                    control: (provided) => ({
                                                                        ...provided,
                                                                        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                                        width: '200px',
                                                                        minHeight: '40px',
                                                                        marginTop: '10px',
                                                                        borderRadius: 10,
                                                                        marginBottom: '10px',
                                                                        fontSize: '14px',
                                                                        border: '1px solid #a3a69b',
                                                                    }),
                                                                }}
                                                                onChange={async (c) => {
                                                                    await updateStatus(
                                                                        [
                                                                            {
                                                                                status:
                                                                                    entry.status === 'locked'
                                                                                        ? 'open'
                                                                                        : 'locked',
                                                                                id: entry.id,
                                                                            },
                                                                        ],
                                                                        state,
                                                                        updateState
                                                                    );
                                                                }}
                                                                placeholder="Set status"
                                                                value={timeStatuses?.find(
                                                                    (x) => x.value === entry.status
                                                                )}
                                                                options={timeStatuses}
                                                            />
                                                        </Flex>
                                                    </Flex>
                                                ))}
                                            </Flex>
                                        </Flex>
                                    )}

                                    {state.view === 'week' && (
                                        <Flex sx={{ mt: 30, mb: 50 }}>
                                            <table
                                                style={{
                                                    border: '1px solid lightGrey',
                                                    borderCollapse: 'collapse',
                                                }}
                                            >
                                                <thead
                                                    style={{
                                                        boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.2)',
                                                        backgroundColor: theme.colors.primary,
                                                        opacity: 0.9,
                                                        height: 50,
                                                        color: 'white',
                                                    }}
                                                >
                                                    <th style={{ width: 100, padding: 10 }}>#</th>
                                                    <th style={{ width: 400 }}>DETAILS</th>
                                                    <th style={{ width: 120 }}>SUN</th>
                                                    <th style={{ width: 120 }}>MON</th>
                                                    <th style={{ width: 120 }}>TUE</th>
                                                    <th style={{ width: 120 }}>WED</th>
                                                    <th style={{ width: 120 }}>THU</th>
                                                    <th style={{ width: 120 }}>FRI</th>
                                                    <th style={{ width: 120 }}>SAT</th>
                                                    <th style={{ width: 120 }}>TOTAL</th>
                                                    <th style={{ width: 50 }}></th>
                                                </thead>

                                                {state.timeEntries?.map((item, idx) => (
                                                    <tr
                                                        style={{ borderTop: '1px solid lightGrey' }}
                                                        key={`time_entry_${idx}`}
                                                    >
                                                        <td style={{ textAlign: 'center' }}>{idx + 1}</td>
                                                        <td style={{ padding: 25 }}>
                                                            <Flex sx={{ flexDirection: 'column', mr: 20 }}>
                                                                <Label sx={{ mt: 10 }} id="activity-type-label">
                                                                    Type of activity
                                                                </Label>
                                                                <ReactSelect
                                                                    aria-labelledby="activity-type-label"
                                                                    styles={{
                                                                        control: (provided) => ({
                                                                            ...provided,
                                                                            boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                                            width: '280px',
                                                                            minHeight: '40px',
                                                                            marginTop: '10px',
                                                                            borderRadius: 10,
                                                                            marginBottom: '10px',
                                                                            border: '1px solid #a3a69b',
                                                                            fontSize: '14px',
                                                                        }),
                                                                    }}
                                                                    onChange={async (c) => {
                                                                        const newTimeEntries = [...state.timeEntries];

                                                                        newTimeEntries[idx].activity_type = c.value;
                                                                        updateState({
                                                                            ...state,
                                                                            timeEntries: newTimeEntries,
                                                                        });
                                                                    }}
                                                                    placeholder="Select..."
                                                                    value={
                                                                        item.activity_type
                                                                            ? {
                                                                                  value: item.activity_type,
                                                                                  label: activityTypes.find(
                                                                                      (x) =>
                                                                                          x.value === item.activity_type
                                                                                  )?.label,
                                                                              }
                                                                            : activityTypes[0]
                                                                    }
                                                                    options={activityTypes}
                                                                />
                                                                {(!item.activity_type ||
                                                                    item.activity_type === 'task_for_client') && (
                                                                    <Flex sx={{ flexDirection: 'column', mt: 10 }}>
                                                                        <Label sx={{ mt: 0 }} id="client-label">
                                                                            Client
                                                                        </Label>
                                                                        <AsyncSelect
                                                                            isDisabled={
                                                                                item.additional_data?.locked_days
                                                                                    ?.length
                                                                            }
                                                                            styles={{
                                                                                control: (provided) => ({
                                                                                    ...provided,
                                                                                    boxShadow:
                                                                                        '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                                                    width: '400px',
                                                                                    minHeight: '40px',
                                                                                    marginTop: '10px',
                                                                                    borderRadius: 10,
                                                                                    marginBottom: '10px',
                                                                                    border: state.errors?.[
                                                                                        `${idx}_client`
                                                                                    ]
                                                                                        ? '1px solid red'
                                                                                        : '1px solid #a3a69b',
                                                                                    fontSize: '14px',
                                                                                }),
                                                                            }}
                                                                            aria-labelledby="client-label"
                                                                            onChange={async (c) => {
                                                                                const newTimeEntries = [
                                                                                    ...state.timeEntries,
                                                                                ];
                                                                                newTimeEntries[idx].client = {
                                                                                    id: c.value,
                                                                                    uuid: c.uuid,
                                                                                    name: c.label,
                                                                                };
                                                                                delete state.errors[`${idx}_client`];
                                                                                updateState({
                                                                                    ...state,
                                                                                    timeEntries: newTimeEntries,
                                                                                    defaultTasksForDropDown:
                                                                                        await loadTasksAsync(
                                                                                            state,
                                                                                            updateState,
                                                                                            c.value
                                                                                        )(''),
                                                                                });
                                                                            }}
                                                                            placeholder="Select client"
                                                                            value={
                                                                                item.client
                                                                                    ? {
                                                                                          value: item.client.id,
                                                                                          label: item.client.name,
                                                                                      }
                                                                                    : null
                                                                            }
                                                                            loadOptions={loadClientsAsync(
                                                                                state,
                                                                                updateState
                                                                            )}
                                                                        />

                                                                        <Label sx={{ mt: 10 }} id="task-label">
                                                                            Task (Optional)
                                                                        </Label>
                                                                        <AsyncSelect
                                                                            isDisabled={
                                                                                item.additional_data?.locked_days
                                                                                    ?.length
                                                                            }
                                                                            styles={{
                                                                                control: (provided) => ({
                                                                                    ...provided,
                                                                                    boxShadow:
                                                                                        '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                                                    width: '400px',
                                                                                    minHeight: '40px',
                                                                                    marginTop: '10px',
                                                                                    borderRadius: 10,
                                                                                    marginBottom: '10px',
                                                                                    border: '1px solid #a3a69b',
                                                                                    fontSize: '14px',
                                                                                }),
                                                                            }}
                                                                            aria-labelledby="task-label"
                                                                            onChange={async (c) => {
                                                                                const newTimeEntries = [
                                                                                    ...state.timeEntries,
                                                                                ];
                                                                                newTimeEntries[idx].task = {
                                                                                    id: c.value,
                                                                                    uuid: c.uuid,
                                                                                    service: c.service,
                                                                                    title: c.label,
                                                                                };

                                                                                newTimeEntries[idx].service = c.service;

                                                                                updateState({
                                                                                    ...state,
                                                                                    timeEntries: newTimeEntries,
                                                                                });
                                                                            }}
                                                                            placeholder="Select task"
                                                                            value={
                                                                                item.task
                                                                                    ? {
                                                                                          value: item.task.id,
                                                                                          label: item.task.title,
                                                                                      }
                                                                                    : null
                                                                            }
                                                                            defaultOptions={
                                                                                state.defaultTasksForDropDown || []
                                                                            }
                                                                            loadOptions={loadTasksAsync(
                                                                                state,
                                                                                updateState,
                                                                                item.client?.id
                                                                            )}
                                                                        />

                                                                        <Label sx={{ mt: 10 }} id="client-label">
                                                                            Service
                                                                        </Label>
                                                                        <ReactSelect
                                                                            aria-labelledby="service-label"
                                                                            isDisabled={
                                                                                item.additional_data?.locked_days
                                                                                    ?.length
                                                                            }
                                                                            styles={{
                                                                                control: (provided) => ({
                                                                                    ...provided,
                                                                                    boxShadow:
                                                                                        '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                                                    width: '280px',
                                                                                    minHeight: '40px',
                                                                                    marginTop: '10px',
                                                                                    borderRadius: 10,
                                                                                    marginBottom: '10px',
                                                                                    border: '1px solid #a3a69b',
                                                                                    fontSize: '14px',
                                                                                }),
                                                                            }}
                                                                            onChange={async (c) => {
                                                                                const newTimeEntries = [
                                                                                    ...state.timeEntries,
                                                                                ];

                                                                                if (
                                                                                    newTimeEntries[idx].task &&
                                                                                    newTimeEntries[idx].task
                                                                                        ?.service !== c.value
                                                                                ) {
                                                                                    return updateState({
                                                                                        ...state,
                                                                                        showConfirmServiceChangeDialog: true,
                                                                                        confirmCallback: () => {
                                                                                            newTimeEntries[
                                                                                                idx
                                                                                            ].service = c.value;
                                                                                            updateState({
                                                                                                ...state,
                                                                                                timeEntries:
                                                                                                    newTimeEntries,
                                                                                            });
                                                                                        },
                                                                                    });
                                                                                }

                                                                                newTimeEntries[idx].service = c.value;
                                                                                updateState({
                                                                                    ...state,
                                                                                    timeEntries: newTimeEntries,
                                                                                });
                                                                            }}
                                                                            placeholder="Select..."
                                                                            value={
                                                                                item.service
                                                                                    ? {
                                                                                          value: item.service,
                                                                                          label: Object.entries(
                                                                                              state.organisation
                                                                                                  ?.workflow_definition
                                                                                                  ?.services || {}
                                                                                          )?.find(
                                                                                              ([key, value]) =>
                                                                                                  key === item.service
                                                                                          )?.[1]?.label,
                                                                                      }
                                                                                    : null
                                                                            }
                                                                            options={[
                                                                                ...(Object.entries(
                                                                                    organisation?.workflow_definition
                                                                                        ?.services || {}
                                                                                )?.map(([key, value]) => ({
                                                                                    value: key,
                                                                                    label: value.label,
                                                                                })) || []),
                                                                            ]}
                                                                        />
                                                                    </Flex>
                                                                )}

                                                                <Flex sx={{ mt: 10 }}>
                                                                    <Flex sx={{ flexDirection: 'column' }}>
                                                                        <Label sx={{ mb: 10 }} id="description-label">
                                                                            Description
                                                                        </Label>
                                                                        <Textarea
                                                                            aria-labelledby="description-label"
                                                                            rows={8}
                                                                            sx={{
                                                                                width: 400,
                                                                                border: state.errors?.[
                                                                                    `${idx}_description`
                                                                                ]
                                                                                    ? '1px solid red'
                                                                                    : '1px solid #a3a69b',
                                                                            }}
                                                                            value={item.description}
                                                                            onChange={(e) => {
                                                                                const newTimeEntries = [
                                                                                    ...state.timeEntries,
                                                                                ];
                                                                                delete state.errors[
                                                                                    `${idx}_description`
                                                                                ];
                                                                                newTimeEntries[idx].description =
                                                                                    e.target.value;
                                                                                updateState({
                                                                                    ...state,
                                                                                    timeEntries: newTimeEntries,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </Flex>

                                                                    {item.activity_type === 'task_for_client' && (
                                                                        <Flex sx={{ flexDirection: 'column', ml: 20 }}>
                                                                            <Label
                                                                                sx={{ mt: 0, width: 200 }}
                                                                                id="billable_per_hr"
                                                                            >
                                                                                Billable (/hr)
                                                                            </Label>

                                                                            <Switch
                                                                                disabled={
                                                                                    item.additional_data?.locked_days
                                                                                        ?.length
                                                                                }
                                                                                aria-labelledby="billable_per_hr"
                                                                                sx={{ mt: 10, ml: 0 }}
                                                                                defaultChecked={
                                                                                    item.additional_data.billable
                                                                                }
                                                                                onClick={(e) => {
                                                                                    const newTimeEntries = [
                                                                                        ...state.timeEntries,
                                                                                    ];
                                                                                    newTimeEntries[
                                                                                        idx
                                                                                    ].additional_data.billable =
                                                                                        !newTimeEntries[idx]
                                                                                            .additional_data.billable;

                                                                                    updateState({
                                                                                        ...state,
                                                                                        timeEntries: newTimeEntries,
                                                                                    });
                                                                                }}
                                                                            />

                                                                            {item.additional_data.billable && (
                                                                                <Input
                                                                                    type="number"
                                                                                    value={item.billable_amount}
                                                                                    placeholder="Amount billable / hour"
                                                                                    onChange={(e) => {
                                                                                        const newTimeEntries = [
                                                                                            ...state.timeEntries,
                                                                                        ];
                                                                                        newTimeEntries[
                                                                                            idx
                                                                                        ].billable_amount =
                                                                                            e.target.value;
                                                                                        delete state.errors[
                                                                                            `${idx}_billable_amount`
                                                                                        ];
                                                                                        updateState({
                                                                                            ...state,
                                                                                            timeEntries: newTimeEntries,
                                                                                        });
                                                                                    }}
                                                                                    sx={{
                                                                                        width: 200,
                                                                                        mt: 20,
                                                                                        border: state.errors?.[
                                                                                            `${idx}_billable_amount`
                                                                                        ]
                                                                                            ? '1px solid red'
                                                                                            : '1px solid #a3a69b',
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </Flex>
                                                                    )}
                                                                </Flex>
                                                            </Flex>
                                                        </td>
                                                        {[0, 1, 2, 3, 4, 5, 6].map((day) => (
                                                            <td key={`day_${idx}_${day}`} style={{ padding: 25 }}>
                                                                <Flex
                                                                    sx={{
                                                                        flexDirection: 'column',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    <ReactSelect
                                                                        styles={{
                                                                            control: (provided) => ({
                                                                                ...provided,
                                                                                boxShadow:
                                                                                    '2px 2px 2px rgba(0, 0, 0, 0.2)',
                                                                                width: '150px',
                                                                                minHeight: '40px',
                                                                                marginTop: '0px',
                                                                                borderRadius: 10,
                                                                                marginBottom: '10px',
                                                                                fontSize: '14px',
                                                                                border: '1px solid #a3a69b',
                                                                            }),
                                                                        }}
                                                                        onChange={(c) => {
                                                                            const newTimeEntries = [
                                                                                ...state.timeEntries,
                                                                            ];
                                                                            newTimeEntries[idx].additional_data.days[
                                                                                day
                                                                            ] = c.value;
                                                                            newTimeEntries[idx].time_spent =
                                                                                item.additional_data.days.reduce(
                                                                                    (accum, value) => {
                                                                                        accum =
                                                                                            parseInt(accum || 0, 10) +
                                                                                            parseInt(value || 0, 10);
                                                                                        return accum;
                                                                                    },
                                                                                    0
                                                                                );

                                                                            updateState({
                                                                                ...state,
                                                                                timeEntries: newTimeEntries,
                                                                                totalTimeSpent: newTimeEntries.reduce(
                                                                                    (accum, value) => {
                                                                                        accum =
                                                                                            parseInt(accum || 0, 10) +
                                                                                            parseInt(
                                                                                                value.time_spent || 0,
                                                                                                10
                                                                                            );
                                                                                        return accum;
                                                                                    },
                                                                                    0
                                                                                ),
                                                                            });
                                                                        }}
                                                                        placeholder="Select..."
                                                                        value={
                                                                            item.additional_data?.days?.[day]
                                                                                ? {
                                                                                      value: item.additional_data
                                                                                          ?.days?.[day],
                                                                                      label: convertMinutesToHours(
                                                                                          item.additional_data?.days?.[
                                                                                              day
                                                                                          ]
                                                                                      ),
                                                                                  }
                                                                                : null
                                                                        }
                                                                        options={timeRecordingOptions}
                                                                        disabled={item.additional_data?.locked_days?.includes(
                                                                            day
                                                                        )}
                                                                    />

                                                                    {state.organisation?.user?.role === 'admin' ? (
                                                                        <Button
                                                                            onClick={async () => {
                                                                                const newTimeEntries = [
                                                                                    ...state.timeEntries,
                                                                                ];

                                                                                if (
                                                                                    !newTimeEntries[idx].additional_data
                                                                                        .locked_days
                                                                                ) {
                                                                                    newTimeEntries[
                                                                                        idx
                                                                                    ].additional_data.locked_days = [];
                                                                                }

                                                                                if (
                                                                                    newTimeEntries[
                                                                                        idx
                                                                                    ].additional_data?.locked_days?.includes(
                                                                                        day
                                                                                    )
                                                                                ) {
                                                                                    newTimeEntries[
                                                                                        idx
                                                                                    ].additional_data.locked_days.splice(
                                                                                        newTimeEntries[
                                                                                            idx
                                                                                        ].additional_data.locked_days.findIndex(
                                                                                            (x) => x === day
                                                                                        ),
                                                                                        1
                                                                                    );
                                                                                } else {
                                                                                    newTimeEntries[
                                                                                        idx
                                                                                    ].additional_data.locked_days.push(
                                                                                        day
                                                                                    );
                                                                                }

                                                                                await save(
                                                                                    {
                                                                                        ...state,
                                                                                        timeEntries: newTimeEntries,
                                                                                    },
                                                                                    updateState
                                                                                );
                                                                            }}
                                                                            variant="light"
                                                                            sx={{ width: 100, marginTop: 20 }}
                                                                        >
                                                                            {item.additional_data?.locked_days?.includes(
                                                                                day
                                                                            )
                                                                                ? 'Unapprove'
                                                                                : 'Approve'}
                                                                        </Button>
                                                                    ) : null}
                                                                    {item.additional_data?.locked_days?.includes(
                                                                        day
                                                                    ) ? (
                                                                        <i
                                                                            style={{
                                                                                marginTop: 20,
                                                                                fontSize: '12px',
                                                                                cursor: 'pointer',
                                                                            }}
                                                                            className="fas fa-lock"
                                                                            aria-hidden="true"
                                                                        />
                                                                    ) : (
                                                                        <Flex sx={{ height: 32 }} />
                                                                    )}
                                                                </Flex>
                                                            </td>
                                                        ))}
                                                        <td style={{ textAlign: 'center' }}>
                                                            {convertMinutesToHours(item.time_spent || 0)}
                                                        </td>
                                                        <td style={{ textAlign: 'center' }}>
                                                            <i
                                                                style={{
                                                                    marginLeft: '30px',
                                                                    marginRight: '30px',
                                                                    fontSize: '12px',
                                                                    cursor: 'pointer',
                                                                    color: item.additional_data?.locked_days?.length
                                                                        ? 'lightGrey'
                                                                        : 'red',
                                                                }}
                                                                className="fas fa-trash"
                                                                aria-hidden="true"
                                                                onClick={async () => {
                                                                    if (item.additional_data?.locked_days?.length)
                                                                        return;

                                                                    if (item.uuid) {
                                                                        await axios({
                                                                            url: `${process.env.REACT_APP_AQRU_AI_API}/time-entries/${item.uuid}`,
                                                                            method: 'DELETE',
                                                                        });
                                                                    }
                                                                    updateState({
                                                                        ...state,
                                                                        showDeleteItemConfirmation: true,
                                                                        confirmCallback: async () => {
                                                                            {
                                                                                const newTimeEntries = [
                                                                                    ...state.timeEntries,
                                                                                ];
                                                                                newTimeEntries.splice(idx, 1);

                                                                                updateState({
                                                                                    ...state,
                                                                                    timeEntries: newTimeEntries,
                                                                                    errors: {},
                                                                                    totalTimeSpent:
                                                                                        newTimeEntries.reduce(
                                                                                            (accum, value) => {
                                                                                                accum =
                                                                                                    parseInt(
                                                                                                        accum || 0,
                                                                                                        10
                                                                                                    ) +
                                                                                                    parseInt(
                                                                                                        value.time_spent ||
                                                                                                            0,
                                                                                                        10
                                                                                                    );
                                                                                                return accum;
                                                                                            },
                                                                                            0
                                                                                        ),
                                                                                });
                                                                            }
                                                                        },
                                                                    });
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                                {state.timeEntries?.length ? (
                                                    <tr style={{ borderTop: '1px solid lightGrey' }}>
                                                        <td></td>
                                                        <td></td>
                                                        {[0, 1, 2, 3, 4, 5, 6].map((x) => (
                                                            <td
                                                                key={`total_${x}`}
                                                                style={{ textAlign: 'center', padding: 25 }}
                                                            >
                                                                {state.timeEntries.reduce((accum, value) => {
                                                                    accum =
                                                                        parseInt(accum || 0, 10) +
                                                                        parseInt(
                                                                            value.additional_data.days[x] || 0,
                                                                            10
                                                                        );
                                                                    return accum;
                                                                }, 0)}
                                                            </td>
                                                        ))}
                                                        <td style={{ textAlign: 'center', padding: 25 }}>
                                                            {state.timeEntries.reduce((accum, value) => {
                                                                accum =
                                                                    parseInt(accum || 0, 10) +
                                                                    parseInt(value.time_spent || 0, 10);
                                                                return accum;
                                                            }, 0)}{' '}
                                                            minutes
                                                        </td>
                                                    </tr>
                                                ) : null}
                                            </table>
                                        </Flex>
                                    )}
                                </Flex>
                            </Flex>
                            {state.view === 'week' && (
                                <Flex
                                    sx={{
                                        position: 'fixed',
                                        height: 80,
                                        background: 'white',
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        alignItems: 'center',
                                        borderTop: '2px solid #EFEFEF',
                                        width: '100%',
                                    }}
                                >
                                    <Button
                                        sx={{ ml: 140 }}
                                        onClick={async () => {
                                            await save(state, updateState);
                                        }}
                                    >
                                        Save
                                    </Button>
                                    <Paragraph sx={{ ml: 20, fontSize: 20 }}>
                                        Total: {convertMinutesToHours(state.totalTimeSpent || 0)}
                                    </Paragraph>
                                    <Button onClick={() => addRow(state, updateState)} variant="light" sx={{ ml: 20 }}>
                                        Add row
                                    </Button>
                                    {Object.keys(state.errors || {}).length ? (
                                        <Paragraph sx={{ color: 'red', ml: 20 }}>
                                            Please complete the fields in red
                                        </Paragraph>
                                    ) : null}
                                    {state.totalTimeSpent > 0 && (
                                        <Flex sx={{ ml: 20 }}>
                                            <Paragraph sx={{ mr: 10 }}>
                                                Billable Time ({billableTimePct.toFixed(0)}%)
                                            </Paragraph>
                                            <ProgressIndicator
                                                percentage={billableTimePct}
                                                sx={{ mb: 0, width: 600 }}
                                            />
                                        </Flex>
                                    )}
                                </Flex>
                            )}
                        </>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    refreshCoreData: () => dispatch({ type: userActions.REFRESH_CORE_DATA }),
    logout: () => dispatch({ type: 'LOGOUT' }),
    dismissTopMenu: () => dispatch({ type: menuActions.DISMISS_TOP_MENU }),
});

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        loggedIn: account.loggedIn,
        dataLoading: account.loading,
        userData: account.user,
        timestampOfLastDataLoad: account.timestampOfLastDataLoad,
        isMobile: account.isMobile,
        accountSummary: account.accountSummary,
        organisation: account.organisation?.entity,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Timesheet);
function buildDateRange(state) {
    let fromDate, toDate;
    if (state.timePeriod?.value === 'this_month') {
        fromDate = `${moment().startOf('day').startOf('month').format('YYYY-MM-DDTHH:mm:ssZ')}`;
        toDate = `${moment().endOf('day').endOf('month').format('YYYY-MM-DDTHH:mm:ssZ')}`;
    }
    if (state.timePeriod?.value === 'today') {
        fromDate = `${moment().startOf('day').format('YYYY-MM-DDTHH:mm:ssZ')}`;
        toDate = `${moment().endOf('day').format('YYYY-MM-DDTHH:mm:ssZ')}`;
    }
    if (state.timePeriod?.value === 'custom') {
        fromDate = state.fromDate;
        toDate = state.toDate;
    }
    if (state.timePeriod?.value === 'last_month') {
        fromDate = `${moment().subtract(1, 'month').startOf('day').startOf('month').format('YYYY-MM-DDTHH:mm:ssZ')}`;
        toDate = `${moment().subtract(1, 'month').endOf('day').endOf('month').format('YYYY-MM-DDTHH:mm:ssZ')}`;
    }
    if (state.timePeriod?.value === 'this_week') {
        fromDate = `${moment().startOf('day').startOf('week').format('YYYY-MM-DDTHH:mm:ssZ')}`;
        toDate = `${moment().endOf('day').endOf('week').format('YYYY-MM-DDTHH:mm:ssZ')}`;
    }
    if (state.timePeriod?.value === 'last_week') {
        fromDate = `${moment().subtract(1, 'week').startOf('day').startOf('week').format('YYYY-MM-DDTHH:mm:ssZ')}`;
        toDate = `${moment().subtract(1, 'week').endOf('day').endOf('week').format('YYYY-MM-DDTHH:mm:ssZ')}`;
    }
    return { fromDate, toDate };
}
