import { Box, Flex } from 'theme-ui';
import themeConfig from '../theme';

export const NotificationsIcon = ({ count, onClick, customWrapperSx = {}, countLeft = 83 }) => (
    <Flex
        sx={customWrapperSx}
        onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClick();
        }}
    >
        {count > 0 && (
            <Box
                sx={{
                    padding: '3px 8px 5px 8px',
                    position: 'absolute',
                    borderRadius: 30,
                    border: `2px solid red`,
                    color: 'red',
                    top: -10,
                    left: countLeft,
                    fontSize: count < 100 ? '14px' : '11px',
                }}
            >
                {count}
            </Box>
        )}
        <i
            className="fa fa-envelope-open-text"
            style={{ color: themeConfig.colors.primary, fontSize: 30, marginRight: 20 }}
        />
    </Flex>
);
